/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/reducers/ScheduleReducer.js
 * This JS file is intended to provide the reducer for schedule part.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

const initialState = {
  schedule: null,
  bookedTimes:null,
  events:null
};

const ScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_SCHEDULE":
      return {
        ...state,
        schedule: action.payload,
      };
      case "GET_TIMES":
        return {
          ...state,
          bookedTimes: action.payload.data,
        };
        case "GET_EVENTS":
        return {
          ...state,
          events: action.payload.data,
        };
        case "RESET_BOOKED_TIMES":
          return {
            ...state,
            bookedTimes: action.payload,
          };
    default:
      return state;
  }
};

export default ScheduleReducer;
