/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice\students\api\TestPackageListApi.js
 * This JS file is intended to provide the reducer for testpackages part.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 07 Feb 2022
 */
const initialState = {
  courseList: null,
  coursecontents: null,
  testlist: null,
  CourseById: null,
  courseprice: null,
  customizedList: 0,
  notification: null,
  gradeSubjectList: null,
  // cartItems: []
  cartItems: JSON.parse(localStorage.getItem('cartItems')) || []
};
const TestPackageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COURSE":
      return {
        ...state,
        courseList: action.payload.data,
      };
    case "GET_CONTENTS":
      return {
        ...state,
        coursecontents: action.payload.data,
      };
    case "GET_TESTLIST":
      return {
        ...state,
        testlist: action.payload.data,
      };
    case "RESET_TESTLIST":
      return {
        ...state,
        testlist: action.payload,
      };
    case "GET_COURSEBYID":
      return {
        ...state,
        CourseById: action.payload.data,
      };
    case "RESET_COURSEBYID":
      return {
        ...state,
        CourseById: action.payload,
      };
    case "GET_COURSEPRICE":
      return {
        ...state,
        courseprice: action.payload.data,
      };
    case "GET_CUSTOMIZEDLIST":
      return {
        ...state,
        customizedList: action.payload,
      };
      case 'ADD_TO_CART':
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload]
        };
        case 'REMOVE_FROM_CART':
          const itemIdToRemove = action.payload;
          const updatedCartItems = state.cartItems.filter(item => item.id !== itemIdToRemove);
          return {
            ...state,
            cartItems: updatedCartItems
          };
    case "RESET_CUSTOMIZEDLIST":
      return {
        ...state,
        customizedList: action.payload,
      };
    case "POST_NOTIFICATION":
      return {
        ...state,
        notification: action.payload.data,
      };
    case "GET_GRADE_SUBJECT":
      return {
        ...state,
        gradeSubjectList: action.payload.data,
      };
    default:
      return state;
  }
};

export default TestPackageListReducer;
