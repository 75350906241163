/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/index.js
 * This JS file is intended to provide the api calls related to dashboard page.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

import { MoodleURL } from "../config";
import { GET_METHOD } from "./ApiMethods";
import { decrypt } from "../../../encrypt";

export const getStudentList = async (userid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_centapi_studentslist&userid=${userid}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const getTaskList = async () => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=mod_assign_get_assignments`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getScheduleList = async () => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=local_quizattempt_userschedules`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// api/index.js

export const deleteMeetingapi = async (eventId) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=local_quizattempt_delete_event&eventid=${eventId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

