/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class success.js
 * This JS file is intended to give a success dashboard of form.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 25 Feb 2022
 */

import React from "react";
import { Grid, Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Schedule from "../dashboard/schedule";
import Progress from "../dashboard/progress";
import TESTEXPLORE from "../test/packages/TestExplore";
import { useHistory, useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import { fetchCourseList } from "../../../webservice/students/actions/TestPackageListAction";
import successmeeting from "./../../../assets/images/successmeeting.png"
import moment from "moment-timezone";
// import successmeeting from "./../../../../assets/images/successmeeting.png";


const USE_STYLES = makeStyles({
  btn: {
    width: "30%",

    "@media (max-width:900px)": {
      width: "80%",
    },
  },

  testPackageContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
});

const SUCCESS = () => {
  const CLASSES = USE_STYLES();
  const HISTORY = useHistory();
  const params = useParams();
  const time = params.timestamp;
  const melbourneTime = moment.unix(time).tz('Australia/Melbourne');
  const formattedDate = melbourneTime.format('MM/DD/YYYY');
  const formattedTime = melbourneTime.format('h:mm a');
  const timezone = melbourneTime.format('z');
  const { userid } = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchUserId());
  }, []);
  React.useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  return (
    <Scrollbars style={{ height: "70vh" }}>
      <Box px={2}>
        <Grid container spacing={2} className={CLASSES.testPackageContainer}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <Button
                  sx={{
                    color: "#634699",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Back
                </Button>
              </Grid>

              <Grid item xs={12} md={8}>
                <Paper sx={{
                  background: "#FFFFFF", boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
                  borderRadius: "5px", paddingBottom: "20%", paddingTop: "10%"
                }}>
                  <Grid
                    container item
                    style={{ marginBottom: "20px", marginTop: "35px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={4} />
                    <Grid item xs={3.7}>
                      <img
                        src={successmeeting}
                        alt="success"
                        width="210.06px"
                        height="180.08px"
                      />
                    </Grid>
                    <Grid item xs={4} />
                  </Grid>

                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "400",
                      fontStyle: "normal",
                      fontSize: "16px",
                      lineHeight: "140%",
                      textAlign: "center",
                    }}
                  >
                    You have meeting with your tutor on
                    <br />
                    <span
                      style={{
                        color: "#634699",
                        fontWeight: "400",
                        fontStyle: "normal",
                        fontSize: "16px",
                      }}
                    >
                      {formattedDate}
                    </span>
                    &nbsp; at &nbsp;
                    <span
                      style={{
                        color: "#634699",
                        fontWeight: "400",
                        fontStyle: "normal",
                        fontSize: "16px",
                      }}
                    >
                      {formattedTime} 
                    </span>
                    &nbsp; (Australia/Melbourne timezone, {timezone}) 
                  </Typography><br />
                  <Grid align="center">
                    <Button
                      onClick={() => HISTORY.push("/my-test-packages")}
                      className={CLASSES.btn}
                      style={{
                        backgroundColor: "#634699",
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      <b>My Test Package</b>
                    </Button>
                  </Grid>
                </Paper>
              </Grid>

              {/* <Grid item xs={6} md={6}>
                <Button
                  onClick={() => HISTORY.push("/test-packages")}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#E2DDEC",
                    color: "#634699",
                    fontWeight: "bold",
                    padding: "15px",
                    "&:hover": { backgroundColor: "#E2DDEC" },
                  }}
                  fullWidth
                >
                  Explore
                </Button>
              </Grid> */}

              {/* <Grid item xs={12} md={8}>
                <TESTEXPLORE />
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} md={4}>
                <Progress />
              </Grid>
              <Grid item xs={12} md={4}>
                <Schedule />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
};

export default SUCCESS;
