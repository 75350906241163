/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestList.js
 * This JS file is intended to create a component that showing the list of tests coming under one test package.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  CardContent,
  Card,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";
import { ExpandIcon, TestPackageListIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCourseContents } from "../../../../webservice/students/actions/TestPackageListAction";
import { useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useState } from "react";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Fragment } from "react";

const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const USE_STYLES = makeStyles({
  testListContainer: {
    backgroundColor: "#E7E5EE",
    borderRadius: "4px",
    marginTop: "10px",
  },
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop:"5px"
  },
});
const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));
const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));

const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const TEST_LIST = () => {
  const [expanded, setExpanded] = React.useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const HANDLE_EXPAND_CLICK = (index, id) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
  };
  const CLASSES = USE_STYLES();
  const params = useParams();
  const courseid = params.id;
const userid = params.userid
  // const { userid } = useSelector((state) => state.authState);
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(fetchCourseContents(courseid, userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSearchResult = () => {
    let list = coursecontents.filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      }
    });
    if (list.length) return list;
    else return [{ modules: 0 }];
  };

  return (
    <Box px={1}>
      <Grid item xs={12}>
        <SEARCH>
          <SEARCH_ICON_WRAPPER>
            <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
          </SEARCH_ICON_WRAPPER>
          <STYLED_INPUT_BASE
            type="text"
            placeholder="Search Topics"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />{" "}
        </SEARCH>
      </Grid>
      <Grid container className={CLASSES.testListContainer}>
        <Grid item xs={12}>
          <Scrollbars style={{ height: 500 }}>
            {coursecontents ? (
              coursecontents && coursecontents.length ? (
                getSearchResult().map(
                  (topic, index) =>
                    topic.section !== 0 &&
                    (topic.modules && topic.modules.length !== 0 ? (
                      <Fragment key={index}>
                        <Card sx={{ margin: "5px",padding: "16px" }}>
                          {/* <CardContent> */}
                            <Grid container>
                              <Grid item xs={1}>
                                <TestPackageListIcon className={CLASSES.icon} />
                              </Grid>
                              <Grid item xs={8} >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "13.5px",
                                    color: "#634699",
                                    textAlign: "left",
                                    marginTop:"5px"
                                  }}
                                >
                                  {topic.name} &nbsp;
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "gray",
                                      fontSize: "16px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: topic?.sectioncurrency,
                                      maximumSignificantDigits: 3,
                                    }).format(topic?.actualcost === "0" ? topic?.sectioncost : topic?.actualcost)}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                {topic.secvisible === true ? (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "13.5px",
                                      color: "#634699",
                                      textAlign: "left",
                                      lineHeight:"33px"
                                    }}
                                  >
                                    Enrolled
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Grid item xs={1}>
                                <EXPAND_MORE
                                  expand={expanded === index}
                                  onClick={() => HANDLE_EXPAND_CLICK(index)}
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                >
                                  <ExpandIcon sx={{ fontSize: 16 }}/>
                                </EXPAND_MORE>
                              </Grid>
                            </Grid>
                          {/* </CardContent> */}
                        </Card>
                        <Card>
                          {topic.modules.length ? (
                            topic.modules.map((module) => (
                              <Collapse
                                key={module.id}
                                in={expanded === index}
                                timeout="auto"
                                unmountOnExit
                              >
                                {module.modname === "quiz" && (
                                  <Card
                                    sx={{
                                      backgroundColor: "#E2DDEC",
                                      // marginBottom: "5px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: "16px",
                                          fontStyle: "regular",
                                          lineHeight: "22px",
                                          color: "#634699",
                                          textAlign: "center",
                                        }}
                                      >
                                        {module.name}
                                        <br />
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                )}
                              </Collapse>
                            ))
                          ) : (
                            <Collapse
                              in={expanded === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Card
                                sx={{
                                  backgroundColor: "#E2DDEC",
                                  marginBottom: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                <CardContent>
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: "16px",
                                      fontStyle: "regular",
                                      lineHeight: "22px",
                                      color: "#634699",
                                      textAlign: "center",
                                    }}
                                  >
                                    No Tests Found
                                    <br />
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Collapse>
                          )}
                        </Card>
                      </Fragment>
                    ) : topic.modules === 0 ? (
                      <Fragment key={index}>
                        <Card sx={{ margin: "15px" }} key={topic.id}>
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                key={topic.id}
                                textAlign="center"
                              >
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                    lineHeight: "140%",
                                  }}
                                >
                                  No Result Found..!
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Fragment>
                    ) : null)
                )
              ) : (
                <Card sx={{ margin: "15px" }}>
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "50px",
                    }}
                  >
                    No tests found
                  </Typography>
                </Card>
              )
            ) : null}
          </Scrollbars>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TEST_LIST;
