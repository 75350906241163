/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/AuthApi.js
 * This JS file is intended to provide the api calls related to authentication purposes.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

import { GET_METHOD } from "./ApiMethods";
import { MoodleURL } from "../config";
import { decrypt } from "../../../encrypt";
import { POST_METHOD } from "./ApiMethods";
import axios from "axios";  // Make sure to import axios

const baseURL = process.env.REACT_APP_API_URL;

export const POST_METHODS = async (url = "", formData) => {
  try {
    const result = await axios.post(baseURL + url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return result;
  } catch (error) {
    throw error;  // Propagate the error for handling in the calling code
  }
};

export const getLogin = async (email, password) => {
  try {
    const apiUrl = "webservice/rest/api.php";
    const formData = new FormData();
    formData.append("api_service", "login");
    formData.append("username", email);
    formData.append("password", password);

    const response = await POST_METHODS(apiUrl, formData);

    const data = response.data;
    if (data?.data?.access_token) {
      return data.data.access_token;
    }
  } catch (error) {
    return error;
  }
};


// export const getLogin = async (email, password) => {
//   try {
//     let response = await GET_METHOD(
//       `/login/token.php?service=portal&username=${email}&password=${password}`
//     );
//     const data = response.data;
//     if (data.token) {
//       return data.token;
//     }
//   } catch (error) {
//     return error;
//   }
// };

export const getUserId = async () => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=core_webservice_get_site_info`
    );
    const data = response.data;
    if (data.userid) {
      return data.userid;
    }
  } catch (error) {
    return error;
  }
};

export const getUserDetails = async () => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=core_webservice_get_site_info`
    );
    const data = response.data;
    if (data.firstname) {
      return data.firstname;
    }
  } catch (error) {
    return error;
  }
};

export const getSignup = async (
  firstname,
  lastname,
  username,
  email,
  password,
  phone2
) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=core_user_create_users&moodlewsrestformat=json&users[0][firstname]=${firstname}&users[0][lastname]=${lastname}&users[0][username]=${username}&users[0][password]=${password}&users[0][email]=${email}&users[0][phone2]=${phone2}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};
export const updateUser = async (state, userid) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let queryparams = "";
    const keysArray = Object.keys(state);
    const keys = ["facebook", "linkedin", "instagram", "pincode", "addressl2","parentphone","parentemail"];
    var index = 0;
    keysArray.map((data) => {
      if (keys.includes(data)) {
        queryparams = `&users[0][customfields][${index}][type]=${data}${queryparams}`;
        queryparams = `&users[0][customfields][${index}][value]=${state[data]}${queryparams}`;
        index++;
      } else
        queryparams = "&users[0][" + data + "]=" + state[data] + queryparams;
    });

    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&moodlewsrestformat=json&wsfunction=core_user_update_users&moodlewsrestformat=json&users[0][id]=${userid}${queryparams}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};
export const generateUserToken = async (userid) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=local_centapi_createtoken&moodlewsrestformat=json&userid=${userid}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserFields = async (userid) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&moodlewsrestformat=json&wsfunction=core_user_get_users_by_field&field=id&values[0]=${userid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getExistingEmail = async (name, value) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=core_user_get_users_by_field&field=${name}&values[0]=${value}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getExistingUsername = async (name, value) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=core_user_get_users_by_field&field=${name}&values[0]=${value}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getUserImage = async (userid, formData) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await POST_METHOD(
      `${MoodleURL(
        d
      )}&moodlewsrestformat=json&wsfunction=local_centapi_updateprofilepic&userid=${userid}`,
      formData
    );

    return response;
  } catch (error) {
    return error;
  }
};
export const ForgotPassword = async (
  username
) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=local_centapi_forgotpass&moodlewsrestformat=json&uname=${username}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};
export const ChangePassword = async (
  id,newPassword
) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=local_centapi_updatepass&moodlewsrestformat=json&id=${id}&password=${newPassword}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};
