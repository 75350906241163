/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/store/index.js
 * This JS file is intended to create the globally accessed store.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
