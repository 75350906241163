/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class ShortAnswerNew.jsx
 * This JSX file is for showing the ShortAnswer Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 02 Feb 2023
 */
import React, { useRef, useState, useEffect } from "react";
import { CardMedia, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-extended/build/ckeditor';
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { romanNumbers } from "../../../../utils/AppConstants";
import { fetchQuizStatus } from "../../../../webservice/students/actions/TestAttemptAction";

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ck-editor__main > .ck-editor__editable": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "&.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        color: "#634699",
        minHeight: "150.1px",
        borderColor: "#634699",
        border: "0.771363px solid #634699",
        borderRadius: "0px 0px 9.25635px 9.25635px",
        background: "transparent",
        "&.ck .ck-placeholder:before": {
          color: "#634699",
          fontWeight: 400,
          fontStyle: "regular",
          fontSize: "13.8568px",
          lineHeight: "140%",
        },
      },
    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      background: "transparent",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "9.25635px 9.25635px 0px 0px",
    },
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));

// Custom plugin to disable paste functionality
class DisablePaste {
  constructor(editor) {
    this.editor = editor;
  }

  init() {
    this.editor.model.document.on('change:data', (evt, data) => {
      const viewDocument = this.editor.editing.view.document;

      // Disable paste using Ctrl+V
      viewDocument.on('clipboardInput', (evt, data) => {
        evt.stop();
      });

      // Disable right-click paste
      viewDocument.on('clipboardOutput', (evt, data) => {
        evt.stop();
      });
    });
  }
}

const ShortAnswerNew = ({
  question,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  show,
  secondLevelIndex,
  level,
  thirdLevelIndex,
  setthirdLevelIndex,
  type,
  quizStatus,
  quiz,
  clearResponse,
  setClearResponse
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [editorData, setEditorData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialog(true);
    setImageSrc(src);
  };
  const dispatchCalledRef = useRef(false);
  const { userid } = useSelector((state) => state.authState);

 // const getLocalStorageKey = () => {
  //   return `editorData_${level}_${question?.questionid || question?.submqid}`;
  // };
  const getLocalStorageKey = () => {
    // Generate key based only on question ID or submqid
    return `editorData_${question?.questionid || question?.submqid}`;
  };
  
  

  useEffect(() => {
    if (show) {
      const storedData = localStorage.getItem(getLocalStorageKey());
  
      if (storedData) {
        setEditorData(storedData);
      } else {
        setEditorData("");
      } 
    }
  }, [question?.questionid, question?.submqid, show]);

  const HANDLE_CHANGE = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    localStorage.setItem(getLocalStorageKey(), data);
    handleChange(data);
  };
  useEffect(() => {
    // Check if show is false
    if (!show) {
      // Remove editor data from local storage based on the current level and question ID
      localStorage.removeItem(getLocalStorageKey());
      // Clear editor data state
      setEditorData("");
    }
  }, [show, level, question?.questionid, question?.submqid, thirdLevelIndex]);
  useEffect(() => {
    if (clearResponse) {
      // Clear editor data and local storage
      setEditorData("");
      localStorage.removeItem(getLocalStorageKey());
      handleChange(""); // Notify the parent component that the data is cleared
      
      // Reset the clearResponse state to false after clearing
      setTimeout(() => setClearResponse(false), 0);
    }
  }, [clearResponse, question?.questionid, question?.submqid]); // Ensure dependencies are listed
  
  

  useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
    if (matchingAnswer) {
      const updatedAnswer = matchingAnswer.answer;
      if (!editorData && updatedAnswer !== null) {
        dispatch(fetchQuizStatus(userid, quiz.id));
      }
    }
  }, [questionStatusResponse, count, quizStatus, question?.questionid, userid]);

  useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
    if (matchingAnswer && matchingAnswer.answer !== null) {
      const updatedAnswer = matchingAnswer.answer;

      if (level === 1) {
        questionStatusResponse[count].answer = updatedAnswer;
        setEditorData(questionStatusResponse[count]?.answer);
      } else if (level === 2) {
        const answers = quizStatus?.answer;
        if (Array.isArray(answers) && answers.length > 0) {
          answers.forEach((answer) => {
            const clozeAnswers = answer?.clozeanswers;
            if (Array.isArray(clozeAnswers)) {
              clozeAnswers.forEach((clozeItem) => {
                if (clozeItem.subquestionid === question?.submqid) {
                  questionStatusResponse[count].subQuestions[secondLevelIndex].answer = clozeItem.subanswer;
                  setEditorData(questionStatusResponse[count].subQuestions[secondLevelIndex].answer);
                }
              });
            }
          });
        }
      } else if (level === 3) {
        const answers = quizStatus?.answer;
        if (Array.isArray(answers) && answers.length > 0) {
          answers.forEach((answer) => {
            const clozeAnswers = answer?.clozeanswers;
            if (Array.isArray(clozeAnswers)) {
              clozeAnswers.forEach((clozeItem) => {
                if (clozeItem.subquestionid === question?.submqid) {
                  questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer = clozeItem.subanswer;
                  setEditorData(questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer);
                }
              });
            }
          });
        }
      }
    }
    return () => {
      dispatchCalledRef.current = false;
    };
  }, [count, secondLevelIndex, thirdLevelIndex, level, quizStatus, question?.questionid, questionStatusResponse, setEditorData]);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Grid container sx={{ marginTop: "1%" }}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.2} md={7.3}>
          {/* <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            1.
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "15px",
            }}
          >
            Answer the following questions:-
          </Typography> */}
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingTop: type === "text" ? "0px" : "10px",
            }}
          >
            {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: type === "text" ? "0px" : "20px",
            }}
          >
            {/* {parse(question?.questiontext || question.question || question.subquestiontext || question.submqtext)} */}
            {parse(question?.questiontext || question.question || question.subquestiontext || question.submqtext, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                src={domNode.attribs.src}
                style={{
                  maxWidth: '65%', // Ensures the image fits within the parent container
                  maxHeight: '65%', // Ensures the image fits within the parent container
                  objectFit: 'contain', // Maintains the aspect ratio of the image
                  cursor: 'pointer'
                }}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
          </Typography>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
          {show && question?.defaultmark !== null ? (
  <Typography
    variant="h2"
    sx={{
      fontWeight: 700,
      fontStyle: "regular",
      color: "#634699",
      fontSize: "14px",
      lineHeight: "150%",
      paddingBottom: type === "text" ? "0px" : "20px",
    }}
  >
        ({question?.defaultmark||question?.submqmark ? formatter.format(question.defaultmark||question?.submqmark) : ""} Mark)
  </Typography>) : null}
</div>
          {show ? (
            <React.Fragment>
              <Grid item container className={classes.divContainer} />
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "16px",
                  lineHeight: "140%",
                  paddingBottom: "5px",
                }}
              >
                Answer
              </Typography>

              <Grid item xs={8.8}>
                <div className={classes.richTextEditor}>
                  <CKEditor
                    data={editorData}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.ui.view.toolbar.element.style.display = 'none'; // Hide the toolbar
                      editor.plugins.get('Clipboard').on('inputTransformation', (evt, data) => {
                        data.content = editor.model.change(writer => writer.createDocumentFragment());
                      });
                    }}
                    onChange={(event, editor) => {
                      HANDLE_CHANGE(event, editor);
                    }}
                    config={{ placeholder: "Please type your answer here...." }}
                  />
                </div>
              </Grid>
            </React.Fragment>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ShortAnswerNew;
