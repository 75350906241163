import React from "react";
import { Grid, ImageList, ImageListItem, IconButton, Button, Typography, CardContent, Card, List } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { AddImageIcon, DeleteIcon, DocImageIcon, DocxImageIcon, HeicImageIcon, JpgImageIcon, PdfImageIcon, PngImageIcon, PptImageIcon, PptxImageIcon } from "../../../../assets/icons";
import { ListdotIcon } from "../../../../assets/icons";
import { getThumbnail } from "../../../../utils/AppConstants";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const useStyles = makeStyles((theme) => ({
  svg: {
    "& .MuiSvgIcon-root": {
      width: "16px",
    },
  },
  svgadd: {
    "& .MuiSvgIcon-root": {
      width: "40px",
      height: "40px",
    },
  },
}));

const FileThumbnailNew = (props) => {
  const classes = useStyles();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  const {
    CLASSES,
    selectedImage,
    setPreviewImage,
    handleDelete,
    processFile,
    count,
    secondLevelIndex,
    level,
    thirdLevelIndex,
  } = props;

  const FilterImage = (type) => {
    if (type.image === "image/png") {
      return <PngImageIcon />;
    } else if (type.image === "image/svg+xml") {
      return <AddImageIcon />;
    } else if (type.image === "image/jpeg") {
      return <JpgImageIcon />;
    } else if (type.image === "application/pdf") {
      return <PdfImageIcon />;
    }else if (type.image === "application/vnd.ms-powerpoint") {
      return <PptImageIcon />;
    }else if (type.image === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return <DocxImageIcon />;
    }else if (type.image === "application/msword") {
      return <DocImageIcon />;
    }else if (type.image === "") {
      return <HeicImageIcon />;
    }else if (type.image === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      return <PptxImageIcon />;
    }
    return "";
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (level === 2) {
      const items = Array.from(questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles = items;
    } else if (level === 1) {
      const items = Array.from(questionStatusResponse[count].uploadedFiles);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      questionStatusResponse[count].uploadedFiles = items;
    } else if (level === 3) {
      const items = Array.from(
        questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles
      );
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles = items;
    }
  }

  return (
    // questionStatusResponse[count]?.uploadedFiles==="undefined"?
    <Grid item container xs={12} sx={{ marginBottom: "10px" }}>
      <Grid item xs={12}>
      <Scrollbars style={{ height: 200 }}>
        <div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list-container">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {(
                    questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.uploadedFiles ||
                    questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.uploadedFiles ||
                    questionStatusResponse[count]?.uploadedFiles
                  ).map((image, index) => {
                    let preview = "no-preview";
                    let type = image?.type?.split("/")?.pop();
                    let thumbnail = getThumbnail(image, type);

                    if (["jpeg", "png"].includes(type)) {
                      preview = URL.createObjectURL(image);
                    }

                    return (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided, snapshot) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              marginBottom: "10px",
                              boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                              borderRadius: "5px",
                              background: "#FFFFFF",
                            }}
                          >
                            <Grid item container xs={12}>
                              <Grid item xs={2} sx={{ padding: "5px", textAlign: "center" }}>
                                <FilterImage image={image?.type} />
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "280%",
                                  }}
                                >
                                  {image?.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={1}>
                                {" "}
                                <IconButton className={classes.svg} disableRipple disableFocusRipple disableTouchRipple>
                                  <ListdotIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  className={classes.svg}
                                  onClick={() => handleDelete(index)}
                                  disableRipple
                                  disableFocusRipple
                                  disableTouchRipple
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Grid container display="flex" alignItems="center" justifyContent="center">
            {" "}
            <input type="file" id="select-image" style={{ display: "none" }} multiple onChange={processFile} />
            <label htmlFor="select-image">
              <IconButton variant="contained" component="div" className={classes.svgadd} sx={{ textAlign: "center" }}>
                {questionStatusResponse[count].uploadedFiles.length > 0 ||
                questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.uploadedFiles.length > 0 ||
                (questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.uploadedFiles.length > 0) ? (
                  <AddImageIcon />
                ) : (
                  ""
                )}
              </IconButton>
            </label>
          </Grid>
        </div></Scrollbars>
      </Grid>
    </Grid>
  );
};

export default FileThumbnailNew;
