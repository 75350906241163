/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TakeTestIndex.js
 * This JS file is intended to give a success dashboard of taketest.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 25 Feb 2022
 */

import React from "react";
import { Grid, Box, Button, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Schedule from "./schedule";
import Progress from "./progress";
import btnimg from "../../../assets/images/btnimg.png";
import TAKETESTTITLE from "../../main/login/TakeTestTitle";
import ENTERTESTCODE from "../../main/login/EnterTestCode";
import TESTEXPLORE from "../test/packages/TestExplore";

const USE_STYLES = makeStyles({
  btn: {
    width: "100%",
    "@media (max-width:900px)": {
      width: "80%",
    },
  },
  btninner: {
    paddingLeft: "10px",
    paddingRight: "50px",
  },

  testPackageContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
    height: "calc(100vh - 42vh)",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRadius: "10px",
      padding: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#634699",
    },
  },
});

const TAKETESTINDEX = () => {
  const CLASSES = USE_STYLES();
  return (
    <Box px={2}>
      <Grid container spacing={2} className={CLASSES.testPackageContainer}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} md={8}>
              <TAKETESTTITLE />
            </Grid>{" "}
            <Grid item xs={12} md={8}>
              <ENTERTESTCODE />
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Button
                    component={Link}
                    to="/login/taketest"
                    className={CLASSES.btn}
                    style={{
                      backgroundColor: "#634699",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    <Grid className={CLASSES.btninner}>
                      <img src={btnimg} alt="pic" />
                    </Grid>{" "}
                    <br />
                    <b> Take Test ? </b>{" "}
                  </Button>{" "}
                </Grid>{" "}
              </Grid>{" "}
            </Grid>{" "}
            <Grid item xs={12} md={8}>
              <TESTEXPLORE />
            </Grid>{" "}
          </Grid>{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} md={4}>
              <Progress />
            </Grid>{" "}
            <Grid item xs={12} md={4}>
              <Schedule />
            </Grid>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </Grid>{" "}
    </Box>
  );
};
export default TAKETESTINDEX;
