import React from 'react';
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import TestPackageList from "./TestPackageList";
// import Schedule from "../../dashboard/schedule";
// import Progress from "../../dashboard/progress";
import { Scrollbars } from "react-custom-scrollbars-2";
import PROGRESS from '../dashboard/progress';
import SCHEDULE from '../dashboard/schedule';
import Results from './Results';

const TestResults = () => {
    return (
        <Scrollbars style={{ height: "90vh" }}>
        <Box px={2}>
          <Grid
            container
            spacing={1}
            // columnSpacing={10}
            // className={CLASSES.testPackageContainer}
          >
             <Grid item xs={12}>
            <Button
              sx={{
                color: "#634699",
                fontWeight: "bold",
                textTransform: "none",
                margin:"0px -15px",
              }}
              // onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
            <Grid item xs={12} md={8}>
              <Grid container direction="column">
                <Grid item xs={12} md={8} sx={{paddingBottom:"100px"}}>
                  {/* <TestPackageList /> */}
                  <Results/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12} md={4}>
                  <PROGRESS />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SCHEDULE />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
    );
}

export default TestResults;
