/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class Logo.js
 * This JS file is intended to create a seperate component for logo image and reuse when needed. 
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import logo from "../../../assets/images/cenablecare-logo.png";

const LOGO = (props) => {
  return <img src={logo} alt="Cenable-care" width={props.width} />;
};
export default LOGO;
