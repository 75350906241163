/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class index.js
 * This JS file is intended to reuse the sidebar component
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import { makeStyles } from "@mui/styles";
import {
  MyTestPackageIcons,
  DashboardIcons,
  TestPackageIcons,
  MyScheduleIcons,
  Testresults,
  Resultsicon,
} from "../../../assets/icons";
import { NavLink, useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  useMediaQuery,
  Box,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import LOGO from "../../../views/main/ui_component/logo";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import { fetchEnrolledTests } from "../../../webservice/students/actions/MyTestPackageAction";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CARD_STYLE = styled(Card)(() => ({
  background: "#634699",
  margin: "50px 20px 0px",
  borderRadius: "5px",
  overflow: "hidden",
  position: "relative",
  // "&:after": {
  //   content: '""',
  //   position: "absolute",
  //   width: "157px",
  //   height: "157px",
  //   borderRadius: "50%",
  //   top: "-105px",
  //   right: "-96px",
  // },
}));
const BUTTON_STYLE = styled(Button)(() => ({
  background: "#fff",
  color: "#000",
  overflow: "hidden",
  position: "relative",
  borderRadius: "5px",
  width: "100%",
  fontWeight: "200",
  top: "10px",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));
const DRAWER_HEADER = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const USE_STYLES = makeStyles(() => ({
  drawerPaper: {
    border: "none",
    backgroundColor: "#F8F9FA",
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
  activeClassName: {
    "& > .MuiListItem-button": {
      border: "1px solid #634699",
      borderRadius: "5px",
      color: "#634699",
      backgroundColor: "none",

      "& .MuiListItemIcon-root": {
        backgroundColor: "#9cb966",
        color: "#000000",
        // filter:"invert(100%)",
        mixBlendMode:"difference",
        padding: "5px",
        borderRadius: "5px",
      },
    },
  },
  iconClass: {
    borderRadius: "5px",
    padding: "5px",
    color: "#634699",
    backgroundColor: "#fff",
    minWidth: "10px",
  },
  text: {
    "& > .MuiTypography-root": {
      padding: " 5px 15px 5px 15px",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  button: {
    "& > .MuiListItem-button": {
      marginLeft: "10px",
    },
  },
}));

const SIDEBAR = ({setExpanded,expanded}) => {
  const classes = USE_STYLES();
  let history = useHistory();
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const [count, setCount] = React.useState(false);
  const { userid } = useSelector((state) => state.authState);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  const ITEM_LIST = [
    {
      id: 1,
      text: "Dashboard",
      icon: <DashboardIcons />,
      link: "/dashboard",
      // disabled: count,
    },
    {
      id: 2,
      text: "Test Packages",
      icon: <TestPackageIcons />,
      link: "/test-packages",
      // disabled: false,
    },
    {
      id: 3,
      text: "My Test Packages",
      icon: <MyTestPackageIcons />,
      link: "/my-test-packages",
      // disabled: count,
    },
    {
      id: 4,
      text: "My Schedule",
      icon: <MyScheduleIcons />,
      link: "/schedule",
      // disabled: count,
    },
    {
      id: 5,
      text: "Test Results ",
      icon: <Testresults />,
      link: "/Testresults",
      // disabled: count,
    },
  ];

  useEffect(() => {
    if (testList?.length > 0) {
      setCount(false);
    } else {
      setCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testList]);

  const handleDrawerOpen = () => {
    setExpanded(true);
  };

  const handleDrawerClose = () => {
    setExpanded(false);
  };

  const handleNavClick = (event, action) => {
    if (action) {
      event.preventDefault();
    }
  };
  const isMobileDevice = useMediaQuery('(max-width: 1600px)');

  return (<div>
    {isMobileDevice ? (
       <Drawer
       anchor="left"
       variant="permanent"
       open={expanded}
       classes={{ paper: classes.drawerPaper }}
     >
       <DRAWER_HEADER>
         {expanded ? (
           <>
             <LOGO width="143px" />
             <IconButton
               onClick={handleDrawerClose}
               position="fixed"
               open={expanded}
               sx={{
                //  bottom: "-33px",
                 padding:"2px",left:"15px",
                 backgroundColor: "#634699",
                 color: "white",
                 "&:hover": {
                   backgroundColor:
                     "#634699",
                 },
               }}
             >
               {theme.direction === "rtl" ? (
                 <ChevronRightIcon />
               ) : (
                 <ChevronLeftIcon />
               )}
             </IconButton>
           </>
         ) : (
           <IconButton
             sx={{ color: "#634699" }}
             onClick={handleDrawerOpen}
             edge={false}
           >
             <MenuIcon />
           </IconButton>
         )}
       </DRAWER_HEADER>
 
       <Divider variant="middle" sx={{ color: "#E0E1E2" }} />
       <List sx={{ paddingTop: "30px",paddingBottom:"25px" }}>
       {/* <List sx={{ margin: "0px 0px 0px 10px",
    padding: "12px 0px 25px" }}> */}
         {ITEM_LIST.map((item, index) => (
           <NavLink
             onClick={(e) => handleNavClick(e, item.disabled)}
             key={item.id}
             to={item.link}
             className={classes.link}
             activeClassName={classes.activeClassName}
           >
             <ListItem
               button
               className={classes.button}
               disabled={item.disabled}
             >
               <ListItemIcon className={classes.iconClass}>
                 {item.icon}
               </ListItemIcon>
               {expanded && (
                 <ListItemText primary={item.text} className={classes.text} />
               )}
             </ListItem>
           </NavLink>
         ))}
       </List>
       {expanded && (
         <CARD_STYLE>
           <CardContent sx={{ p: 2 }}>
             <List sx={{ p: 0, m: 0 }}>
               <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                 <ListItemText sx={{ mt: 0 }}>
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                   <HelpIcon
                     sx={{
                       color: "#634699",
                       backgroundColor: "#FFF",
                       borderRadius: "5px",
                       padding: "2px",
                     }}
                   /> &nbsp; &nbsp; &nbsp;
                   <Typography
                     variant="subtitle2"
                     sx={{ color: "#FFF", fontWeight: "bold" }}
                   >
                     Need Help?
                   </Typography></Box> 
                   <Typography  sx={{ fontSize:"11px",color: "#FFF",margin:"5px 0px",textAlign:"center" }}>
                     FAQs
                   </Typography>
                   <div>
                     <BUTTON_STYLE
                      onClick={() => history.push("/HelpIndex")}
                       sx={{
                         fontWeight: "bold",
                         fontSize: "11px",
                         textTransform: "none",
                       }}
                     >
                       Click Here
                     </BUTTON_STYLE>{" "}
                   </div>
                 </ListItemText>
               </ListItem>
             </List>
           </CardContent>
         </CARD_STYLE>
       )}
     </Drawer>
    ) : (
      <div style={{ width: "210px" }}>
      <div style={{display:"flex",justifyContent: "center"}}>
        <LOGO width="143px" />
      </div>
      <List sx={{ paddingTop: "30px",paddingBottom:"25px",marginLeft:"10px" }}>
        {ITEM_LIST.map((item, index) => (
          <NavLink
            onClick={(e) => handleNavClick(e, item.disabled)}
            key={item.id}
            to={item.link}
            className={classes.link}
            activeClassName={classes.activeClassName}
          >
            <ListItem
              button
              className={classes.button}
              disabled={item.disabled}
            >
              <ListItemIcon className={classes.iconClass}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} className={classes.text} />
            </ListItem>
          </NavLink>
        ))}
      </List>
      <CARD_STYLE>
        <CardContent sx={{ p: 2 }}>
          <List sx={{ p: 0, m: 0 }}>
            <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
              <ListItemText sx={{ mt: 0 }}>
                <HelpIcon
                  sx={{
                    color: "#634699",
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#FFF", fontWeight: "bold" }}
                >
                  Need Help?
                </Typography>
                <Typography variant="caption" sx={{ color: "#FFF" }}>
                  FAQs
                </Typography>
                <div>
                  <BUTTON_STYLE
                      onClick={() => history.push("/HelpIndex")}

                    sx={{
                      fontWeight: "bold",
                      fontSize: "11px",
                      textTransform: "none",
                    }}
                  >
                    Click Here
                  </BUTTON_STYLE>{" "}
                </div>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </CARD_STYLE>
    </div>
    )}</div>
   
  );
};

export default SIDEBAR;
