/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/FeedbackApi.js
 * This JS file is intended to provide the api calls related to questionwise and overall feedback.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { MoodleURL } from "../config";
import { GET_METHOD } from "./ApiMethods";
import { decrypt } from "../../../encrypt";

export const getAllFeedbacks = async (userid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_centapi_getallquizattempt&userid=${userid}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
