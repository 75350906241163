/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class ErrorInnerLeft.js
 * This JS file is intended to provide the layout of left container in the error page
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
const ERRORINNERLEFT = () => {
  let history = useHistory();
  return (
    <Grid container>
      <Grid item container direction="column" xs={12}>
        <Grid item>
          <Button
            sx={{
              color: "#FFFFFF",
              fontWeight: "400",
              fontStyle: "normal",
              fontSize: "14px",
              textTransform: "none",
            }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "700",
              fontStyle: "normal",
              fontSize: "35px",
              lineHeight: "45.5px",
            }}
          >
            Uh..Oh it seems
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "700",
              fontStyle: "normal",
              fontSize: "35px",
              lineHeight: "45.5px",
            }}
          >
            you are not
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "700",
              fontStyle: "normal",
              fontSize: "35px",
              lineHeight: "45.5px",
            }}
          >
            registered with
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "700",
              fontStyle: "normal",
              fontSize: "35px",
              lineHeight: "45.5px",
            }}
          >
            Us!
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "#A0AEC0",
              fontWeight: "700",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "19.6px",
            }}
          >
            join the cenable journey
          </Typography>
        </Grid>
        <br />
        <Grid item>
          <Button
            style={{ backgroundColor: "white", color: "#634699", width: "63%" }}
            type="submit"
            variant="contained"
          >
            <b> Login </b>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ERRORINNERLEFT;
