import moment from 'moment';

import pdfThumbnail from '../assets/images/pdf-thumbnail.png';
import wordThumbnail from '../assets/images/word-thumbnail.png';
import noThumbnail from '../assets/images/no-thumbnail.jpg';

export const getTimeSlots = (start, end) => {
  var startTime = moment(start, 'HH:mm');
  var endTime = moment(end, 'HH:mm');

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }

  var timeSlots = [];

  while (startTime <= endTime) {
    let slotStart = new moment(startTime).format('HH:mm');
    let slotEnd = new moment(startTime.add(15, 'minutes')).format('HH:mm');
    // timeSlots.push(`${slotStart}-${slotEnd}`)
    timeSlots.push({
      start: slotStart,
      end: slotEnd,
    });
  }

  return timeSlots;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getThumbnail = (file, type) => {
  if (file && type) {
    let thumbnail = '';

    if (type === 'pdf') thumbnail = pdfThumbnail;
    else if (type === 'msword') thumbnail = wordThumbnail;
    else if (['jpeg', 'png'].includes(type)) {
      if (typeof file === 'object') thumbnail = URL?.createObjectURL(file);
      else thumbnail = file;
    } else thumbnail = noThumbnail;

    return thumbnail;
  }
};

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const urltoFile = async (url, name, type) => {
  if (url && name) {
    return new Promise((resolve, reject) => {
      resolve(dataURLtoFile(url, name))
    })
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};
export const calculateQuestionLevels = (questions, index1, index2, index3) => {


  if (questions[index1]?.subQuestions?.length === 0) {
    return 1;
  } else if (
    questions[index1]?.subQuestions[index2]?.subQuestions === ""
  ) {
    return 2
  } else {
    return 3;
  }

}
 export const alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

 export const colorPicker = (status, count, index) => {
  if (count === index) {
    return ["#634699", "none", "#FFFFFF", "6vw"];
  } else if (status === "Not Visited") {
    return ["#FFFFFF", "1px solid #634699", "#000000", "6vw", "20px"];
  } else if (status === "Answered") {
    return ["#37AB97", "none", "#FFFFFF", "6vw"];
  } else if (status === "Not Answered") {
    return ["#ED6182", "none", "#FFFFFF", "6vw", "18px"];
  } else if (status === "Answer Later") {
    return ["#FFC15C", "none", "#000000", "6vw"];
  }
};
export const romanNumbers = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
// export const NeedhelpsectionTopic = ["Test Taking", "Test Packages", "Tips for Success", "Contact Support", "Test Formats"];
export const NeedhelpsectionTopic = [
  {
    name: "Test Taking",
    questions: [
      {
        question: "How do I start a test?",
        answer: ["Answer 1 for question 1"],
      },
      {
        question: "Can I pause a test and resume it later?",
        answer: ["Answer 1 for question 2"],
      },
      {
        question: "What happens if I lose internet connection during a test?",
        answer: ["Answer 1 for question 3"],
      },
      {
        question: "How do I submit a test?",
        answer: ["Answer 1 for question 4"],
      },
      {
        question: "Can I review my test after submitting it?",
        answer: ["Answer 1 for question 5"],
      },
    ],
  },
  {
    name: "Test Packages",
    questions: [
      {
        question: "Question 1",
        answer: ["Answer 1 for question 1 of Test Packages"],
      },
      {
        question: "Question 2",
        answer: ["Answer 1 for question 2 of Test Packages"],
      },
      {
        question: "Question 3",
        answer: ["Answer 1 for question 3 of Test Packages"],
      },
    ],
  },
];
export const allinone = [
  {
    section: "Taking Test",
    questions: [
      {
        id: 1,
        question: "How do I start a test?",
        answer: (
          <div>
            <strong>Direction 1 </strong>
            <br />
            Dashboard <strong>&gt;</strong> My Test Packages <strong>&gt;</strong> click on the Required Test Package <strong>&gt;</strong>
             <br />Click on the Required Test Package <strong> &gt; Click on Take Test </strong>
            <br /> <br />
            <strong>Direction 2 (if you are an external Test)</strong>
            <br />
            Dashboard <strong>&gt;</strong> My Test Packages <strong>&gt;</strong> click on Take Test <strong>&gt;</strong>
            <br />
            Enter the required Test Code <strong>&gt;</strong> Click on Begin Test
          </div>
        )
      },
      
      { id: 2, question: "Can I pause a test and resume it later?", answer: "No, once the test starts, you can only submit the test and then exit." },
      { id: 3, question: "What happens if I lose internet connection during a test?", answer: "If your internet connection decides to take a little break during the quiz, no worries! The quiz status will be marked as 'InProgress' and you'll get to continue where you left off once the connection comes back. Just remember, you may need to answer the questions again, but think of it as a chance to showcase your skills and knowledge even better the second time around. Keep that determination shining bright." },
      { id: 4, question: "How do I submit a test?", answer: "\"After completing all the questions, you'll be faced with a crucial screen. There, you'll find the 'Submit Test' button. Click it to finalize your efforts and mark the end of your test. Good luck!\"" },
      {
        id: 5,
        question: "Can I review my test after submitting it?",
        answer: (
          <div>
            Yes, you can! Select the test results tab on the menu bar. You will be able <br/>to see the test that you have attempted.
            <br />
            <strong>Note: For MCQs format question papers you will be able to see the <br/>result immediately.</strong>
            <br />
            For descriptive question papers you will find the results after a certain <br/>period of time.
          </div>
        )
      }
    ],
  },{
    section: "My Schedule",
    questions: [
      {id: 6, question: "What is “My Schedule”?", answer: "'My Schedule' simplifies the process of booking meetings with tutors for getting help with your questions. It lets you easily see when tutors are available and choose a time that works best for both of you. This way, you can get personalized assistance and clear up any doubts efficiently. It's like setting up a quick and focused appointment to get the help you need, when you need it, all within your e-learning platform."},
      {id: 7, question: "How can I Schedule a meeting with Tutor?", answer: (<div>To schedule a meeting with a tutor online, first log in to your account and navigate to the scheduling section such as <strong>'My Schedule'</strong> or <strong>'Schedule now'</strong>.<br /> <br/>Choose the relevant test package and the section, then select a date from the enabled calendar. Ensure the tutor's availability for your chosen slot, enter meeting details like title, time and decriptions then finalize by clicking <strong>'SAVE'</strong>. <br /><br/>After confirmation, you'll receive a notification and can manage your scheduled meetings through the platform's scheduling interface.</div>)},
      {id: 8, question: (<div>Where can I manage and view details of my scheduled<br/> tutoring meetings after they have been confirmed?</div>), answer: "After your tutoring meetings have been confirmed, you can usually manage and view their details in the 'My Schedule' section within your account on the tutoring platform. This area typically provides a list of Title-Topic names of all scheduled meetings, including information such as date, time, subject or topic, and meeting ID (if applicable) in the description for each session."},
      {id: 9,question: (<div>Once a tutoring session is successfully scheduled,<br/> how do I typically receive confirmation and what information<br/> is included in this confirmation?</div>), answer: (<div>Once a tutoring session is successfully scheduled on an online platform, you typically receive confirmation through:<br/> <br/> <strong>Email Notification:</strong> The platform sends an email to the address associated with your account, confirming the details of the scheduled session.<br/> <br/><strong> In-App Notification:</strong> You may receive an immediate notification within the platform itself, confirming the booking and providing relevant details.<br/><br/> The confirmation usually includes essential information such as <br/> Date and Time, Title or Description, and Meeting Link if applicable.</div>)},
      {id: 10, question: "How many meetings can I schedule in a day?", answer: "Typically, users can schedule up to one meetings per day."
  }
    ]
  },  
  {
    section: "Test Packages",
    questions: [
      { id: 11, question: "How do I purchase a Test Package?", answer: (<div>Dashboard &gt; Test Packages &gt; Click on the required Test Package to <br/>check the details &gt; click on cart icon <br/><strong>Note: Please buy the following packages after you ensured the <br/>contents inside it .</strong></div>) },
      { id: 12, question: "How many tests are included in each Test Package?", answer: "That varies from Package to Package." },
      { id: 13, question: "How long do I have access to a Test Package after purchasing it?", answer: "You will have year long access to the test package from the date of Purchase." },
      { id: 14, question: "What happens if I run out of tests in my Test Package?", answer: "Well Bravo ! you have completed the test package . you can go freely choose other packages to test yourself to the limit ." },
      { id: 15, question: "How do I access my Test Package tests?", answer: "You Can go to My Test Packages tab in the Menu Bar." },
    ],
  },
  {
    section: "Tips for Success",
    questions: [
      { id: 16, question: "Prepare and organize your study materials", answer: "Create a study plan and schedule that works for you. Organize your study materials, notes, and textbooks in a way that you can easily find and review them. Review the test syllabus, marking criteria, and test format beforehand to get an idea of what to expect." },
      { id: 17, question: "Understand the test format", answer: "Knowing the test format can help you prepare and prioritize the most important topics to study. Familiarize yourself with the types of questions that will be on the test, such as multiple-choice, short answer, or essay questions." },
      { id: 18, question: "Practice, practice, practice", answer: "Practice makes perfect! Use past tests and practice quizzes to help you prepare for the test. Taking practice tests will help you identify areas where you need to focus more, as well as familiarize yourself with the test format and types of questions." },
      { id: 19, question: "Manage your time effectively", answer: "Time management is essential during a test. Read through the instructions and questions carefully, and allocate your time according to the weightage of each question or section. Don't spend too much time on any one question." },
      { id: 20, question: "Review your answers", answer: "Always review your answers before submitting your test. Check for any errors, spelling mistakes, or omissions. Make sure you have answered every question." },
      { id: 21, question: "Stay calm and focused:", answer: "It's normal to feel anxious during a test, but don't let it overwhelm you. Take deep breaths, stay calm, and focused. If you don't know an answer, don't panic; move on to the next question and come back to it later." },
      { id: 22, question: "Get enough rest and eat well", answer: "A good night's sleep and healthy eating habits can help you stay focused and energized during the test. Avoid staying up late the night before the test and eating heavy or unhealthy meals." },
    ],
  },
];

export const questionFormats = [
  {
    section: "Here below are the following types of question formats",
    questions: [
      {
        name: "MCQ",
        description: "A multiple-choice question is a type of question in which a test-taker is asked to select the correct answer from a list of options provided. The question typically consists of a stem or a problem statement, followed by a set of choices or options."
      },
      {
        name: "Short Answer Questions",
        description: "Short answer questions are a type of question in which the test-taker is asked to provide a brief and specific answer to a question. Short answer questions typically require the test-taker to provide a response that is one word, a phrase, or a sentence long."
      },
      {
        name: "True Or False",
        description: "These questions are designed to elicit a simple 'yes' or 'no' response."
      },
      {
        name: "Arrange Elements",
        description: "Arrange the appropriate objects (In column 2) to the respective statements (in Column 1)."
      },
      {
        name: "Essay Questions",
        description: "Long answer questions are a type of question where the response requires more detailed and elaborate explanations. These questions typically require the test-taker or respondent to provide a thorough analysis or discussion of a particular topic or issue."
      },
      {
        name: "Subquestions",
        description: "Subquestions are specific questions that are part of a larger question or topic. They are used to guide the respondent's answer and provide structure to a long answer question. Subquestions can help break down a complex question into more manageable parts and ensure that the respondent addresses all the key aspects of the topic."
      }],
  },
  
  
];
export const video = [
  {
    section: "Explore the diverse range of video tutorials covering different aspects of the exam experience.",
    questions: [
      {
        name: "Login",
        description: "This video demonstrates the login process for accessing the exam platform. It includes step-by-step instructions on how to log in, the required credentials, and any additional security measures.",
        answer: "https://youtu.be/BUxStIk1UOU"
      },
      {
        name: "Question UI",
        description: "This video demonstrates the login process for accessing the exam platform. It includes step-by-step instructions on how to log in, the required credentials, and any additional security measures.",
        answer: "https://youtu.be/BdOXmfwEDFQ"
      },
      {
        name: "Before the exam",
        description: "Before the exam, there are essential preparations and guidelines to follow. This video provides valuable information about what candidates need to do before starting the exam, including checking technical requirements, understanding exam rules, and managing any necessary documentation.",
        answer: "https://youtu.be/DleAibEjxE4"
      },
      {
        name: "During the exam",
        description: "This video guides you through the actual exam process. It covers crucial aspects such as time management, navigating between questions, using tools within the exam interface (if applicable), and handling any technical issues that may arise during the exam.",
        answer: "https://youtu.be/RKWEtQf5UVQ"
      },
      {
        name: "After the exam",
        description: "After completing the exam, certain actions need to be taken. This video explains what to expect post-exam, such as result announcements, score reports, and any necessary follow-up steps.",
        answer: "https://youtu.be/a3ZZ3b3WnXw"
      },
      ],
  },
];
