/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class views/students/test/packages/PendingTests.js
 * This JS file is intendend to create a card component for showing the tests that need to to be attempt.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import React, { Fragment } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { TestPackageListIcon } from "../../../../assets/icons";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchQuizDetails } from "../../../../webservice/students/actions/MyTestPackageAction";

const USE_STYLES = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "3px",
    borderRadius: "5px",
    width: 26,
    height: 26,
  },
  gradeIcon: {
    "& .MuiSvgIcon-root": {
      fill: "#E2DDEC",
    },
  },
});

const INPROGRESS = (props) => {
  const params = useParams();
  const courseid = params.subject;
  const classes = USE_STYLES();
  const { quizList } = useSelector((state) => state.mytestpackagestate);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchQuizDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {quizList && quizList.quizzes && quizList.quizzes.length
        ? quizList.quizzes.map((quiz) => {
            return quiz.coursemodule === props.module.id ? (
              <Fragment key={quiz.id}>
                <Grid item xs={2} md={1.3}>
                <TestPackageListIcon sx={{ backgroundColor: props.module.locked ? "#A9A9A9" : "#634699",
    padding: "3px",
    borderRadius: "5px",
    width: 26,
    height: 26,}}
                  //  className={classes.icon}
                    />
                </Grid>
                <Grid item xs={4} md={4.7}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontStyle: "regular",
                      lineHeight: "22px",
                      color: props.module.locked ? "#A9A9A9" : "#634699",
                    }}
                  >
                    {quiz.name}
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={6} md={5}>
                      <Button
                        fullWidth
                        component={Link}
                        disabled={props.module.locked}
                        to={`/my-test-packages/${courseid}/test/${quiz.id}`}
                        variant="contained"
                        sx={{
                          fontSize:"12px",
                          padding:"10px",
                          textTransform: "none",
                          backgroundColor: "#634699",
                          "&:hover": {
                            backgroundColor: "#634699",
                          },
                        }}
                      >
                        Continue Attempt   
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null;
          })
        : null}
    </Grid>
  );
};

export default INPROGRESS;
