/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class conversations.js
 * This JS file is intended to display the conversation details of the student user.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { makeStyles } from "@mui/styles";
import { Card, Typography, CardContent, Grid, List } from "@mui/material";
const USE_STYLES = makeStyles({
  card: {
    width: "100%",
    height: "383px",
    margin: "auto",
    borderRadius: "5px",
    transition: "0.3s",
    // "&:hover": {
    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    // },
  },
});
const CONVERSATIONS = () => {
  const classes = USE_STYLES();
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Typography
              style={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "25.2px",
                paddingLeft: "21px",
                paddingTop: "28px",
              }}
            >
              Conversations
            </Typography>
            <CardContent>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "19.6px",
                    textAlign: "center",
                  }}
                >
                  Coming soon!
                </Typography>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default CONVERSATIONS;
