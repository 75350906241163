/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class index.js
 * This file acts as the intial renderering page to App.js.
 *
 * @author manu.nair@centelon.com
 * @since 17 Feb 2022
 *
 * @modifiedBy tincy.thomas@centelon.com
 * @modifiedDate 18 Feb 2022
 */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./webservice/students/store";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
