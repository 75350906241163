/**All right reserved under Cenablecare Edutech © 2022 */
/**
 * @class TestPackageList.js
 * This JS file is intended to create a component used for listing the test packages coming under one subject
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */
import {
  Typography,
  Grid,
  Card,CardHeader,
  CardContent,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Dialog,
  List,
  DialogContent,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { AddToCartIcon, ExpandIcon, TestPackageListIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseList,
  fetchCourseContents,
  fetchGradeSubject,
  carteditemsaction,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useEffect } from "react";
import Image from "../../../../assets/images/collapse-testicon1.svg";
import Pic from "../../../../assets/images/collapse-sessionicon1.svg";
import { useState } from "react";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import mailicon from "../../../../assets/images/cartaddedimage.png"
// import mailicon from "../../../assets/images/cartaddedimage.png";



const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));

const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));
const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
const USE_STYLES = makeStyles({
  testContainer: {
    padding: "50px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
   popover: {
    '& .MuiPopover-paper': {
      marginTop: '127px',
      marginLeft: '16px',
    },
  },
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop:"10px",
  },
  carticon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop:"5px",
    "&:hover": {
      backgroundColor: "rgb(99 70 153 / 40%)",
    },
  },
  card: {
    margin: "10px",
    "& .MuiCardHeader-title": {
      color: "#634699",
      fontWeight: "bold",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  test: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "35px",
  },
  session: {
    backgroundImage: `url(${Pic})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "25px 15px",
    // backgroundSize: 'contain',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
// });
  select: {
    '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      // paddingRight: '10px',
      overflow: "inherit",
      // padding: "8.5px 19px",  
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      marginRight:"-24px",
      // padding: "8.5px 19px", 
    },
    "&:after": {
      borderColor: "transparent",
      // padding: "8.5px 19px", 
    },
    "&.MuiSelect-select": {
      // padding: "8.5px 19px", 
    }, '& .MuiInputBase-input': {
      padding: '10px 26px 10px 12px', 
    },
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      fontSize: "0.7rem",
      transform: "rotate(90deg)",
      marginRight: "5px",
    },
    "& .MuiInputBase-formControl": {
      textAlign: "center",
      fontSize: "0.9rem",
      color: "#634699",
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px rgb(0 0 0 / 2%)",
    borderRadius: "5px",
      padding:"0px"
    },
  },
});
const TEST_PACKAGE_LIST = () => {
  const [expanded, setExpanded] = React.useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [hover,sethover]=useState(false);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [showSyllabus, setShowSyllabus] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const HANDLE_EXPAND_CLICK = (index, id) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
    dispatch(fetchCourseContents(id, userid));
  };
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const history = useHistory();
  const { courseList } = useSelector((state) => state.testPackageListReducer);
  const { userid } = useSelector((state) => state.authState);
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );
  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );
  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  useEffect(() => {
    dispatch(fetchGradeSubject());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { gradeSubjectList } = useSelector(
    (state) => state.testPackageListReducer
  );
  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
  };
  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const getSearchResult = () => {
    let enrolledCourses = [];
    let nonEnrolledCourses = [];
  
    let list = courseList?.filter((val) => {
      if (searchTerm === "" && selectedGrade === "" && selectedSubject === "") {
        return val;
      } else if (
        val.displayname.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedGrade
          ? val.customfields[0].name === "Grades" &&
            val.customfields[0].valueraw === selectedGrade.toString()
          : true) &&
        (selectedSubject
          ? val.customfields[1].name === "Subjects" &&
            val.customfields[1].valueraw === selectedSubject.toString()
          : true)
      ) {
        return val;
      }
    });
  
    list?.forEach(course => {
      if (course.enroluistat) {
        enrolledCourses.push(course);
      } else {
        nonEnrolledCourses.push(course);
      }
    });
  
    if (enrolledCourses.length || nonEnrolledCourses.length) {
      return { enrolledCourses, nonEnrolledCourses };
    } else {
      return { enrolledCourses: [{ format: "no-topics" }], nonEnrolledCourses: [{ format: "no-topics" }] };
    }
  };
  
  // const { cartItems } = useSelector(
  //   (state) => state.testPackageListReducer
  // );
  const [cartOpen, setCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const handleAddToCart = (itemList) => {
    dispatch(carteditemsaction(itemList));
    setLastAddedItem(itemList);
    setCartOpen(true);
  };
  const handleCartClose = () => {
    setCartOpen(false);
    setLastAddedItem(null);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState('');

  const handleImageClick = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    setOpenDialog(true);
  };
  const [isCourseExpanded, setIsCourseExpanded] = useState(true);

const toggleCourseExpansion = () => {
  setIsCourseExpanded(!isCourseExpanded);
};
const [isCourseExpandeds, setIsCourseExpandeds] = useState(true);

const toggleCourseExpansions = () => {
  setIsCourseExpandeds(!isCourseExpandeds);
};
const enrolledCourses = getSearchResult().enrolledCourses;
  return (
    <>
      <Grid container direction="column" sx={{height:"60px"}}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "2rem",
                  // lineHeight: "56px",
                }}
              >
                Test Packages
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid
                container
                spacing={1}
                // marginBottom="10px"
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item xs={3} md={3.2}>
                  <FormControl
                    fullWidth
                    className={CLASSES.formcontrol}
                    variant="outlined"
                  >
                    {/* <InputLabel sx={{ background: "white" }} shrink>
                      Grade
                    </InputLabel> */}
                    <Select
                    sx={{overflow: "inherit",padding:"0px"}}
                      id="grouped-select"
                      label="Grouping"
                      displayEmpty
                      onChange={handleGradeChange}
                      className={CLASSES.select}
                      defaultValue={""}
                      IconComponent={ExpandIcon}
                    >
                      <MenuItem
                       className={CLASSES.popover}
                        value=""
                        sx={{
                          color: "#634699",
                          backgroundColor: "transparent",
                          "&.Mui-selected":{
                            background: "#634699",color: "white"
                          }
                        }}
                      >
                        Grade
                      </MenuItem>
                      {gradeSubjectList && gradeSubjectList?.grades?.length
                        ? gradeSubjectList?.grades?.map((grade, index) => (
                            <MenuItem
                            className={CLASSES.popover}
                              key={index}
                              value={grade.id}
                              sx={{
                                color: "#634699",
                                backgroundColor: "white",
                                "&.Mui-selected":{
                                  background: "#634699",color: "white"
                                }
                              }}
                            >
                              {grade.gradename}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} md={3.2}>
                  <FormControl
                    fullWidth
                    className={CLASSES.formcontrol}
                    variant="outlined"
                  >
                    {/* <InputLabel sx={{ background: "white" }} shrink={true}>
                      Subject
                    </InputLabel> */}
                    <Select
                      id="grouped-select"
                      label="Grouping"
                      displayEmpty
                      onChange={handleSubjectChange}
                      className={CLASSES.select}
                      defaultValue={""}
                      IconComponent={ExpandIcon}
                    >
                      <MenuItem
                        value=""
                        sx={{
                          color: "#634699",
                          backgroundColor: "white",
                        }}
                      >
                        Subject
                      </MenuItem>
                      {selectedGrade
                        ? gradeSubjectList && gradeSubjectList?.mapping?.length
                          ? gradeSubjectList?.mapping?.map((grade, index) => {
                              return (
                                grade.grade === selectedGrade &&
                                (grade?.gradesubjects?.length
                                  ? grade?.gradesubjects?.map(
                                      (subject, index) => (
                                        <MenuItem
                                          key={index}
                                          value={subject.subjectid}
                                          sx={{
                                            color: "#634699",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          {subject.subjectname}
                                        </MenuItem>
                                      )
                                    )
                                  : null)
                              );
                            })
                          : null
                        : gradeSubjectList && gradeSubjectList?.subjects?.length
                        ? gradeSubjectList?.subjects?.map((subject, index) => (
                            <MenuItem
                              key={index}
                              value={subject.id}
                              sx={{
                                color: "#634699",
                                backgroundColor: "white",
                              }}
                            >
                              {subject.subject}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SEARCH>
            <SEARCH_ICON_WRAPPER>
              <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
            </SEARCH_ICON_WRAPPER>
            <STYLED_INPUT_BASE
              type="text"
              placeholder="Search Test Packages"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />{" "}
          </SEARCH>
        </Grid>
  
      {enrolledCourses.length > 0 && (
        <Grid item xs={12} md={12}>
        {/* Enrolled Courses Section */}
        <CardHeader  sx={{marginRight:"40px", padding:"10px"}}
title={
  <Typography
    sx={{
      color: "#634699",
      fontWeight: 700,
      fontSize: "1rem",
    }}
  >
    Enrolled Courses
  </Typography>
}
action={
  <IconButton
    onClick={toggleCourseExpansion}
    aria-expanded={isCourseExpanded}
    aria-label="show more"
    sx={{
      transform: isCourseExpanded ? "rotate(90deg)" : "rotate(0deg)",
      // transition: "transform 0.3s ease",
    }}
  >
    <ExpandIcon sx={{fontSize:16}}/>
  </IconButton>
}
/><Collapse in={isCourseExpanded} timeout="auto" unmountOnExit>
<CardContent>
        {courseList && courseList.length
  ? getSearchResult().enrolledCourses.length > 0
    ? getSearchResult()
        .enrolledCourses.sort(
          (a, b) => a.categorysortorder - b.categorysortorder
        )
        .map((data, index) => {
              let quizCount = 0;
              let assignCount = 0;
              return data.format === "topics" &&
                data.categoryid === 2 &&
                data.visible === 1 ? (
                <Fragment key={data.id}>
                  <Grid item xs={12} key={data.id}>
                    <Card sx={{ marginBottom: "10px",padding:"10px" }}>
                      {/* <CardContent> */}
                        <Grid container>
                          <Grid item xs={2} md={1}>
                            {/* <TestPackageListIcon className={CLASSES.icon} />  */}
                            {/* {data.imageurl?(<img src={data.imageurl} alt="ww"className={CLASSES.icon}width={26} height={26} />):<TestPackageListIcon className={CLASSES.icon} />} */}
                            {data.imageurl ? (
      <img
        src={data.imageurl}
        alt="courseurl"
        width={26}
        height={26}
        style={{cursor:"pointer",marginTop:"8px"}}
        // className={CLASSES.icon} 
        onClick={() => handleImageClick(data.imageurl)}
      />
    ) : (
      <TestPackageListIcon className={CLASSES.icon} />
    )}

    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}  PaperProps={{
        style: {
          background: "#FFFFFF",
          boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
          borderRadius: "5px",
          padding: "1%",
          // width: "1000px",
          // width: "579px",
        },
      }}
      // open={snackbar.open}
      sx={{
        backgroundColor: "white",
      }}>
      <DialogContent>
        <img src={clickedImageUrl} alt="Clicked Image" style={{ maxWidth: '100%', height: 'auto' }} />
      </DialogContent>
    </Dialog>
                          </Grid>
                          {/* <Grid item xs={4} md={4}> */}
                          <Grid item xs={7} md={7}>
                            <Typography
                              sx={{
                                color: "#634699",
                                fontWeight: 700,
                                //anusha
                                fontSize: "14px",
                                lineHeight: "28.4px",
                              }}
                            >
                              {data.displayname}
                              <span
                                style={{
                                  color: "#718096",
                                  fontWeight: 400,
                                  //anusha
                                  fontSize: "14px",
                                  lineHeight: "28.4px",
                                  marginLeft:"20px"
                                }}
                              >
                              {data.customfields[0].name === "Grades" && data.customfields[0].value !== null && !isNaN(data.customfields[0].value)
  ? data.customfields[0].value + (
      parseInt(data.customfields[0].value) % 100 === 11 || parseInt(data.customfields[0].value) % 100 === 12 || parseInt(data.customfields[0].value) % 100 === 13
        ? "th"
        : parseInt(data.customfields[0].value) % 10 === 1
          ? "st"
          : parseInt(data.customfields[0].value) % 10 === 2
            ? "nd"
            : parseInt(data.customfields[0].value) % 10 === 3
              ? "rd"
              : "th"
    )
  : data.customfields[0].value !== null ? data.customfields[0].value : ""
}
                              </span>
                              <br /> <Typography
                                              sx={{
                                                color: "#000",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "38.4px",
                                              }}
                                            >
                                              {data.verificationstatus}
                                            </Typography>
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "16.8px",
                                }}
                              >
                                {data.summary.replace(/<[^>]+>/g, "")}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={3} md={1.5}>
                            {data?.paypalenrollments.map(
                              (pricedata, index) => (
                                <Typography
                                  key={index}
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "38.4px",
                                  }}
                                >
                                   {new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: pricedata?.currency,
                                    maximumSignificantDigits: 3,
                                  }).format(pricedata?.actualcost)}
                                  {/* {new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: pricedata?.currency,
                                    maximumSignificantDigits: 3,
                                  }).format(pricedata?.actualcost === "0" ? pricedata?.cost : pricedata?.actualcost)} */}
                                </Typography>
                              )
                            )}
                          </Grid>
                          {/* <Grid item xs={2} md={1}></Grid> */}
                          <Grid item xs={2} md={1.5}>
                            {/* we have hide cart button for api to store the value */}
                            {/* <IconButton className={CLASSES.carticon} onClick={() => handleAddToCart(data)}>
                              <AddToCartIcon sx={{fontSize:20}}/>
                            </IconButton> */}
                            {cartOpen && lastAddedItem === data && (
      // <CartPopup cartItems={[data]}  />
      <Dialog
      open={cartOpen}
      onClose={handleCartClose}
      PaperProps={{
        style: {
          maxHeight: "none",
        },
      }}
      BackdropProps={{ invisible: true }}
    >
      <Grid container item xs={12}>
        <Card
          className={CLASSES.testContainer}
          sx={{ minWidth: 600 }}
        >
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={mailicon} alt="mailicon" width="100px" height="100px" />
          </Grid>
          <br />

          <Typography
          variant="h1"
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "160%",
              textAlign: "center",
            }}
          >
           {data.displayname}<br/>
           successfully carted

          </Typography>
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Grid>
        </Card>
      </Grid>
    </Dialog>
    )}
                          </Grid>
                          <Grid item xs={3} md={1}>
                          <EXPAND_MORE
                                expand={expanded === index}
                                onClick={() =>
                                  HANDLE_EXPAND_CLICK(index, data.id)
                                }
                                aria-expanded={expanded}
                                aria-label="show more"
                              >
                                <ExpandIcon sx={{fontSize:16}}/>
                              </EXPAND_MORE>
                            {/* <Button
                              variant="outlined"
                              style={{
                                color: "gray",
                                border: "1px solid #634699",
                                textTransform: "none",
                              }}
                              onClick={() =>
                                HANDLE_EXPAND_CLICK(index, data.id)
                              }
                              aria-expanded={expanded}
                            >
                              View Syllabus
                            </Button> */}
                          </Grid>
                         
                        </Grid>
                      {/* </CardContent> */}
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    {coursecontents && coursecontents.length
                      ? coursecontents.map((content, indexcontent) => {
                          if (
                            content &&
                            content.modules &&
                            content.modules.length
                          ) {
                            let quizlist = content.modules.filter(
                              (e) => e.modname === "quiz"
                            );
                            if (quizlist.length) {
                              quizCount = quizCount + quizlist.length;
                            }
                            let assignlist = content.modules.filter(
                              (e) => e.modname === "assign"
                            );
                            if (assignlist.length) {
                              assignCount = assignCount + assignlist.length;
                            }
                          }
                          return coursecontents.length - 1 ===
                            indexcontent ? (
                            <Collapse in={expanded === index} key={index}>
                              <Card sx={{marginBottom:"16px"}}>
                                {/* <CardContent> */}
                                  <Grid item xs={12}>
                                    <Grid container>
                                      {/* <Grid item md={1}></Grid> */}
                                      <Grid container>
    <Grid item xs={12} md={1.8} className={CLASSES.session}>
      {/* This Grid item will display the image */}
    </Grid>
    <Grid item xs={12} md={1} className={CLASSES.textContainer}>
      <span
        style={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "60.667px",
        }}
      >
        {filteredContents.length}
      </span>
      <Typography
        sx={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "12px",
          marginTop:"-10px",
        }}
      >
        Sessions
      </Typography>
    </Grid>
    <Grid item xs={12} md={1.8} className={CLASSES.test}>
      {/* This Grid item will display the image */}
    </Grid>
    <Grid item xs={12} md={4} className={CLASSES.textContainer}>
      <span
        style={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "60.667px",
        }}
      >
       {quizCount}
      </span>
      <Typography
        sx={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "12px",marginTop:"-10px",
        }}
      >
        Tests
      </Typography>
    </Grid>
    <Grid item xs={12} md={2}>
                                          <Grid item>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "1vw",marginTop: "40px",
                                                lineHeight: "150%", width:"200px",textAlign:"center",
                                              }}
                                            >
                                              {data.verificationstatus}
                                            </Typography>
                                          </Grid>
                                          {data.enroluistat === false &&
                                          filteredContents.length !== 0 ? (
                                            <Grid item>
                                              {/* <Button
                                                component={Link}
                                                to={`/test-packages/orderconfirmation/${data.id}`}
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                  marginTop: 1,
                                                  color: "#634699",
                                                  borderColor: "#634699",
                                                  fontWeight: "bold",
                                                  textTransform: "none",
                                                  "&:hover": {
                                                    borderColor: "#634699",
                                                  },
                                                }}
                                              >
                                                Enroll
                                              </Button> */}
                                            </Grid>
                                          ) : (
                                            ""
                                          )}
                                          {data.cancustomize === 1 &&
                                          filteredContents.length !== 0 ? (
                                            <Grid item>
                                              <Button
                                                component={Link}
                                                to={`/test-packages/${data.id}/${userid}`}
                                                onMouseOver={()=>sethover(true)} 
   onMouseOut={()=>sethover(false)} 
   onClick={() => {
    localStorage.setItem("userid", userid);
    history.push(`/test-packages/${data.id}/${userid}`);
  }}

                                                variant="outlined"
                                                // fullWidth
                                                sx={{
                                                  marginLeft:"50px",
                                                  fontSize:"12px",
                                                  color: "#fff",
                                                  borderColor: "#634699",
                                                  textTransform: "none",
                                                  backgroundColor: "#634699",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "#7C55C5",
                                                    borderColor: "#634699",
                                                  },
                                                }}
                                              >
                                                {hover?"Customise":"Customise"}
                                              </Button>
                                            </Grid>
                                          ) : (
                                            ""
                                          )}
                                        </Grid>
  </Grid>
  
  {/* </Grid> */}
                                    </Grid>
                                    <Grid container>
                                    <Grid item xs={8} sx={{ marginTop: "22px" }}>
                                          {showSyllabus && (
                                            <div>
                                              {filteredContents.map((item) => (
                                                <Grid>
                                                  <List key={item.id}>
                                                    <Typography
                                                      sx={{
                                                        color: "#634699",
                                                        fontWeight: 400,
                                                        fontSize: "13px",
                                                        lineHeight: "140%",
                                                        marginLeft: "50px",
                                                      }}
                                                    >
                                                      {item.name}
                                                    </Typography>
                                                  </List>
                                                </Grid>
                                              ))}
                                              
                                            </div>
                                          )}
                                        </Grid>
                                    <Grid item xs={4}>
                                      {/* <Grid item xs={2.5}> */}
                                       
                                      </Grid>
                                    </Grid>
                                    <>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            textTransform: "none",
                                            background: "rgba(224, 218, 235, 0.74)",
                                            color: "#634699",
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            padding: "8px",
                                            marginTop: "8px",
                                            fontSize:"12px",
                                            borderRadius: "4px",
                                            "&:hover": { background: "rgba(224, 218, 235, 0.74)", boxShadow: "none" },
                                          }}
                                          fullWidth
                                          onClick={() => setShowSyllabus(!showSyllabus)}
                                        >
                                          {showSyllabus ? "Show Less" : "Show Test Syllabus"}
                                        </Button>
                                      </>
                                  </Grid>
                                {/* </CardContent> */}
                              </Card>
                            </Collapse>
                          ) : null;
                        })
                      : null}
                  </Grid>
                </Fragment>
              ) : data.format === "no-topics" ? (
                <Grid item xs={12} key={data.format} textAlign="center">
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                    }}
                  >
                    No Result Found..!
                  </Typography>
                </Grid>
              ) : null;
            })
            : // Message when there are no enrolled courses
            <Grid item xs={12}  textAlign="center">

            <Typography
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "140%",
            }}
          >No enrolled courses found!</Typography></Grid>
        : null} </CardContent>
</Collapse>
      </Grid>
      )}
        
  
        <Grid item xs={12} md={12}>
          {/* Non-Enrolled Courses Section */}
          <CardHeader  sx={{marginRight:"40px",padding:"10px"}}
  title={
    <Typography
      sx={{
        color: "#634699",
        fontWeight: 700,
        fontSize: "1rem",
      }}
    >
      Courses Available For Enrollment
    </Typography>
  }
  action={
    <IconButton
      onClick={toggleCourseExpansions}
      aria-expanded={isCourseExpandeds}
      aria-label="show more"
      sx={{
        transform: isCourseExpandeds ? "rotate(90deg)" : "rotate(0deg)",
        // transition: "transform 0.3s ease",
      }}
    >
      <ExpandIcon sx={{fontSize:16}}/>
    </IconButton>
  }
/><Collapse in={isCourseExpandeds} timeout="auto" unmountOnExit>
<CardContent>
         
{courseList && courseList.length
    ? getSearchResult().nonEnrolledCourses.length > 0
      ? getSearchResult()
          .nonEnrolledCourses.sort(
            (a, b) => a.categorysortorder - b.categorysortorder
          )
          .map((data, index) => {
                let quizCount = 0;
                let assignCount = 0;
                return data.format === "topics" &&
                  data.categoryid === 2 &&
                  data.visible === 1 ? (
                  <Fragment key={data.id}>
                    <Grid item xs={12} key={data.id}>
                      <Card sx={{ marginBottom: "10px",padding:"10px" }}>
                        {/* <CardContent> */}
                          <Grid container>
                            <Grid item xs={2} md={1}>
                              {/* <TestPackageListIcon className={CLASSES.icon} />  */}
                              {/* {data.imageurl?(<img src={data.imageurl} alt="ww"className={CLASSES.icon}width={26} height={26} />):<TestPackageListIcon className={CLASSES.icon} />} */}
                              {data.imageurl ? (
        <img
          src={data.imageurl}
          alt="courseurl"
          width={26}
          height={26}
          style={{cursor:"pointer",marginTop:"8px"}}
          // className={CLASSES.icon} 
          onClick={() => handleImageClick(data.imageurl)}
        />
      ) : (
        <TestPackageListIcon className={CLASSES.icon} />
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}  PaperProps={{
          style: {
            background: "#FFFFFF",
            boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            padding: "1%",
            // width: "1000px",
            // width: "579px",
          },
        }}
        // open={snackbar.open}
        sx={{
          backgroundColor: "white",
        }}>
        <DialogContent>
          <img src={clickedImageUrl} alt="Clicked Image" style={{ maxWidth: '100%', height: 'auto' }} />
        </DialogContent>
      </Dialog>
                            </Grid>
                            {/* <Grid item xs={4} md={4}> */}
                            <Grid item xs={7} md={7}>
                              <Typography
                                sx={{
                                  color: "#634699",
                                  fontWeight: 700,
                                  //anusha
                                  fontSize: "14px",
                                  lineHeight: "28.4px",
                                }}
                              >
                                {data.displayname}
                                <span
                                  style={{
                                    color: "#718096",
                                    fontWeight: 400,
                                    //anusha
                                    fontSize: "14px",
                                    lineHeight: "28.4px",
                                    marginLeft:"20px"
                                  }}
                                >
                                {data.customfields[0].name === "Grades" && data.customfields[0].value !== null && !isNaN(data.customfields[0].value)
    ? data.customfields[0].value + (
        parseInt(data.customfields[0].value) % 100 === 11 || parseInt(data.customfields[0].value) % 100 === 12 || parseInt(data.customfields[0].value) % 100 === 13
          ? "th"
          : parseInt(data.customfields[0].value) % 10 === 1
            ? "st"
            : parseInt(data.customfields[0].value) % 10 === 2
              ? "nd"
              : parseInt(data.customfields[0].value) % 10 === 3
                ? "rd"
                : "th"
      )
    : data.customfields[0].value !== null ? data.customfields[0].value : ""
  }
                                </span>
                                <br /> <Typography
                                                sx={{
                                                  color: "#000",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  lineHeight: "38.4px",
                                                }}
                                              >
                                                {data.verificationstatus}
                                              </Typography>
                                <span
                                  style={{
                                    color: "white",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "16.8px",
                                  }}
                                >
                                  {data.summary.replace(/<[^>]+>/g, "")}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={3} md={1.5}>
                              {data?.paypalenrollments.map(
                                (pricedata, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      color: "#634699",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      lineHeight: "38.4px",
                                    }}
                                  >
                                     {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: pricedata?.currency,
                                      maximumSignificantDigits: 3,
                                    }).format(pricedata?.actualcost)}
                                    {/* {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: pricedata?.currency,
                                      maximumSignificantDigits: 3,
                                    }).format(pricedata?.actualcost === "0" ? pricedata?.cost : pricedata?.actualcost)} */}
                                  </Typography>
                                )
                              )}
                            </Grid>
                            {/* <Grid item xs={2} md={1}></Grid> */}
                            <Grid item xs={2} md={1.5}>
                              {/* we have hide cart button for api to store the value */}
                              {/* <IconButton className={CLASSES.carticon} onClick={() => handleAddToCart(data)}>
                                <AddToCartIcon sx={{fontSize:20}}/>
                              </IconButton> */}
                              {cartOpen && lastAddedItem === data && (
        // <CartPopup cartItems={[data]}  />
        <Dialog
        open={cartOpen}
        onClose={handleCartClose}
        PaperProps={{
          style: {
            maxHeight: "none",
          },
        }}
        BackdropProps={{ invisible: true }}
      >
        <Grid container item xs={12}>
          <Card
            className={CLASSES.testContainer}
            sx={{ minWidth: 600 }}
          >
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={mailicon} alt="mailicon" width="100px" height="100px" />
            </Grid>
            <br />

            <Typography
            variant="h1"
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
             {data.displayname}<br/>
             successfully carted

            </Typography>
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
            </Grid>
          </Card>
        </Grid>
      </Dialog>
      )}
                            </Grid>
                            <Grid item xs={3} md={1}>
                            <EXPAND_MORE
                                  expand={expanded === index}
                                  onClick={() =>
                                    HANDLE_EXPAND_CLICK(index, data.id)
                                  }
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                >
                                  <ExpandIcon sx={{fontSize:16}}/>
                                </EXPAND_MORE>
                              {/* <Button
                                variant="outlined"
                                style={{
                                  color: "gray",
                                  border: "1px solid #634699",
                                  textTransform: "none",
                                }}
                                onClick={() =>
                                  HANDLE_EXPAND_CLICK(index, data.id)
                                }
                                aria-expanded={expanded}
                              >
                                View Syllabus
                              </Button> */}
                            </Grid>
                           
                          </Grid>
                        {/* </CardContent> */}
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      {coursecontents && coursecontents.length
                        ? coursecontents.map((content, indexcontent) => {
                            if (
                              content &&
                              content.modules &&
                              content.modules.length
                            ) {
                              let quizlist = content.modules.filter(
                                (e) => e.modname === "quiz"
                              );
                              if (quizlist.length) {
                                quizCount = quizCount + quizlist.length;
                              }
                              let assignlist = content.modules.filter(
                                (e) => e.modname === "assign"
                              );
                              if (assignlist.length) {
                                assignCount = assignCount + assignlist.length;
                              }
                            }
                            return coursecontents.length - 1 ===
                              indexcontent ? (
                              <Collapse in={expanded === index} key={index}>
                                <Card sx={{marginBottom:"16px"}}>
                                  {/* <CardContent> */}
                                    <Grid item xs={12}>
                                      <Grid container>
                                        {/* <Grid item md={1}></Grid> */}
                                        <Grid container>
      <Grid item xs={12} md={1.8} className={CLASSES.session}>
        {/* This Grid item will display the image */}
      </Grid>
      <Grid item xs={12} md={1} className={CLASSES.textContainer}>
        <span
          style={{
            color: "#634699",
            fontWeight: 700,
            fontSize: "60.667px",
          }}
        >
          {filteredContents.length}
        </span>
        <Typography
          sx={{
            color: "#634699",
            fontWeight: 700,
            fontSize: "12px",
            marginTop:"-10px",
          }}
        >
          Sessions
        </Typography>
      </Grid>
      <Grid item xs={12} md={1.8} className={CLASSES.test}>
        {/* This Grid item will display the image */}
      </Grid>
      <Grid item xs={12} md={4} className={CLASSES.textContainer}>
        <span
          style={{
            color: "#634699",
            fontWeight: 700,
            fontSize: "60.667px",
          }}
        >
         {quizCount}
        </span>
        <Typography
          sx={{
            color: "#634699",
            fontWeight: 700,
            fontSize: "12px",marginTop:"-10px",
          }}
        >
          Tests
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
                                            <Grid item>
                                              <Typography
                                                sx={{
                                                  color: "#634699",
                                                  fontWeight: 700,
                                                  fontSize: "1vw",marginTop: "40px",
                                                  lineHeight: "150%", width:"200px",textAlign:"center",
                                                }}
                                              >
                                                {data.verificationstatus}
                                              </Typography>
                                            </Grid>
                                            {data.enroluistat === false &&
                                            filteredContents.length !== 0 ? (
                                              <Grid item>
                                                {/* <Button
                                                  component={Link}
                                                  to={`/test-packages/orderconfirmation/${data.id}`}
                                                  variant="outlined"
                                                  fullWidth
                                                  sx={{
                                                    marginTop: 1,
                                                    color: "#634699",
                                                    borderColor: "#634699",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                      borderColor: "#634699",
                                                    },
                                                  }}
                                                >
                                                  Enroll
                                                </Button> */}
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                            {data.cancustomize === 1 &&
                                            filteredContents.length !== 0 ? (
                                              <Grid item>
                                                <Button
                                                  component={Link}
                                                  to={`/test-packages/${data.id}/${userid}`}
                                                  onMouseOver={()=>sethover(true)} 
     onMouseOut={()=>sethover(false)} 
     onClick={() => {
      localStorage.setItem("userid", userid);
      history.push(`/test-packages/${data.id}/${userid}`);
    }}
  
                                                  variant="outlined"
                                                  // fullWidth
                                                  sx={{
                                                    marginLeft:"50px",
                                                    fontSize:"12px",
                                                    color: "#fff",
                                                    borderColor: "#634699",
                                                    textTransform: "none",
                                                    backgroundColor: "#634699",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#7C55C5",
                                                      borderColor: "#634699",
                                                    },
                                                  }}
                                                >
                                                  {hover?"Customise":"Customise"}
                                                </Button>
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                          </Grid>
    </Grid>
    
    {/* </Grid> */}
                                      </Grid>
                                      <Grid container>
                                      <Grid item xs={8} sx={{ marginTop: "22px" }}>
                                            {showSyllabus && (
                                              <div>
                                                {filteredContents.map((item) => (
                                                  <Grid>
                                                    <List key={item.id}>
                                                      <Typography
                                                        sx={{
                                                          color: "#634699",
                                                          fontWeight: 400,
                                                          fontSize: "13px",
                                                          lineHeight: "140%",
                                                          marginLeft: "50px",
                                                        }}
                                                      >
                                                        {item.name}
                                                      </Typography>
                                                    </List>
                                                  </Grid>
                                                ))}
                                                
                                              </div>
                                            )}
                                          </Grid>
                                      <Grid item xs={4}>
                                        {/* <Grid item xs={2.5}> */}
                                         
                                        </Grid>
                                      </Grid>
                                      <>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              textTransform: "none",
                                              background: "rgba(224, 218, 235, 0.74)",
                                              color: "#634699",
                                              boxShadow: "none",
                                              fontWeight: "bold",
                                              padding: "8px",
                                              marginTop: "8px",
                                              fontSize:"12px",
                                              borderRadius: "4px",
                                              "&:hover": { background: "rgba(224, 218, 235, 0.74)", boxShadow: "none" },
                                            }}
                                            fullWidth
                                            onClick={() => setShowSyllabus(!showSyllabus)}
                                          >
                                            {showSyllabus ? "Show Less" : "Show Test Syllabus"}
                                          </Button>
                                        </>
                                    </Grid>
                                  {/* </CardContent> */}
                                </Card>
                              </Collapse>
                            ) : null;
                          })
                        : null}
                    </Grid>
                  </Fragment>
                ) : data.format === "no-topics" ? (
                  <Grid item xs={12} key={data.format} textAlign="center">
                    <Typography
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                      }}
                    >
                      No Result Found..!
                    </Typography>
                  </Grid>
                ) : null;
              })
              : // Message when there are no enrolled courses
              <Grid item xs={12}  textAlign="center">

              <Typography
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "140%",
              }}
            >No non-enrolled courses found!</Typography></Grid>
          : null}</CardContent>
</Collapse>
        </Grid>
      </Grid>
    </>
  );
  
};
export default TEST_PACKAGE_LIST;
