import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import HEADER from "./layouts/main/header";
import SIDEBAR from "./layouts/main/sidebar";
import AuthenticationError from "./views/main/NotFound";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.updateError = this.updateError.bind(this);
  }

  updateError(error) {
    this.setState({
      hasError: error,
    });
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("Error:", error);
    console.log("Error Info:", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        // Header
        // Side Bar
        // 403 component

        <div>
          <Grid
            container
            spacing={2}
            sx={{ height: "100vh", overflow: "hidden" }}
          >
            {/* <Grid sx={{ flex: 0 }}>
              <SIDEBAR />
            </Grid>
            <Grid item xs sx={{ margin: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HEADER />
                </Grid> */}
            <Grid item xs={12}>
              <Box px={2}>
                <Grid container spacing={2} columnSpacing={10}>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="column">
                      <Grid item xs={12} md={12}>
                        <AuthenticationError
                          show={false}
                          error={this.state.hasError}
                          updateError={this.updateError}
                        />
                      </Grid>
                      <Typography>
                        {this.state.error}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
