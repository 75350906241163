import { Grid, Typography } from "@mui/material";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
const OfflineHeader = () => {
  const { validateTestCode } = useSelector((state) => state.guestAccessState);
 
  return (
    <React.Fragment>
    <Grid container sx={{ background: "#634699" , margin: "20px 20px 20px 20px",
    borderRadius: "10px",
    width: "94%",padding:"10px"}}>
     
        <Grid container sx={{ display: "flex",padding:"10px 0px 10px 0px"}}>
          <Grid container item xs={12} >
            <Grid item xs={0.5} />
            <Grid item xs={4.5}  sx={{ display: "flex"}}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "140%",
                  color: "#FFFFFF",
                //   textAlign: "left",
                }}
              >
                Name-&nbsp;
              </Typography>
            
           
              {" "}
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#C4C4C4",
                //   textAlign: "left",
                }}
              >
                {validateTestCode?.user}
              </Typography>
            </Grid>
            <Grid item xs={7}  sx={{ display: "flex"}}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "140%",
                  color: "#FFFFFF",
                //   textAlign: "left",
                }}
              >
                Email Address&nbsp;
              </Typography>
            
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#C4C4C4",
                //   textAlign: "left",
                }}
              >
                {validateTestCode?.email}
              </Typography>
            </Grid>
            {/* <Grid item xs={0.5} /> */}
          </Grid>
        </Grid>
        <Grid container style={{ display: "flex"}}>
          <Grid container item xs={12} >
          <Grid item xs={0.5} />
            <Grid item xs={4.5}  sx={{ display: "flex"}}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "140%",
                  color: "#FFFFFF",
                //   textAlign: "left",
                }}
              >
               Test Code-&nbsp;
              </Typography>
           
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#C4C4C4",
                //   textAlign: "left",
                }}
              >
                {validateTestCode?.code}
              </Typography>
            </Grid>
            <Grid item xs={7} sx={{ display: "flex"}}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "140%",
                  color: "#FFFFFF",
                //   textAlign: "left",
                }}
              >
               Parent’s Contact Number&nbsp;
              </Typography>
           
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#C4C4C4",
                //   textAlign: "left",
                }}
              >
                {validateTestCode?.parentphone}
              </Typography>
            </Grid>
            {/* <Grid item xs={0.5} /> */}
          </Grid>
        </Grid>
     
    </Grid>
    
    </React.Fragment>
  );
};

export default OfflineHeader;
