import React from "react";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import FileUploadPreviewNew from "./FileUploadPreviewNew";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

const FileInputNew = ({ onUpload, fileSizeLimit, attemptidd, count, secondLevelIndex, thirdLevelIndex, level }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  const HANDLE_SUBMIT_BUTTON = () => {
    setModalOpen(true);
  };
  const HANDLE_CLOSE = () => {
    setModalOpen(false);
  };
  return (
    <>
      {questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.uploadedFiles.length > 0 ||
      questionStatusResponse[count]?.uploadedFiles.length > 0 ||
      questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.uploadedFiles.length > 0 ? (
        <Button
          onClick={() => HANDLE_SUBMIT_BUTTON()}
          sx={{
            minWidth: "32px",
            borderRadius: "5px",
            cursor: "pointer",
            height: "35px",
            marginLeft: "6px",
            backgroundColor: "#634699",
            border: "#634699",
            color: "white",
            "&:hover": {
              backgroundColor: "#634699",
            },
          }}
        >
          <AddIcon sx={{ fontSize: 15 }} />
        </Button>
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={6} md={9} />
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => HANDLE_SUBMIT_BUTTON()}
              component="span"
              sx={{
                textTransform: "none",
                backgroundColor: "#634699",
                textAlign: "center",
                "&:hover": { backgroundColor: "#634699" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontStyle: "regular",
                  fontSize: "14px",
                  lineHeight: "140%",
                }}
              >
                Upload File
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Dialog
        PaperProps={{
          style: {
            maxHeight: "none",
          },
        }}
        open={modalOpen}
        sx={{
          backgroundColor: "rgba(99, 70, 153,  60%)",
        }}
      >
        <FileUploadPreviewNew
          onClose={HANDLE_CLOSE}
          onUpload={onUpload}
          fileSizeLimit={fileSizeLimit}
          attemptidd={attemptidd}
          count={count}
          secondLevelIndex={secondLevelIndex}
          thirdLevelIndex={thirdLevelIndex}
          level={level}
        />
      </Dialog>
    </>
  );
};

export default FileInputNew;
