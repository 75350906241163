/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class assets/icon/index.js
 * This JS file is intended to import the major icons used in the project from design file and can reuse anywhere in the project
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy manu.nair@centelon.com
 * @modifiedDate 17 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as TestPackageComponentIcon } from "./testpackageicon.svg";
import { ReactComponent as AddToCart } from "./add-to-cart.svg";
import { ReactComponent as Expand } from "./expand-icon.svg";
import { ReactComponent as More } from "./more-icon.svg";
import { ReactComponent as Bullet } from "./bullet.svg";
import { ReactComponent as TakeTest } from "./take-test.svg";
import { ReactComponent as Grade } from "./grade.svg";
import { ReactComponent as Grade2 } from "./grade2.svg";
import { ReactComponent as Empty } from "./empty.svg";
import { ReactComponent as PreviewIcon } from "./preview.svg";
import { ReactComponent as ImageIcon } from "./individual-image.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as MyTest } from "./mytest.svg";
import { ReactComponent as Dashboardicon } from "./dashboard.svg";
import { ReactComponent as TestPackages } from "./testpackage.svg";
import { ReactComponent as MySchedules } from "./myschedule.svg";
import { ReactComponent as AuthError } from "./autherror.svg";
import { ReactComponent as TimerUp } from "./TimerUp.svg";
import { ReactComponent as Mcq } from "./mcq.svg";
import { ReactComponent as Delete } from "./DeleteIcon.svg";
import { ReactComponent as Listdot } from "./ListdotIcon.svg";
import { ReactComponent as PngImage } from "./PngImageIcon.svg";
import { ReactComponent as AddImage } from "./AddImageIcon.svg";
import { ReactComponent as JpgImage } from "./JpgImageIcon.svg";
import { ReactComponent as PdfImage } from "./PdfImageIcon.svg";
import { ReactComponent as DocImage } from "./DocImageIcon.svg";
import { ReactComponent as HeicImage } from "./HeicImageIcon.svg";
import { ReactComponent as PptxImage } from "./PptxImageIcon.svg";
import { ReactComponent as  Scheduleicon} from "./Scheduleiconbtn.svg";
import { ReactComponent as  Feedbackicon} from "./Feedbackiconbtn.svg";
import { ReactComponent as Starfilled } from "./Starfilledicon.svg";
import { ReactComponent as ExpandFilled } from "./ExpandFilledIcon.svg";
import { ReactComponent as carticon } from "./carticon.svg";
import { ReactComponent as GradeAicon } from "./GradeA.svg";
import { ReactComponent as GradeAPlusicon } from "./GradeA+.svg";
import { ReactComponent as GradeBicon } from "./GradeB.svg";
import { ReactComponent as GradeBPlusicon } from "./GradeB+.svg";
import { ReactComponent as GradeCicon } from "./GradeC.svg";
import { ReactComponent as GradeCPlusicon } from "./GradeC+.svg";
import { ReactComponent as GradeDicon } from "./GradeD.svg";
import { ReactComponent as GradeDPlusicon } from "./GradeD+.svg";
import { ReactComponent as GradeEicon } from "./GradeE.svg";
import { ReactComponent as GradeUGicon } from "./GradeUG.svg";
import { ReactComponent as GradeFicon } from "./GradeF.svg";
import { ReactComponent as NotGradeFicon } from "./Not graded.svg";

import { ReactComponent as  schedules} from "./Scheduleicon.svg";
import { ReactComponent as  Testresultsicon} from "./Testresults.svg";
import { ReactComponent as  results} from "./results.svg";
import { ReactComponent as  PptImage} from "./ppticon.svg";
import { ReactComponent as  DocxImage} from "./Docxicon.svg";
import { ReactComponent as  Reminder} from "./Remindericon.svg";
import { ReactComponent as  Congratulation} from "./congratulationicon.svg";
import { ReactComponent as  Attention} from "./Attentionicon.svg";
import { ReactComponent as  Notifyprev} from "./Notifyprevicon.svg";
import { ReactComponent as  Notifynext} from "./Notifynexticon.svg";
import { ReactComponent as  Headersetting} from "./Headersettings.svg";
import { ReactComponent as  Notificationheader} from "./Notificationheader.svg";
import { ReactComponent as  cartheader} from "./Cart.svg";
import { ReactComponent as  emptycarticon} from "./Emptycart.svg";
import { ReactComponent as  checkfeedbackwhiteicon} from "./checkfeedbackwhite.svg";
import { ReactComponent as  canceledit} from "./cancelediticon.svg";
import { ReactComponent as  emptycarticons} from "./Emptycarticon.svg";
import { ReactComponent as  HelpMail} from "./HelpMailIcon.svg";
import { ReactComponent as  MCQtest} from "./MCQtestscreen.svg";
import { ReactComponent as  SAtest} from "./SAtestscreen.svg";
import { ReactComponent as  TOFtest} from "./TOFtestscreen.svg";
import { ReactComponent as  MATCHtest} from "./MATCHtestscreen.svg";
import { ReactComponent as  ESSAYtest} from "./ESSAYtestscreen.svg";
import { ReactComponent as  SUBQUESTIONStest} from "./SUBQUESTIONStestscreen.svg";



export function SUBQUESTIONStestscreen(props) {
  return (
    <SvgIcon
      {...props}
      component={SUBQUESTIONStest}
      fill={props.fill}
      viewBox="0 0 462 326"
    />
  );
}
export function ESSAYtestscreen(props) {
  return (
    <SvgIcon
      {...props}
      component={ESSAYtest}
      fill={props.fill}
      viewBox="0 0 422 298"
    />
  );
}
export function MATCHtestscreen(props) {
  return (
    <SvgIcon
      {...props}
      component={MATCHtest}
      fill={props.fill}
      viewBox="0 0 436 308"
    />
  );
}

export function TOFtestscreen(props) {
  return (
    <SvgIcon
      {...props}
      component={TOFtest}
      fill={props.fill}
      viewBox="0 0 440 311"
    />
  );
}
export function SAtestscreen(props) {
  return (
    <SvgIcon
      {...props}
      component={SAtest}
      fill={props.fill}
      viewBox="0 0 433 306"
    />
  );
}

export function MCQtestscreen(props) {
  return (
    <SvgIcon
      {...props}
      component={MCQtest}
      fill={props.fill}
      viewBox="0 0 425 300"
    />
  );
}
export function HelpMailIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={HelpMail}
      fill={props.fill}
      viewBox="0 0 31 21"
    />
  );
}

export function Emptycarticon(props) {
  return (
    <SvgIcon
      {...props}
      component={emptycarticons}
      fill={props.fill}
      viewBox="0 0 72 83"
    />
  );
}

export function Cancelediticon(props) {
  return (
    <SvgIcon
      {...props}
      component={canceledit}
      fill={props.fill}
      viewBox="0 0 10 10"
    />
  );
}

export function Checkfeedbackwhite(props) {
  return (
    <SvgIcon
      {...props}
      component={checkfeedbackwhiteicon}
      fill={props.fill}
      viewBox="0 0 21 22"
    />
  );
}
export function Emptycart(props) {
  return (
    <SvgIcon
      {...props}
      component={emptycarticon}
      fill={props.fill}
      viewBox="0 0 221 196"
    />
  );
}

export function Cartheaders(props) {
  return (
    <SvgIcon
      {...props}
      component={cartheader}
      fill={props.fill}
      viewBox="0 0 16 15"
    />
  );
}
export function Notificationheaders(props) {
  return (
    <SvgIcon
      {...props}
      component={Notificationheader}
      fill={props.fill}
      viewBox="0 0 13 17"
    />
  );
}
export function Headersettings(props) {
  return (
    <SvgIcon
      {...props}
      component={Headersetting}
      fill={props.fill}
      viewBox="0 0 15 15"
    />
  );
}
export function NotifynextIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Notifynext}
      fill={props.fill}
      viewBox="0 0 18 21"
    />
  );
}

export function NotifyprevIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Notifyprev}
      fill={props.fill}
      viewBox="0 0 18 21"
    />
  );
}

export function AttentionIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Attention}
      fill={props.fill}
      viewBox="0 0 28 24"
    />
  );
}
export function CongratulationIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Congratulation}
      fill={props.fill}
      viewBox="0 0 27 28"
    />
  );
}

export function ReminderIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Reminder}
      fill={props.fill}
      viewBox="0 0 23 29"
    />
  );
}

export function DocxImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={DocxImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}

export function PptImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={PptImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function Resultsicon(props) {
  return (
    <SvgIcon
      {...props}
      component={results}
      fill={props.fill}
      viewBox="0 0 16 20"
    />
  );
}

export function Testresults(props) {
  return (
    <SvgIcon
      {...props}
      component={Testresultsicon}
      fill={props.fill}
      viewBox="0 0 15 20"
    />
  );
}


export function Scheduleimage(props) {
  return (
    <SvgIcon
      {...props}
      component={schedules}
      fill={props.fill}
      viewBox="0 0 27 27"
    />
  );
}

export function McqIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Mcq}
      fill={props.fill}
      viewBox="0 0 8 8"
    />
  );
}
export function GradeA(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeAicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeAPlus(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeAPlusicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeB(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeBicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeBPlus(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeBPlusicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeC(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeCicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeCPlus(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeCPlusicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeD(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeDicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeDPlus(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeDPlusicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function GradeE(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeEicon}
      fill={props.fill}
      viewBox="0 0 251 185"
    />
  );
}
export function GradeUG(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeUGicon}
      fill={props.fill}
      viewBox="0 0 251 185"
    />
  );
}
export function GradeF(props) {
  return (
    <SvgIcon
      {...props}
      component={GradeFicon}
      fill={props.fill}
      viewBox="0 0 251 177"
    />
  );
}
export function NotGrade(props) {
  return (
    <SvgIcon
      {...props}
      component={NotGradeFicon}
      fill={props.fill}
      viewBox="0 0 226 209"
    />
  );
}
export function DeleteIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Delete}
      fill={props.fill}
      viewBox="0 0 20 26"
    />
  );
}
export function ListdotIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Listdot}
      fill={props.fill}
      viewBox="0 0 26 26"
    />
  );
}
export function PngImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={PngImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function AddImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={AddImage}
      fill={props.fill}
      viewBox="0 0 74 74"
    />
  );
}
export function JpgImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={JpgImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function PdfImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={PdfImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function DocImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={DocImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function HeicImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={HeicImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function PptxImageIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={PptxImage}
      fill={props.fill}
      viewBox="0 0 25 33"
    />
  );
}
export function Scheduleiconbtn(props) {
  return (
    <SvgIcon
      {...props}
      component={Scheduleicon}
      fill={props.fill}
      viewBox="0 0 14 14"
    />
  );
}
export function Feedbackiconbtn(props) {
  return (
    <SvgIcon
      {...props}
      component={Feedbackicon}
      fill={props.fill}
      viewBox="0 0 21 22"
    />
  );
}
export function Starfilledicon(props) {
  return (
    <SvgIcon
      {...props}
      component={Starfilled}
      fill={props.fill}
      viewBox="0 0 19 19"
    />
  );
}
export function ExpandFilledIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={ExpandFilled}
      fill={props.fill}
      viewBox="0 0 15 12"
    />
  );
}
export function Cart(props) {
  return (
    <SvgIcon
      {...props}
      component={carticon}
      fill={props.fill}
      viewBox="0 0 13 17"
    />
  );
}

export function TestPackageListIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={TestPackageComponentIcon}
      fill={props.fill}
      viewBox="0 0 26 26"
    />
  );
}

export function AddToCartIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={AddToCart}
      fill={props.fill}
      viewBox="0 0 20 18"
    />
  );
}
export function ExpandIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Expand}
      fill={props.fill}
      viewBox="0 0 16 18"
    />
  );
}
export function MoreIcon(props) {
  return (
    <SvgIcon {...props} component={More} fill={props.fill} viewBox="0 0 20 4" />
  );
}
export function BulletIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Bullet}
      fill={props.fill}
      viewBox="0 0 15 15"
    />
  );
}
export function TakeTestIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={TakeTest}
      fill={props.fill}
      viewBox="0 0 22 22"
    />
  );
}
export function GradeIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Grade}
      fill={props.fill}
      viewBox="0 0 29 36"
    />
  );
}
export function GradeIcon2(props) {
  return (
    <SvgIcon
      {...props}
      component={Grade2}
      fill={props.fill}
      viewBox="0 0 29 36"
    />
  );
}
export function EmptyIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={Empty}
      fill={props.fill}
      viewBox="0 0 72 83"
    />
  );
}

export function PreviewComponent(props) {
  return (
    <SvgIcon
      {...props}
      component={PreviewIcon}
      fill={props.fill}
      viewBox="0 0 135 131"
    />
  );
}

export function ImageComponent(props) {
  return (
    <SvgIcon
      {...props}
      component={ImageIcon}
      fill={props.fill}
      viewBox="0 0 178 121"
    />
  );
}

export function PlusComponent(props) {
  return (
    <SvgIcon
      {...props}
      component={PlusIcon}
      fill={props.fill}
      viewBox="0 0 22 22"
    />
  );
}
export function MyTestPackageIcons(props) {
  return (
    <SvgIcon
      {...props}
      component={MyTest}
      fill={props.fill}
      viewBox="0 0 15 16"
    />
  );
}
export function DashboardIcons(props) {
  return (
    <SvgIcon
      {...props}
      component={Dashboardicon}
      fill={props.fill}
      viewBox="0 0 16 17"
    />
  );
}
export function TestPackageIcons(props) {
  return (
    <SvgIcon
      {...props}
      component={TestPackages}
      fill={props.fill}
      viewBox="0 0 16 15"
    />
  );
}
export function MyScheduleIcons(props) {
  return (
    <SvgIcon
      {...props}
      component={MySchedules}
      fill={props.fill}
      viewBox="0 0 13 13"
    />
  );
}

export function AuthErrorIcon(props) {
  return (
    <SvgIcon
      {...props}
      component={AuthError}
      fill={props.fill}
      viewBox="0 0 428 320"
    />
  );
}

export function TimerUpImage(props) {
  return (
    <SvgIcon
      {...props}
      component={TimerUp}
      fill={props.fill}
      viewBox="0 0 260 390"
    />
  );
}
