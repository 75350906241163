import React from "react";
import { Grid, Card, Typography, Divider, Button, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseList,
  fetchCourseById,
  resetCustomizedList,
  fetchCourseContents,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

const ORDERBILL = () => {
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountedPrices, setDiscountedPrices] = useState([]);
  let history = useHistory();
  const params = useParams();
  const courseid = params.id;
  const dispatch = useDispatch();
  const courseList = JSON.parse(localStorage.getItem("courseList"));
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    dispatch(fetchUserId());
  }, [dispatch]);

  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
  }, [userid, dispatch]);

  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );

  const customizedList = JSON.parse(localStorage.getItem("customizeList"));
  const courseids = JSON.parse(localStorage.getItem("courseid"));

  const getListToRender = () => {
    return customizedList ? customizedList : coursecontents;
  };

  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );

  const getListToRenders = () => {
    return customizedList ? customizedList : filteredContents;
  };

  useEffect(() => {
    let totalDiscount = 0;
    customizedList?.forEach((item) => {
      if (item.discount) {
        totalDiscount += parseInt(item.discount, 10);
      }
    });
    setDiscount(totalDiscount);
  }, [customizedList]);
  const [Tprice, setTPrice] = useState(0);
  useEffect(() => {
    let TotalPrice = 0;
    getListToRender() &&
      getListToRender().map((item) => {
        if (item.sectioncost) {
          TotalPrice += parseInt(item.sectioncost);
        }
      });
    setTPrice(TotalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListToRender, filteredContents, setTPrice]);
  useEffect(() => {
    let TotalPrice = 0;
    const discounts = [];
    getListToRender()?.forEach((item) => {
        const actualcost = parseInt(item.actualcost);
        const itemDiscount = item.discount ? parseInt(item.discount, 10) : 0;
        const discountedPrice = actualcost - (actualcost * itemDiscount) / 100;
        const displayCost = actualcost === 0 ? parseInt(item.sectioncost) : actualcost;
      TotalPrice += displayCost;
      discounts.push({
        ...item,
        discountedPrice,
        displayCost, // Add displayCost to the discountedPrices array
      });
    });
    setPrice(TotalPrice);
    setDiscountedPrices(discounts);
  }, [getListToRender, filteredContents]);


  localStorage.setItem("cost", Tprice);

  useEffect(() => {
    dispatch(fetchCourseById(courseid));
    dispatch(fetchCourseContents(courseid, userid));
    return () => {
      dispatch(fetchCourseById(""));
    };
  }, [courseid, userid, dispatch]);

  const customizeHandler = () => {
    dispatch(resetCustomizedList());
    localStorage.removeItem("customizeList");
    history.push(`/test-packages/${courseids}/${userid}`);
  };

  return (
    <Grid container>
      {courseList && courseList?.length
        ? courseList.map((list, index) => {
            return (
              list.id === parseInt(courseids) && (
                <Fragment key={index}>
                  <Grid item xs={12}>
                    <Card>
                      <Grid container>
                        <Grid item xs={6} p={1} sx={{ paddingTop: "25px" }}>
                          <Typography
                            variant="p"
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "16px",
                            }}
                          >
                            {list.displayname}
                            <span style={{ color: "#634699" }}></span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          display="flex"
                          justifyContent="flex-end"
                          p={2}
                        >
                          <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "17px",
                              lineHeight: "56px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(price)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} p={1}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "17px",
                              lineHeight: "33.6px",
                            }}
                          >
                            Care
                            <br />
                            <span
                              style={{
                                color: "#634699",
                                fontWeight: 700,
                                fontSize: "17px",
                                lineHeight: "33.6px",
                              }}
                            >
                              Discount
                            </span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={8}
                          display="flex"
                          justifyContent="flex-end"
                          p={1}
                        >
                          {/* <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "17px",
                              lineHeight: "56px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(discount)}
                          </Typography> */}
                            {discountedPrices.map((item, index) => (
  <Card key={index} sx={{ marginTop: 2 }}>
    <Grid container>
      <Grid item xs={3.5} px={1} py={0}>
        {item?.discount !== "0" ? (
          <Typography
            sx={{
              color: "#878787",
              fontWeight: 400,
              fontSize: "14px",
              textDecoration: "line-through",
            }}
          >
            {new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: list.paypalenrollments[0]?.currency,
              maximumSignificantDigits: 3,
            }).format(item.actualcost)}
          </Typography>
        ) : (
          <Box sx={{ height: 21 }} />
        )}
      </Grid>
      <Grid item xs={4.5} px={1} py={0}>
        {item?.discount !== "0" ? (
          <Typography
            sx={{
              color: "#388e3c",
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            {`${item.discount}% off`}
          </Typography>
        ) : (
          <Box sx={{ height: 21,width:90 }} />
        )}
      </Grid>
      <Grid item xs={3.5} px={1} py={0}>
        <Typography
          sx={{
            color: "#634699",
            fontWeight: 700,
            fontSize: "14px",
          }}
        >
          {item?.actualcost === "0" ? new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(item.sectioncost): new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(item.discountedPrice)}
        </Typography>
      </Grid>
    </Grid>
  </Card>
))}

                        </Grid>
                      </Grid>
                      <Divider variant="middle" sx={{ bgcolor: "#634699" }} />
                      <Grid container>
                        <Grid item xs={6} p={2}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "17px",
                              lineHeight: "33.6px",
                            }}
                          >
                            Total
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          display="flex"
                          justifyContent="flex-end"
                          p={2}
                        >
                          <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "17px",
                              lineHeight: "56px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(Tprice)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  
                    <Button
                      onClick={() =>
                        history.push(
                          `/test-packages/checkout/${courseid}/${
                            Tprice
                          }`
                        )
                      }
                      variant="contained"
                      fullWidth
                      sx={{
                        marginTop: 1,
                        backgroundColor: "#634699",
                        textTransform: "none",
                        "&:hover": { backgroundColor: "#7C55C5" },
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "140%",
                          color: "#F1F1F1",
                        }}
                      >
                        Checkout
                      </Typography>
                    </Button>
                    <Button
                      onClick={customizeHandler}
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginTop: 1,
                        borderColor: "#634699",
                        color: "#634699",
                        textTransform: "none",
                        border: "1px solid #634699",
                        borderRadius: "3px",
                        "&:hover": { borderColor: "#7C55C5" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "140%",
                          color: "#634699",
                        }}
                      >
                        Customize
                      </Typography>
                    </Button>
                  </Grid>
                </Fragment>
              )
            );
          })
        : null}
    </Grid>
  );
};

export default ORDERBILL;
