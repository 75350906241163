/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class progress.js
 * This JS file is for showing the progress component
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
    Card,
    Typography,
    Grid,
    FormControl,
    Select,
    MenuItem,
    IconButton,
  } from "@mui/material";
  import { makeStyles } from "@mui/styles";
  import CircularProgress from "@mui/material/CircularProgress";
  import Box from "@mui/material/Box";
  import { useDispatch, useSelector } from "react-redux";
  import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
  import { useEffect, useState } from "react";
  import { fetchEnrolledTests } from "../../../webservice/students/actions/MyTestPackageAction";
  import PIC from "../../../views/main/ui_component/pic";
  import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
  import ArrowRightIcon from "@mui/icons-material/ArrowRight";
  const USE_STYLES = makeStyles({
    card: {
      width: "102%",
      height: "377.5px",
      margin: "auto",
      textAlign: "center",
      borderRadius: "5px",
      transition: "0.3s",
      // boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      alignItems: "center",
      // "&:hover": {
      //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      // },
    },
  
    circleProgress: {
      color: "#634699",
      marginTop: "40px",
      position: "absolute",
      left: 0,
    },
    cardHeader: {
      backgroundColor: "#d8e2f3",
    },
    field: {
      paddingLeft: "20px",
      "& .MuiSelect-outlined": {
        borderStyle: "none",
      },
    },
    select: {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#634699",
      },
      "&:after": {
        borderColor: "#634699",
      },
    },
  });
  
  const PROGRESS = () => {
    const CLASSES = USE_STYLES();
    const dispatch = useDispatch();
    const { userid } = useSelector((state) => state.authState);
    const { testList } = useSelector((state) => state.mytestpackagestate);
    const displayNames = testList?.map(course => course.displayname) ?? [];
  const [sliderCount, setSliderCount] = useState(0);
  const allDisplayNames = displayNames.map((name, index) => ` ${name}`);
    useEffect(() => {
      dispatch(fetchUserId());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useEffect(() => {
      if (userid) dispatch(fetchEnrolledTests(userid));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userid]);
  
    const [course, setCourse] = useState("");
  
    useEffect(() => {
      if (testList?.length) setCourse(testList[0]?.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testList]);
  
    const handleChange = (event) => {
      setCourse(event.target.value);
    };
  
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={CLASSES.card}>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  paddingLeft: "21px",
                  paddingTop: "28px",
                  textAlign:"left"
                }}
              >
                Progress
              </Typography>
            </Grid>
            <Grid item xs={12}>
            
  
            <Typography
                      style={{
                        color: "#A0AEC0",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "150%",
                        paddingLeft: "21px",
                        paddingTop: "10px",
                        textAlign:"left"
  
                      }}
                    >
                      {allDisplayNames[sliderCount]}
                    </Typography>
  
                    
             
            </Grid>
           
            {testList && testList.length ? (
    testList.map(
      (test, index) =>
        test.displayname === testList.map(test => test.displayname)[sliderCount] && (<div key={index}>
          <IconButton
          disabled={sliderCount > 0 ? false : true}
          variant="contained"
          onClick={() => {
            setSliderCount(sliderCount - 1);
          }}
          sx={{
            bottom: "95px",
            textTransform: "none",
            color: "#634699",
            backgroundColor: "#fff",
            margin: "1.5px",
            "&:hover": { backgroundColor: "#fff" },
          }}
          size="small"
        >
          <ArrowLeftIcon sx={{ fontSize: 30 }} />
        </IconButton>
                    <Box
                      sx={{ position: "relative", display: "inline-flex" }}
                      key={index}
                    >
                      
                      <CircularProgress
                        sx={{
                          position: "relative",
                          marginTop: "40px",
                          color: (theme) =>
                            theme.palette.grey[
                              theme.palette.mode === "light" ? 400 : 800
                            ],
                        }}
                        variant="determinate"
                        value={100}
                        size={200}
                        thickness={5}
                      />
                      <CircularProgress
                        className={CLASSES.circleProgress}
                        variant="determinate"
                        value={test.progress}
                        size={200}
                        thickness={5}
                      />
                      <Box
                        sx={{
                          top: 40,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#2D3748",
                            fontWeight: 700,
                            fontSize: "28.28px",
                            lineHeight: "49.39px",
                          }}
                        >
                          {/* {allDisplayNames.push(`${Math.round(test.progress)}%`)} */}
                          {`${Math.round(test.progress)}%`}
                        </Typography>
                      </Box>
                     
                    </Box>
                    <IconButton
            variant="contained"
            onClick={() => {
              setSliderCount(sliderCount + 1);
            }}
            disabled={sliderCount < allDisplayNames.length - 1 ? false : true}
            sx={{
              bottom: "95px",left:"0px",
              textTransform: "none",
              color: "#634699",
              backgroundColor: "#fff",
              margin: "1.5px",
              "@media (max-width: 700px)": {
                left: "0px",bottom:"95px"
              },
              "&:hover": { backgroundColor: "#fff" },
            }}
            size="small"
          >
            <ArrowRightIcon sx={{ fontSize: 30 }} />
          </IconButton></div>
                  )
              )
            ) : (
              <div>
                <Grid>
                  <Box sx={{ marginTop: "10px" }}>
                    <PIC width="220px" />
                  </Box>
                </Grid>
                <Grid>
                <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Not yet progress{" "}
                  </Typography>
  
                  <Typography
                    sx={{
                      color: "#718096",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      textAlign: "center",
                    }}
                  >
                    Get started! <br />
                    Find the right course for you to enroll and <br />
                    have a test
                  </Typography>
                  <br />
                </Grid>
              </div>
            )}
           
          </Card>
        </Grid>
      </Grid>
    );
  };
  export default PROGRESS;
  