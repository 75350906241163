/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestCode.js
 * This JS file is for designing the layout for test code page.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { useState, useEffect } from "react";
import { Typography, Button, Grid, Snackbar, Alert } from "@mui/material";
import LoginPurpleBg from "../../../assets/images/login-purple-bg.png";
import LoginWhiteBg from "../../../assets/images/login-white-bg.png";
import { makeStyles } from "@mui/styles";
import error from "../../../assets/images/Test Code .svg";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validateTestCodeValue } from "../../../webservice/students/actions/GuestAccessAction";
import { fetchQuizStatus } from "../../../webservice/students/actions/TestAttemptAction";

const USE_STYLES = makeStyles({
  btn: {
    width: "40%",
    "@media (max-width:900px)": {
      width: "80%",
    },
  },
  leftContainer: {
    backgroundColor: "#634699",
    height: "100vh",
    borderRadius: "15px",
    // backgroundImage: `url(${LoginPurpleBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10% 50%",
    backgroundSize: "90% 100%",

    "@media (max-width:900px)": {
      height: "80vh",
    },
  },
  rightContainer: {
    height: "100vh",
    weight: "100vh",
    // backgroundImage: `url(${LoginWhiteBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "99% 90%",
    backgroundSize: "25% 25%",
    "@media (max-width:900px)": {
      height: "65vh",
    },
  },
  innerLeftcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "25%",
    fontFamily: "Helvetica",
    color: "white",
    "@media (max-width:900px)": {
      height: "70vh",
      paddingLeft: "20%",
    },
  },
  innerRightcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Helvetica",
    alignItems: "center",

    "& .MuiInputBase-root": {
      fontWeight: "600",
    },

    "@media (max-width:900px)": {
      height: "50vh",
      paddingLeft: "0%",
    },
  },
});

const TESTCODE = () => {
  const CLASSES = USE_STYLES();
  let HIS = useHistory();
  const dispatch = useDispatch();
  const { validateUser } = useSelector((state) => state.guestAccessState);

  const { quizStatus } = useSelector((state) => state.testAttemptState);

  const { validateTestCode } = useSelector((state) => state.guestAccessState);

  const [testCode, setTestCode] = useState(new Array(4).fill(""));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setTestCode([...testCode.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
  };
  useEffect(() => {
    if (isSubmitted) {
      dispatch(validateTestCodeValue(testCode.join(""), validateUser?.userid));
    }
    return setIsSubmitted(false);
  }, [isSubmitted]);

  useEffect(() => {
    if (validateTestCode?.status === true) {
      dispatch(fetchQuizStatus(validateUser.userid, validateTestCode.quizid));
    } else if (validateTestCode?.status === false) {
      setOpen(true);
    }
  }, [validateTestCode]);

  const onCloseHandler = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (quizStatus?.status === "notstarted") {
      HIS.push("/login/testverificationscreen");
    } else if (quizStatus?.status === "inprogress") {
      HIS.push("/login/testverificationscreen");
    } else if (quizStatus?.status === "finished") {
      HIS.push("/login/Results");
    }
  }, [quizStatus]);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={CLASSES.leftContainer}>
            <Grid container className={CLASSES.innerLeftcontainer} spacing={0} alignItems="center">
              <Grid item xs>
                <Grid container>
                  <Grid item container direction="column" xs={12}>
                    <form onSubmit={handleSubmit}>
                      <Grid item>
                        <Button onClick={() => HIS.goBack()} sx={{ textTransform: "none" }}>
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "140%",
                              color: "#FFFFFF",
                            }}
                          >
                            Back
                          </Typography>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "35px",
                            lineHeight: "130%",
                            color: "#FFFFFF",
                          }}
                        >
                          Enter Test Code
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "14.0303px",
                            lineHeight: "140%",
                            color: "#FFFFFF",
                          }}
                        >
                          Please Enter Your Test Code Here{" "}
                        </Typography>
                      </Grid>
                      <br />
                      <div>
                        {testCode.map((data, index) => {
                          return (
                            <input
                              style={{
                                width: "60px",
                                height: "60px",
                                marginRight: "10px",
                                borderRadius: "5px",
                                textAlign: "center",
                                border: "2px solid #FFFFFF",
                                outlineColor: "#FFFFFF",
                                fontWeight: 700,
                                fontSize: "17px",
                                background: "transparent",
                                color: "white",
                              }}
                              type="text"
                              name="testCode"
                              maxLength="1"
                              key={index}
                              value={data}
                              onChange={(e) => handleChange(e.target, index)}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        })}
                      </div>
                      <br />
                      <Grid item>
                        <Button
                          sx={{
                            backgroundColor: "#FFFFFF",
                            color: "#634699",
                            textTransform: "none",
                            borderRadius: "5px",
                            width: "21.1vw",
                            "&:hover": { backgroundColor: "#FFFFFF" },
                            ["@media (max-width:700px)"]: { width: "68vw" },
                          }}
                          type="submit"
                          variant="contained"
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "150%",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",

                              color: "#634699",
                            }}
                          >
                            Proceed
                          </Typography>
                        </Button>
                        <Snackbar open={open} autoHideDuration={6000} onClose={onCloseHandler}>
                          <Alert onClose={onCloseHandler} severity="warning" sx={{ width: "100%" }}>
                            It seems you are not accessible for this quiz. Please contact your tutor or admin.
                          </Alert>
                        </Snackbar>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={CLASSES.rightContainer}>
            <Grid container className={CLASSES.innerRightcontainer} spacing={0} alignItems="center">
              <Grid container align="center">
                <Grid item container direction="column" xs={12} alignItems="center">
                  <Grid item>
                    <img src={error} alt="pic" width="270px" height="280px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TESTCODE;
