/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/ScheduleApi.js
 * This JS file is intended to provide the api calls related to enrolled test packages.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { GET_METHOD } from "./ApiMethods";
import { MoodleURL } from "../config";
import { decrypt } from "../../../encrypt";
import { POST_METHODS } from "./AuthApi";

export const postMeetingSchedule = async (
  userid,
  packageid,
  sectionid,
  title,
  newCreatedTimestamp,
  value,
  event,Isfileuploaded
) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response;
      
    if (event) {
      const fileFormData = new FormData();
      fileFormData.append('event', event);
     
      response = await POST_METHODS(
        `${MoodleURL(token)}&wsfunction=local_quizattempt_save_event&moodlewsrestformat=json&userid=${userid}&courseid=${packageid}&sectionid=${sectionid}&timestamp=${newCreatedTimestamp}&title=${title}&description=${value}&isfileuploaded=${Isfileuploaded}`,
        fileFormData
      );
    } else {
      response = await GET_METHOD(
        `${MoodleURL(token)}&wsfunction=local_quizattempt_save_event&moodlewsrestformat=json&userid=${userid}&courseid=${packageid}&sectionid=${sectionid}&timestamp=${newCreatedTimestamp}&title=${title}&description=${value}&isfileuploaded=${Isfileuploaded}`
      );
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const getBookedTimesApi = async (
  userid,
  packageid,
  sectionid,
  dateStamp
) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_quizattempt_tutor_notavail&moodlewsrestformat=json&userid=${userid}&courseid=${packageid}&sectionid=${sectionid}&timestamp=${dateStamp}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilteredEventsApi = async (
  userid,
  test,
  section,
  month,
  year
) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_quizattempt_getmyevents&moodlewsrestformat=json&userid=${userid}&courseid=${test}&sectionid=${section}&month=${month}&year=${year}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
