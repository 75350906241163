/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class test.js
 * This JS file is for showing the confirmation page before starting a test attempt
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Box,
  CardContent,
  Card,
  Button,
  Dialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import TEST_ATTEMPT_SCREEN from "./TestAttemptScreen";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  startQuizAttempt,
  fetchQuestionList,
  fetchQuizStatus,
  changeTestStatus
} from "../../../../webservice/students/actions/TestAttemptAction";
import TestAttemptScreenNew from "./testAttemptScreenNew";

const USE_STYLES = makeStyles({
  linearProgress: {
    marginTop: "12px",
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EFF1F3",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#634699",
    },
  },
});

const TEST = () => {
  const params = useParams();
  const history = useHistory();
  const quizid = params.quizid;
  const { quizList } = useSelector((state) => state.mytestpackagestate);

  function fancyTimeFormat(duration) {
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const [open, setOpen] = React.useState(false);
  const { userid } = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  const { questionList } = useSelector((state) => state.testAttemptState);
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const { validateTestCode } = useSelector((state) => state.guestAccessState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const [showCard, setShowCard] = useState(false);

  const handleOkButtonClick = () => {
    setShowCard(true);
  };


  const [attemptidd, setAttemptId] = useState(quizStatus?.attemptid?quizStatus?.attemptid:startattempt?.attempt?.id);

  const HANDLE_CLICK_OPEN = async() => {
    await dispatch(startQuizAttempt(quizid));
   if (quizStatus?.status === "notstarted") {
      dispatch(changeTestStatus(false));

      let index = quizList.quizzes.findIndex(quiz => quiz.id === parseInt(quizid));

      history.push({
        pathname: '/my-test-packages/startTest',
        state: quizList.quizzes[index]
      });
      setOpen(true);
      if (startattempt?.attempt?.id){
        dispatch(fetchQuestionList(quizid, startattempt?.attempt?.id)); 
      }
       
    } else if (quizStatus?.status === "inprogress") {
      dispatch(changeTestStatus(false));
      // dispatch(startQuizAttempt(quizid));
      let index = quizList.quizzes.findIndex(quiz => quiz.id === parseInt(quizid));

      history.push({
        pathname: '/my-test-packages/startTest',
        state: quizList.quizzes[index]
      });
      if(quizStatus?.attemptid){
        dispatch(fetchQuestionList(quizid, quizStatus?.attemptid)); 
      }
      
    }
  };

  useEffect(() => {
    if (quizid && validateTestCode?.usertype === "offline") {
      dispatch(startQuizAttempt(quizid));
      setOpen(true);
    }
  }, [validateTestCode, quizid, dispatch]);

  useEffect(() => {
    dispatch(fetchQuizStatus(userid, quizid));
  }  , []);


  // const max = questionList?.length;
  const classes = USE_STYLES();
  return (
    <>
      {questionList?.length !== 0 ?
        <Grid container spacing={2}>
          {quizList && quizList.quizzes && quizList.quizzes.length
            ? quizList.quizzes.map((quiz) => {
              return quiz.id === parseInt(quizid) ? (
                <Fragment key={quiz.id}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontStyle: "regular",
                        color: "#634699",
                        fontSize: "19px",
                        lineHeight: "150%",
                      }}
                    >
                      {quiz.name}
                    </Typography>
                  </Grid>
                  {questionList &&
                    questionList[0]?.message === "No questions in quiz" ? null : (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ paddingRight: "50px" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontStyle: "regular",
                          color: "#000000",
                          fontSize: "24px",
                          lineHeight: "33.6px",
                        }}
                      >
                        time &nbsp;
                        <span
                          style={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: "30px",
                            lineHeight: "56px",
                          }}
                        >
                          {fancyTimeFormat(quiz.timelimit)}
                        </span>
                      </Typography>
                    </Grid>
                  )}

                  {questionList &&
                    questionList[0]?.message === "No questions in quiz" ? (
                    <Grid item container xs={12} textAlign="center">
                      <Grid item xs={12} paddingRight="50px">
                        <Card>
                          <CardContent>
                            { }
                            <Grid item xs={12} p={5}>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontStyle: "regular",
                                  color: "#634699",
                                  fontSize: "24px",
                                  lineHeight: "33.6px",
                                }}
                              >
                                Currently no Questions have been added yet.
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontStyle: "regular",
                                  color: "#634699",
                                  fontSize: "24px",
                                  lineHeight: "33.6px",
                                }}
                              >
                                Please try again.
                              </Typography>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={2}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: "19px",
                            lineHeight: "33.6px",
                          }}
                        >
                          Progress
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={8}>
                        <Box width="100%">
                          <LinearProgress
                            className={classes.linearProgress}
                            variant="determinate"
                            value={
                              +(quizStatus?.status === "inprogress" &&
                                quizStatus?.answer
                                ? (quizStatus?.answer?.length / quizStatus?.totalquestions) * 100
                                : "0")
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={2}
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ paddingRight: "70px", paddingTop: "7px" }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {quizStatus?.status === "inprogress" &&
                            quizStatus?.answer
                            ? quizStatus?.answer?.length
                            : "0"}
                          /{quizStatus?.totalquestions}
                        </Typography>
                      </Grid>
                      <Grid container spacing={2} textAlign="center" p={4}>
      {!showCard && (
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "red",
              fontSize: "16px",
              lineHeight: "33.6px",
              textAlign: "center",
            }}
          >
            The Instructions:
          </Typography>
        </Grid>
      )}

      {!showCard && (
        <>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontStyle: "regular",
                color: "#634699",
                fontSize: "13px",
                lineHeight: "21.6px",
                textAlign: "left",
              }}
            >
              <span style={{ fontSize: "15px", color: "#000", fontWeight: 700 }}>For Section A:</span> <br /><span style={{ fontSize: "15px" }}> Solve each problem, choose the best answers from the choices provided.</span>
              <br />
              <br />
              <span style={{ fontSize: "15px", color: "#000", fontWeight: 700 }}>For Section B:</span> <br /><span style={{ fontSize: "15px" }}> Expected clear and detailed explanation for questions which carry 2 marks or more than 2 marks.</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="ul"
              style={{ color: "#000", textAlign: "left", fontSize: "15px" }}
            >
              <li style={{ fontSize: "15px" }}><span style={{ color: "#634699", fontSize: "15px" }}>Student must show all necessary working clearly.</span></li>
              <br /> <li style={{ fontSize: "15px" }}><span style={{ color: "#634699", fontSize: "15px" }}>Our honor code is built upon principles of honesty, integrity, responsibility, and fairness and upholding the highest standards of ethical conduct and academic excellence.</span></li>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
    <Typography
      sx={{
        fontWeight: 700,
        fontStyle: "regular",
        color: "Black",
        fontSize: "16px",
        lineHeight: "33.6px",
        textAlign: "center",
      }}
    >
      Good luck!
    </Typography>
  </Grid>  */}
          <Grid item xs={12}>
            <Button
              onClick={handleOkButtonClick}
              variant="contained"
              sx={{
                backgroundColor: "#634699",
                textTransform: "none",
                "&:hover": { backgroundColor: "#634699" },
                marginRight: "10px",
              }}
            >
              Ok, Understood 
            </Button>
          </Grid>
        </>
      )}

      {showCard && (
                        <Grid item xs={12} paddingRight="50px">
                          <Card>
                            <CardContent>
                              { }
                              <Grid item xs={12} p={5}>
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontStyle: "regular",
                                    color: "#634699",
                                    fontSize: "24px",
                                    lineHeight: "33.6px",
                                  }}
                                >
                                  It’s your time to shine !
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontStyle: "regular",
                                    color: "#634699",
                                    fontSize: "24px",
                                    lineHeight: "33.6px",
                                  }}
                                >
                                  ARE YOU READY ?!
                                </Typography>
                                <Button
                                  onClick={HANDLE_CLICK_OPEN}
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#634699",
                                    textTransform: "none",
                                    "&:hover": { backgroundColor: "#634699" },
                                    marginRight: "10px",
                                  }}
                                >
                                  Here we go !
                                </Button>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
      )}
                      </Grid>
                    </>
                  )}
                </Fragment>
              ) : null;
            })
            : null}
        </Grid> : ""}
    </>
  );
};

export default TEST;
