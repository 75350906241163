/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class img.js
 * This JS file is intended to create a seperate component for logo image and reuse when needed.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */
import img from "../../../assets/images/No-scheduleicon.png";

const IMG = (props) => {
  return <img src={img} alt="Cenable-care" width={props.width} />;
};

export default IMG;
