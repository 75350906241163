import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  AttentionIcon,
  CongratulationIcon,
  NotifynextIcon,
  NotifyprevIcon,
  ReminderIcon,
  TestPackageListIcon,
} from "../../../assets/icons";
import { Fragment } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const notifications = [
  {
    id: 1,
    name: "Amrita Mohan",
    message: "has sent your Test Results ! Check it Out",
  },
  {
    id: 2,
    name: "Reminder",
    message:
      "Your midterm exam for Math 101 is scheduled for tomorrow at 9am. Make sure to prepare and review your notes",
  },
  {
    id: 3,
    name: "Congratulations!",
    message:
      "Your application for the scholarship program has been approved. You will receive further instructions via email.",
  },
  {
    id: 4,
    name: "Attention!",
    message:
      "The school will be closed tomorrow due to inclement weather. All classes and activities are cancelled. Stay safe!",
  },
];
const USE_STYLES = makeStyles({
  iconContainer: {
    display: "inline-block",
    padding: "4px",
    borderRadius: "5px",
  },
  icon: {
    display: "inline-block",
    padding: "4px",
    paddingBottom: "0px",
    borderRadius: "5px",
  },
});
const colorPicker = (name) => {
  if (name === "Amrita Mohan") {
    return [<AccountCircleOutlinedIcon sx={{ color: "white" }} />, "#6c757d"];
  } else if (name === "Reminder") {
    return [<ReminderIcon />, "#ECA359"];
  } else if (name === "Congratulations!") {
    return [<CongratulationIcon />, "#63BF53"];
  } else if (name === "Attention!") {
    return [<AttentionIcon />, "#C64D49"];
  } else {
    return [<ReminderIcon />, "#6c757d"];
  }
};
const Notifications = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();

  return (
    <div>
      <Scrollbars style={{ height: 400 }}>
        <Box px={2}>
          <Grid item xs={12}>
            <Button
              sx={{
                color: "#634699",
                fontWeight: "bold",
                textTransform: "none",
              }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={11} md={11}>
                <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: 700,
                    fontSize: "40px",
                    lineHeight: "56px",
                  }}
                >
                  Notifications
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1} md={1} sx={{ display: "flex" }}>
                <NotifyprevIcon sx={{ fontSize: "20px", marginTop: "18px" }} />
                &nbsp;{" "}
                <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "56px",
                  }}
                >
                  1/250
                </Typography>
                &nbsp;
                <NotifynextIcon sx={{ fontSize: "20px", marginTop: "18px" }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {notifications.map((notification, index) => {
              const [icon, color] = colorPicker(notification.name);
              return (
                <Card key={index} sx={{ marginBottom: "10px" }}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={2} md={1}>
                        <div className={CLASSES.icon} style={{ backgroundColor: color }}>
                          {icon}
                        </div>
                      </Grid>

                      <Grid item xs={7} md={7}>
                        <Typography
                          sx={{
                            color: "#634699",
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "22.4px",
                          }}
                        >
                          {notification.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#634699",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "22.4px",
                          }}
                        >
                          {notification.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </Grid>

          <Grid container sx={{ marginBottom: "20px" }}>
            <Grid item xs={4} md={8.5} />
            <Grid item xs={4} md={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  background: "#634699",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  textTransform: "none",
                  marginLeft: "10px",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#634699",
                    boxShadow: "none",
                  },
                }}
              >
                {" "}
                <ArrowLeftIcon />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    fontSize: "14px",
                    lineHeight: "140%",
                  }}
                >
                  Prev
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4} md={1.3} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  background: "#634699",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  textTransform: "none",
                  marginLeft: "10px",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#634699",
                    boxShadow: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    fontSize: "14px",
                    lineHeight: "140%",
                    textAlign: "center",
                  }}
                >
                  Next
                </Typography>
                <ArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
    </div>
  );
};

export default Notifications;
