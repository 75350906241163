/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/actions/FeedbackAction.js
 * This JS file is intended to provide the action methods with respect to feedback part
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { getAllFeedbacks } from "../api/FeedbackApi";

export const fetchFeedbackList = (userid) => async (dispatch) => {
  try {
    const getFeedbacks = await getAllFeedbacks(userid);
    dispatch({ type: "GET_ALL_FEEDBACKS", payload: getFeedbacks });
  } catch (error) {
    return error;
  }
};
