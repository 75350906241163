/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class login.js
 * This JS file is intended to provide the layout of login component for the users
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 Feb 2022
 */
import React, { useEffect, useState } from "react";
import { Typography, Button, Grid } from "@mui/material";
import LoginForm from "./LoginForm";
import Log from "../../../assets/images/cenablecare-logo.png";
import { makeStyles } from "@mui/styles";
import LoginPurpleBg from "../../../assets/images/login-purple-bg.png";
import LoginWhiteBg from "../../../assets/images/login-white-bg.png";
import { useHistory } from "react-router-dom";

const USE_STYLES = makeStyles({
  btn: {
    width: "40%",
    "@media (max-width:900px)": {
      width: "80%",
    },
  },
  leftContainer: {
    backgroundColor: "#634699",
    height: "100vh",
    // changes done by anusha
    // borderRadius: "15px",
    // backgroundImage: `url(${LoginPurpleBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10% 50%",
    backgroundSize: "90% 100%",

    "@media (max-width:900px)": {
      height: "80vh",
    },
  },
  rightContainer: {
    height: "100vh",
    weight: "100vh",
    // backgroundImage: `url(${LoginWhiteBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "99% 90%",
    backgroundSize: "25% 25%",
    "@media (max-width:900px)": {
      height: "65vh",
    },
  },
  innerLeftcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "25%",
    fontFamily: "Helvetica",
    color: "white",
    "@media (max-width:900px)": {
      height: "70vh",
      paddingLeft: "20%",
    },
  },
  innerRightcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Helvetica",
    alignItems: "center",

    "& .MuiInputBase-root": {
      fontWeight: "600",
    },

    "@media (max-width:900px)": {
      height: "50vh",
      paddingLeft: "0%",
    },
  },
});

const LOGIN = () => {
  const CLASSES = USE_STYLES();
  const HISTORY = useHistory();
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    localStorage.setItem("isOnline", JSON.stringify(isOnline));
  }, [isOnline]);
  const handleClickTakeTest = () => {
    // Set isOnline to false for mock test form
    localStorage.setItem("isOnline", JSON.stringify(false));
    // Redirect to the mock test form
    HISTORY.push("/login/mocktestform");
  };
  
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <div className={CLASSES.leftContainer}>
          <Grid
            container
            className={CLASSES.innerLeftcontainer}
            spacing={0}
            alignItems="center"
          >
            <Grid item xs>
              <LoginForm isOnline={isOnline} />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={CLASSES.rightContainer}>
          <Grid
            container
            className={CLASSES.innerRightcontainer}
            spacing={0}
            alignItems="center"
          >
            <Grid container align="center">
              <Grid
                item
                container
                direction="column"
                xs={12}
                alignItems="center"
              >
                <Grid item>
                  <img src={Log} alt="pic" />
                </Grid>
                <Button
                  className={CLASSES.btn}
                  style={{
                    backgroundColor: "#634699",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  type="submit"
                  onClick={handleClickTakeTest}
                  // onClick={() => HISTORY.push("/login/mocktestform")}
                  variant="contained"
                >
                  <b> Take Test ? </b>
                </Button>
                <br />
                <Grid item color="#634699" textAlign="center">
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 400,
                      fontSize: "14.0303px",
                      lineHeight: "140%",
                      marginBottom: "10px",
                    }}
                  >
                    *only for cenable care students
                  </Typography>
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "14.0303px",
                      lineHeight: "140%",
                    }}
                  >
                    Get registered !
                  </Typography>
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "14.0303px",
                      lineHeight: "140%",
                    }}
                  >
                    contact &nbsp;
                    <span
                      style={{
                        color: "#634699",
                        fontWeight: 400,
                        fontSize: "14.0303px",
                        lineHeight: "140%",
                      }}
                    >
                      admin@cenablecare.com
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default LOGIN;
