/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class routes/index.js
 * This JS file is intended to provide the main routes for the pages.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import LOGIN from "../../views/main/login/login";
import SIGNUP from "../../views/main/signup/SignUp";
import MAIN from "../../layouts/main";
import TESTCODE from "../../views/main/login/TestCode";
import LOGINERROR from "../../views/main/login/LoginError";
import MOCKTESTFORM from "../../views/main/login/MockTestForm";
import OTPSCREEN from "../../views/main/login/OtpScreen";
import TESTVERIFICATIONSCREEN from "../../views/main/login/TestVerificationScreen";
import GuardedRoute from "../../layouts/main/GuardedRoute";
import TestAttemptScreenNew from "../../views/students/test/packages/testAttemptScreenNew";
import { useSelector } from "react-redux";
import Offlinefeedback from "../../views/students/test/packages/OfflineResults";
import FeedbackNew from "../../views/students/test/packages/feedbackNew";
import OfflineFeedbacknew from "../../views/students/test/packages/OfflineFeedbacknew";
import OfflineQuestionFeedbacknew from "../../views/students/test/packages/OfflineQuestionFeedbacknew";
import OfflineResults from "../../views/students/test/packages/OfflineResults";
import NotFound from "../../views/main/NotFound";
const INDEX = () => {
  const { notStarted } = useSelector((state) => state.testAttemptState);
  return (
    <div>
      <Switch>
        <Route path="/login" exact>
          <LOGIN />
        </Route>
        <Route path="/error" exact>
          <LOGINERROR />
        </Route>
        <Route path="/login/testcode" exact>
          <TESTCODE />
        </Route>
        <Route path="/login/mocktestform" exact>
          <MOCKTESTFORM />
        </Route>
        <Route path="/login/mocktestform/otp" exact>
          <OTPSCREEN />
        </Route>
        <Route path="/login/testverificationscreen" exact>
          <TESTVERIFICATIONSCREEN />
        </Route>
        <Route path="/login/Results" exact>
          <OfflineResults />
        </Route>
        <Route path="/login/:id/feedbacks/:quizid" exact>
          <OfflineFeedbacknew />
        </Route>
        <Route path="/login/:subject/feedback/:quizid/:questionname" exact>
          <OfflineQuestionFeedbacknew />
        </Route>
        <Route path="/signup" exact>
          <SIGNUP />
        </Route>
        <Route path="/notfound">
                    <NotFound />
                  </Route>
        <GuardedRoute
          path="/my-test-packages/startTest"
          component={TestAttemptScreenNew}
          auth={notStarted} />
        <Route path="/">
          <MAIN />
        </Route>
      </Switch>
    </div>
  );
};

export default INDEX;
