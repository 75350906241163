/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/reducers/FeedbackReducer.js
 * This JS file is intended to provide the reducer for feedback part. 
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

const initialState = {
    allfeedbacks:null
  };

const FeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_FEEDBACKS":
          return {
            ...state,
            allfeedbacks: action.payload.data,
          };
        default:
          return state;
      }
}

export default FeedbackReducer