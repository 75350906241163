/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/config.js
 * This JS file is intended to provide the backend url globally to access the webservices. 
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

export const baseURL = process.env.REACT_APP_API_URL

export const MoodleURL = (user) => {
  return `webservice/rest/server.php?wstoken=${user}&moodlewsrestformat=json`;
};
