/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class success.js
 * This JS file is intended to give a success message screen after Test Attempt.
 *
 * @author tincy.thomas@centelon.com
 * @since 31 May 2022
 */

import React from "react";
import { Grid, Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Schedule from "../students/dashboard/schedule";
import Progress from "../students/dashboard/progress";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import autherror from "../../assets/images/autherror.png";

const USE_STYLES = makeStyles({
  btn: {
    width: "7.56vw",
    "@media (max-width:900px)": {
      width: "7.56vw",
    },
  },

  errorScreenContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "15px",
  },
  paperContainer: {
    paddingTop: "100px",paddingBottom:"100px",
    display:"flex",
    justifyContent:"center",
    textAlign: "center",
  },
  imgContainer: {
    width: "21.87vw",
    "@media (max-width:900px)": {
      width: "30.87vw",
    },
  },
});

const AuthenticationError = ({ show, error, updateError }) => {
  const CLASSES = USE_STYLES();
  const HISTORY = useHistory();
  return (
    <Scrollbars style={{ height: 600 }}>
      <Box px={2}>
        <Grid container item spacing={2} className={CLASSES.errorScreenContainer}>
          <Grid item xs={12} md={12}>
            <Grid container  item spacing={2} direction="column">
              <Grid item xs={12} md={12}>
                <Paper className={CLASSES.paperContainer}>
                  <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    className={CLASSES.textContainer}
                  >
                    <Grid item xs={12}>
                      <img
                        src={autherror}
                        alt="Cenable-care"
                        className={CLASSES.imgContainer}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: "700",
                          fontStyle: "normal",
                          fontSize: "2.83rem",
                          lineHeight: "3.96rem",
                          textAlign: "center",
                        }}
                      >
                        We are so sorry..
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#98A0AB",
                          fontWeight: "400",
                          fontStyle: "normal",
                          fontSize: "1.3rem",
                          lineHeight: "1.82rem",
                        }}
                      >
                        The page you are trying to access
                      </Typography>
                      <Typography
                        sx={{
                          color: "#98A0AB",
                          fontWeight: "400",
                          fontStyle: "normal",
                          fontSize: "1.3rem",
                          lineHeight: "1.82rem",
                        }}
                      >
                        has restricted access.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          HISTORY.goBack()
                          updateError(false);
                        }}
                        className={CLASSES.btn}
                        style={{
                          backgroundColor: "#634699",
                          color: "#FFFFFF",
                          textTransform: "none",
                          fontWeight: "700px",
                          fontSize: "0.81rem",
                        }}
                        type="submit"
                        variant="contained"
                      >
                        <b>Go Back</b>
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} md={4}>
                <Progress />
              </Grid>
              <Grid item xs={12} md={4}>
                <Schedule />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>

      </Box>
    </Scrollbars>
  );
};

export default AuthenticationError;
