/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class pic.js
 * This JS file is intended to create a seperate component for logo image and reuse when needed.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 26 July 2022
 */
import img from "../../../assets/images/noprogress.png";

const PIC = (props) => {
  return <img src={img} alt="Cenable-care" width={props.width} />;
};

export default PIC;
