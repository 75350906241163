/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/reducers/MyTestPackageReducer.js
 * This JS file is intended to provide the reducer for enrolled test packages.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

const initialState = {
  testList: null,
  contentlist: null,
  courseList: null,
  quizList: null,
  quizAttemptDetail: null,
};

const MyTestPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TESTS":
      return {
        ...state,
        testList: action.payload.data,
      };
    case "GET_TEST_DETAILS":
      return {
        ...state,
        contentList: action.payload.data,
      };
    case "RESET_TEST_DETAILS":
      return {
        ...state,
        contentList: action.payload,
      };
    case "GET_COURSES":
      return {
        ...state,
        courseList: action.payload.data,
      };
    case "RESET_COURSES":
      return {
        ...state,
        courseList: action.payload,
      };
    case "GET_QUIZZES":
      return {
        ...state,
        quizList: action.payload.data,
      };
    case "GET_QUIZ_ATTEMPT_DETAIL":
      return {
        ...state,
        quizAttemptDetail: action.payload.data,
      };
    default:
      return state;
  }
};

export default MyTestPackageReducer;
