/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestCustomize.js
 * This JS file is intended to create a component that performing the drag and drop feature for customizing the test list.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Table,
  Dialog,
  Alert,
  AlertTitle,
  Typography
} from "@mui/material";
import { TestPackageListIcon } from "../../../../assets/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchCourseContents,
  customizeList,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useParams, Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const usestyles = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "3px",
    borderRadius: "5px",
    margin: "10px",
  },
});

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  }
};
const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));
const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));

const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: 'inherit',
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));


const TestCustomize = () => {
  const params = useParams();
  const [modal, setModal] = useState(false);
  const courseid = params.id;
  const history = useHistory();
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );

  const dispatch = useDispatch();
  // const { userid } = useSelector((state) => state.authState);
  const userid = params.userid
  const [leftItems, setLeftItems] = useState(
    coursecontents?.filter(
      (item) => item.section !== 0 && item.modules && item.modules.length !== 0
    ) || []
  );
  // useEffect(() => {
  //   // Update leftItems when coursecontents changes
  //   setLeftItems(
  //     coursecontents?.filter(
  //       (item) => item.section !== 0 && item.modules && item.modules.length !== 0
  //     ) || []
  //   );
  // }, [coursecontents]);

  const [rightItems, setRightItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchCourseContents(courseid, userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleItemClick = (item) => {
  //   // Check if the item is already in the right grid
  //   const isAlreadySelected = rightItems.some(
  //     (selectedItem) => selectedItem.id === item.id
  //   );

  //   if (!isAlreadySelected) {
  //     // Remove the item from the left grid
  //     setLeftItems(leftItems.filter((leftItem) => leftItem.id !== item.id));
  //     // Add the item to the right grid
  //     setRightItems([...rightItems, item]);
  //   }
  // };
  const handleItemClick = (item) => {
    // Check if the item is already in the right grid
    const isAlreadySelected = rightItems.some(
      (selectedItem) => selectedItem.id === item.id
    );
  
    // Check if the item is already enrolled
    const isEnrolled = item.secvisible === true;
  
    if (!isAlreadySelected && !isEnrolled) {
      // Remove the item from the left grid
      setLeftItems(leftItems?.filter((leftItem) => leftItem?.id !== item?.id));
      // Add the item to the right grid
      setRightItems([...rightItems, item]);
    }
  };
  
  const handleRemoveItem = (item) => {
    // Remove the item from the right grid
    setRightItems(rightItems.filter((rightItem) => rightItem.id !== item.id));
    // Add the item back to the left grid
    setLeftItems([...leftItems, item]);
  };
  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );
  const { courseList } = useSelector((state) => state.testPackageListReducer);

localStorage.setItem("filteredContents", JSON.stringify(filteredContents));

  const clickHandler = () => {
    dispatch(customizeList(rightItems));
    if (rightItems.length !== 0) {
      localStorage.setItem("userid", userid);
      localStorage.setItem("courseid", courseid);
      localStorage.setItem("courseList", JSON.stringify(courseList));
      history.push(`/test-packages/orderconfirmation/${courseid}`);
    } else {
      setModal(true);
    }
  };
  useEffect(() => {
    localStorage.setItem("customizeList", JSON.stringify(rightItems));
  }, [rightItems]);
 
  const classes = usestyles();
  return (
    <>
      <Grid container item xs={12} spacing={2}>
      <Grid item container xs={12}>
        <Grid item xs={12} md={6}>
        <SEARCH>
            <SEARCH_ICON_WRAPPER>
              <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
            </SEARCH_ICON_WRAPPER>
            <STYLED_INPUT_BASE
              type="text"
              placeholder="Search Topics"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />{" "}
          </SEARCH>
        </Grid>
        <Grid item container xs={12} md={6} spacing={2}>
          <Grid item xs={12} md={6} >
            <Button
              onClick={() => {
                history.push(`/test-packages/${courseid}/${userid}`);
              }}
              sx={{
                width: "100%",
                backgroundColor: "#E2DDEC",
                color: "#634699",
                fontWeight: "bold",
                textTransform: "none",marginLeft:"10px",
                "&:hover": { backgroundColor: "#E2DDEC" },
              }}
            >
              Cancel Customization
            </Button>
          </Grid>
          <Grid item xs={12} md={6} >
            <Button
              onClick={clickHandler}
              sx={{
                width: "100%",
                backgroundColor: "#634699",
                color: "#fff",
                fontWeight: "bold",
                textTransform: "none",marginLeft:"10px",
                "&:hover": { backgroundColor: "#634699" },
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>

        {modal ? (
          <Dialog
            open={modal}
            onClose={() => {
              setModal(false);
            }}
          >
            <Alert severity="success" color="info">
              <AlertTitle>Alert</AlertTitle>
              Please select atleast one topic!
            </Alert>
          </Dialog>
        ) : (
          ""
        )}
        <Grid item xs={12} md={6}  >
          
          {/* Left Side: Available Items Grid */}
          {leftItems && leftItems.length === 0 && (
      <Grid item xs={12} style={{ padding: 5, margin: "5px", minHeight: "50px",  color: "#634699" }}>
        <p></p>
        <Typography sx={{color: "#CBC3DD",
  fontWeight: 700,
  fontSize: "16.38px",
  lineHeight: "36.94px",
  textAlign: "center",}}>
    Click a card on the right to deselect topics.
              
              </Typography></Grid>
      
    )}
          <div style={{background:"#E7E5EE", padding:leftItems && leftItems.length === 0?"0px":"5px"}}>
          <Grid container  sx={{ margin: "0px 0px 0px 0px", }} >  
            {leftItems && leftItems
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  val.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((item, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{
                    padding: 5,
                    margin: "5px",
                    minHeight: "50px",
                    backgroundColor: "#fff",
                    color: "#634699",
                    cursor: item.secvisible === true ?"default":"pointer",
                  }}
                  onClick={() => handleItemClick(item)}
                >
                  <Table key={index}>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{borderBottom:"none",padding:"10px"}}>
                          <TestPackageListIcon
                          className={classes.icon}/>
                        </TableCell>

                        <TableCell
                          align="left"
                          width="100%"
                          component="th"
                          scope="row" sx={{borderBottom:"none",padding:"10px"}}
                          style={{
                            fontWeight: "bold",
                            color: "#634699",
                          }}
                        >
                          {item.name} -{" "}
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: item?.sectioncurrency,
                            maximumSignificantDigits: 3,
                          }).format(item?.actualcost === "0" ? item?.sectioncost : item?.actualcost)}
                        </TableCell>

                        <TableCell
                          align="center"
                          margin="18px"sx={{borderBottom:"none",padding:"10px"}}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#634699",
                            textAlign: "left",
                          }}
                        >
                          {item.secvisible === true ? <p>Enrolled</p> : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              ))}
          </Grid></div>
        </Grid>

        <Grid item xs={12} md={6} >
          {/* Right Side: Selected Items Grid */}
          {rightItems.length === 0 && (
      <Grid item xs={12} style={{ padding: 5, margin: "5px", minHeight: "50px",  color: "#634699" }}>
        <p></p>
        <Typography sx={{color: "#CBC3DD",
  fontWeight: 700,
  fontSize: "16.38px",
  lineHeight: "36.94px",
  textAlign: "center",}}>
    Click a card on the left to select topics.
              
              </Typography></Grid>
      
    )}
    <div style={{background:"#E7E5EE",padding:rightItems.length === 0?"0px":"5px"}}>
          <Grid container  >
            
            {rightItems.map((item, index) => (
              <Grid
                item
                xs={12}
                key={index}
                style={{
                  padding: 5,
                  margin: "5px",
                  minHeight: "50px",
                  backgroundColor: "#fff",
                  color: "#634699",
                  cursor:"pointer",
                }}
                onClick={() => handleRemoveItem(item)}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{borderBottom:"none",padding:"10px"}}>
                        <TestPackageListIcon 
                                                    className={classes.icon}
                                                    />
                      </TableCell>

                      <TableCell
                        align="left"
                        width="100%"
                        component="th" sx={{borderBottom:"none",padding:"10px"}}
                        scope="row"
                        style={{
                          fontWeight: "bold",
                          color: "#634699",
                        }}
                      >
                        {item.name} -{" "}
                        {new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: item?.sectioncurrency,
                          maximumSignificantDigits: 3,
                        }).format(item?.actualcost === "0" ? item?.sectioncost : item?.actualcost)}
                      </TableCell>

                      <TableCell
                        align="center"
                        margin="18px" sx={{borderBottom:"none",padding:"10px"}}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#634699",
                          textAlign: "left",
                        }}
                      >
                        {item.secvisible === true ? <p>Enrolled</p> : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            ))}
          </Grid></div>
        </Grid>
      </Grid>
    </>
  );
};

export default TestCustomize;
