/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/TestAttemptApi.js
 * This JS file is intended to provide the api calls related to Test attempt.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { MoodleURL } from "../config";
import { GET_METHOD, POST_METHOD } from "./ApiMethods";
import { decrypt } from "../../../encrypt";

export const startQuiz = async (quizid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=mod_quiz_start_attempt&moodlewsrestformat=json&quizid=${quizid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const questionList = async (quizid, attemptid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_quizattempt_quiz_questions&moodlewsrestformat=json&quizid=${quizid}&attemptid=${attemptid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

// export const saveChange = async (
//   attemptid,
//   slot,
//   qtype,
//   answer,
//   questionid,
//   isUpload,
//   uploadedFiles
// ) => {
//   try {
//     let user = window.localStorage.getItem("userToken");
//     let d = decrypt(user);
//     if (qtype === "match" || qtype === "multianswer" || qtype === "multichoice") {
//       let queryparams = "";
//       if (typeof answer !== "object") {
//         queryparams = queryparams + "&answer[0]=" + answer;
//       }
//       else {
//         answer.map((data, index) => {
//           queryparams = queryparams + `&answer[${index}]=` + (typeof data === "object" ? data.index : data);
//         });
//       }
//       queryparams = queryparams.trim();
//       let response = await POST_METHOD(
//         `${MoodleURL(
//           d
//         )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}${queryparams}&questionid=${questionid}&isfileuploaded=${isUpload}`,
//         uploadedFiles
//       );
//       return response;
//     } else {
//       let response = await POST_METHOD(
//         `${MoodleURL(
//           d
//         )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}&answer[0]=${answer}&questionid=${questionid}&isfileuploaded=${isUpload}`,
//         uploadedFiles
//       );

//       return response;
//     }
//   } catch (error) {
//     return error;
//   }
// };
export const saveChange = async (
  attemptid,
  slot,
  qtype,
  answer,
  questionid,
  isUpload,
  uploadedFiles
) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    if (qtype === "match" || qtype === "multianswer" || qtype === "multichoice") {
      let queryparams = "";
      if (typeof answer !== "object") {
        queryparams = queryparams + "&answer[0]=" + answer;
      }
      else {
        answer.map((data, index) => {
          queryparams = queryparams + `&answer[${index}]=` + (typeof data === "object" ? data.index : data);
        });
      }
      queryparams = queryparams.trim();
      let response = await POST_METHOD(
        `${MoodleURL(
          d
        )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}${queryparams}&questionid=${questionid}&isfileuploaded=${isUpload}`,
        uploadedFiles
      );
      return response;
    } else if (qtype === "essay" || qtype === "shortanswer") {
      let response = await POST_METHOD(
        `${MoodleURL(
          d
        )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}&answer[0]=${(encodeURIComponent(answer))}&questionid=${questionid}&isfileuploaded=${isUpload}`,
        uploadedFiles
      );
      return response;
    } else {
      let response = await POST_METHOD(
        `${MoodleURL(
          d
        )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}&answer[0]=${answer}&questionid=${questionid}&isfileuploaded=${isUpload}`,
        uploadedFiles
      );

      return response;
    }
  } catch (error) {
    return error;
  }
};


export const finishQuizAttempt = async (attemptid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=mod_quiz_process_attempt&moodlewsrestformat=json&attemptid=${attemptid}&finishattempt=1`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const reviewQuizAttempt = async (attemptid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=mod_quiz_get_attempt_summary&moodlewsrestformat=json&attemptid=${attemptid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getQuizStatus = async (userid, quizid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_quizattempt_checkuserattempt&moodlewsrestformat=json&userid=${userid}&quizid=${quizid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};
export const saveChangeSubQuestions = async (
  attemptid,
  slot,
  qtype,
  answer,
  questionid,
  isUpload,
  uploadedFiles
) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    function splitAnswers(array, result) {
      array.forEach((element) => {

        if (Array.isArray(element.subQuestions) && element.subQuestions.length > 0) {
          splitAnswers(element.subQuestions, result); // recursively traverse nested subquestions array
        }
        else {
          result.push(element.answer);
        }
      });
      return result;
    }
    let finalAnswers;
    finalAnswers = splitAnswers(answer.subQuestions, []);
   
    let queryparams = ""; let matchData = "";
    finalAnswers.forEach((data, index) => {
      if (typeof data === "object") {
        matchData = "";
        data.forEach((option, optionIndex) => {
          matchData += "sub" + optionIndex + "-" + option.index + (optionIndex === data.length - 1 ? "" : ",");
        })
        queryparams += `&answer[${index}]=` + matchData;
      }
      else {
        queryparams += `&answer[${index}]=` + encodeURIComponent(data);
      }
    });
    let response = await POST_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}${queryparams}&questionid=${questionid}&isfileuploaded=${isUpload}`,
      uploadedFiles
    );
    return response;
    // }else{
    //   let response = await POST_METHOD(
    //     `${MoodleURL(
    //       d
    //     )}&wsfunction=local_quizattempt_save_answer&moodlewsrestformat=json&attemptid=${attemptid}&slot=${slot}&qtype=${qtype}&answer[0]=${answer}&questionid=${questionid}&isfileuploaded=${isUpload}`,
    //     uploadedFiles
    //   );

    //   return response;
    // }  
  } catch (error) {
    return error;
  }
};
