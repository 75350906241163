/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class QuestionScreenNew.jsx
 * This JSX file created for handling the Question view screen.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 30 Jan 2023
 */
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React from "react";
import EssayNew from "./essayNew";
import MultiChoiceNew from "./multiChoiceNew";
import QuestionSideBar from "./QuestionSideBar";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchQuestionStatus,
  fetchQuizStatus,
  fetchTrueorfalseStatus,
  saveAnswer,
  saveAnswerSubQuestions,
} from "../../../../webservice/students/actions/TestAttemptAction";
import { useDispatch } from "react-redux";
import TrueFalseNew from "./TrueFalseNew";
import ShortAnswerNew from "./ShortAnswerNew";
import MatchNew from "./MatchNew";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MultiQuestion from "./MultiQuestion";
import MultiAnswer from "./MultiAnswer";
import Lottie from "react-lottie";
import animationData from "../../../../assets/images/loader.json";
import { calculateQuestionLevels } from "../../../../utils/AppConstants";
import { useBeforeUnload } from "../../../../utils/customHooks";
import WarningAlert from "./WarningAlert";
import { Prompt } from "react-router-dom";
import { useHistory } from "react-router-dom";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const USE_STYLES = makeStyles({
  root: {
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.1)",
      background: "#E0E1E2",
    },
  },
});
const checkFinalQuestion = (level, questionStatusResponse, secondLevelIndex, thirdLevelIndex, count) => {
  if (level === 1) {
    if (count === questionStatusResponse.length - 1) {
      return "Submit Test";
    }
    return "Next";
  } else if (level === 2) {
    if (
      secondLevelIndex === questionStatusResponse[count]?.subQuestions.length - 1 &&
      count === questionStatusResponse.length - 1
    ) {
      return "Submit Test";
    }
    return "Next";
  } else if (level === 3) {
    if (
      thirdLevelIndex === questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions?.length - 1 &&
      count === questionStatusResponse.length - 1 &&
      secondLevelIndex === questionStatusResponse[count]?.subQuestions.length - 1
    ) {
      return "Submit Test";
    }
    return "Next";
  }
};
const QuestionScreenNew = ({
  question,
  count,
  setCount,
  quiz,
  open,
  setOpen,
  submit,
  setSubmit,
  attemptidd,
  thirdLevelIndex,
  secondLevelIndex,
  setthirdLevelIndex,
  setsecondLevelIndex,
}) => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedAnswer, setSelectedAnswer] = useState("");
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const { userid } = useSelector((state) => state.authState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const [review, setReview] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showAlertModal, setAlertModal] = React.useState(false);
  const [level, setLevel] = useState(1);
  const [formData, setFormData] = useState(new FormData());
  const [editorData, setEditorData] = useState("");
  useEffect(() => {
    dispatch(fetchQuizStatus(userid, quiz.id));
  }  , []);
  var ctrlKeyDown = false;
  const keydown = (e) => {
    if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && ctrlKeyDown)) {
      // Pressing F5 or Ctrl+R
      e.preventDefault();
    } else if ((e.which || e.keyCode) == 17) {
      // Pressing  only Ctrl
      ctrlKeyDown = true;
    }
  };

  const keyup = (e) => {
    // Key up Ctrl
    if ((e.which || e.keyCode) == 17) ctrlKeyDown = false;
  };

  useBeforeUnload({
    when: true,
    message: "Are you sure you want to leave this test?",
    action: () => setSubmit("submit"),
  });

  useEffect(() => {
    setOpenAlert(true);
    window.addEventListener("keydown", keydown);
    window.addEventListener("keyup", keyup);
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    return () => {
      window.removeEventListener("keydown", keydown);
      window.removeEventListener("keyup", keyup);
      document.removeEventListener("contextmenu", (event) => event.preventDefault());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.status === "Answer Later" ||
      questionStatusResponse[count]?.status === "Answer Later" ||
      questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.status ===
        "Answer Later"
    ) {
      setReview(true);
    } else {
      setReview(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, secondLevelIndex, thirdLevelIndex]);
  const handleReview = () => {
    if (level === 1) {
      if (review) {
        setReview(false);
        if (questionStatusResponse[count].answer !== "" || questionStatusResponse[count].answer !== []) {
          questionStatusResponse[count].status = "Answered";
        } else {
          questionStatusResponse[count].status = "Not Answered";
        }
      } else {
        questionStatusResponse[count].status = "Answer Later";
        setReview(true);
      }
    } else if (level === 2) {
      if (review) {
        setReview(false);
        if (
          questionStatusResponse[count].subQuestions[secondLevelIndex].answer !== "" ||
          questionStatusResponse[count].subQuestions[secondLevelIndex].answer !== []
        ) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answered";
        } else {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Not Answered";
        }
      } else {
        questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answer Later";
        setReview(true);
      }
    } else if (level === 3) {
      if (review) {
        setReview(false);
        if (
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer !== "" ||
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer !== []
        ) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
            "Answered";
        } else {
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
            "Not Answered";
        }
      } else {
        questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
          "Answer Later";
        setReview(true);
      }
    }
  };

  const handleChange = (index,option) => {
    // Level 1 events on selection
    if (level === 1) {
      questionStatusResponse[count].status = (index !== "" && index !== undefined) ? "Answered" : "Not Answered";
      if (questionStatusResponse[count].type === "multichoice" && parseInt(question.single) === 0) {
        if (questionStatusResponse[count].answer.includes(index)) {
          const newSelection = [...questionStatusResponse[count].answer];
          newSelection.splice(newSelection.indexOf(index), 1);
          setSelectedAnswer(newSelection);
          questionStatusResponse[count].answer.splice(questionStatusResponse[count].answer.indexOf(index), 1);
        } else {
          const newSelection = [...questionStatusResponse[count].answer, index];
          questionStatusResponse[count].answer.push(index);
          setSelectedAnswer(newSelection);
        }
      } else {
        questionStatusResponse[count].answer = index;
        setSelectedAnswer(index);
      }
    }
    // Level 2 events on selection
    if (level === 2) {
      if (option !== "" && option !== undefined) {
      questionStatusResponse[count].subQuestions[secondLevelIndex].answer = option;
      questionStatusResponse[count].subQuestions[secondLevelIndex].status = (option !== "" && option !== undefined) ? "Answered" : "Not Answered";
      setSelectedAnswer(option);
    }
    else {
      questionStatusResponse[count].subQuestions[secondLevelIndex].answer = index;
      questionStatusResponse[count].subQuestions[secondLevelIndex].status = (index !== "" && index !== undefined) ? "Answered" : "Not Answered";
      setSelectedAnswer(index);
  }}
    // Level 3 events on selection
    else if (level === 3) {
      if (option !== "" && option !== undefined) {
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer = option;
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
        (option !== "" && option !== undefined) ? "Answered" : "Not Answered";
      setSelectedAnswer(option);
    }else {
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer = index;
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
        (index !== "" && index !== undefined) ? "Answered" : "Not Answered";
      setSelectedAnswer(index);
    }}
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
      const checkSessionExpiry = () => {
        const loginTimestamp = localStorage.getItem("loginTimestamp");
        if (loginTimestamp) {
          const now = new Date().getTime();
          const loginTime = new Date(loginTimestamp).getTime();
          const elapsedTime = now - loginTime;
          const threeMinutesInMilliseconds = 12 * 60 * 60 * 1000 // 12 hour
  
  
          if (elapsedTime > threeMinutesInMilliseconds) {
            // dispatch(logoutUser());
            localStorage.removeItem("userToken");
            localStorage.removeItem("loginTimestamp");
            history.push("/login");
          }
        }
      };
  
      checkSessionExpiry();
      const interval = setInterval(checkSessionExpiry, 60000); // Check every minute
  
      return () => clearInterval(interval);
    }, [dispatch, history]);

  useEffect(() => {
  
    if (quizStatus?.answer && quizStatus?.answer.length > 0 && Array.isArray(questionStatusResponse)) {
      let updatedQuestionStatusResponse = [...questionStatusResponse];
  
      quizStatus.answer.forEach((matchingAnswer) => {
        const questionIndex = updatedQuestionStatusResponse.findIndex(
          (question) => question.questionid === matchingAnswer.questionid
        );
  
        if (questionIndex !== -1) {
          updatedQuestionStatusResponse[questionIndex].status = matchingAnswer.answer ? 'Answered' : 'Not Answered';
  
          if (Array.isArray(updatedQuestionStatusResponse[questionIndex].subQuestions) && matchingAnswer.clozeanswers) {
            updatedQuestionStatusResponse[questionIndex].subQuestions.forEach((subQuestion, secondLevelIndex) => {
              const matchingSubAnswer = matchingAnswer.clozeanswers.find(
                (clozeItem) => clozeItem.subquestionid === subQuestion.questionid
              );
  
              if (matchingSubAnswer !== undefined) {
                updatedQuestionStatusResponse[questionIndex].subQuestions[secondLevelIndex].status = matchingSubAnswer.subanswer ? 'Answered' : 'Not Answered';
  
                if (Array.isArray(subQuestion.subQuestions)) {
                  subQuestion.subQuestions.forEach((thirdLevelSubQuestion, thirdLevelIndex) => {
                    const matchingThirdLevelAnswer = matchingSubAnswer.clozeanswers?.find(
                      (clozeItem) => clozeItem.subquestionid === thirdLevelSubQuestion.questionid
                    );
  
                    if (matchingThirdLevelAnswer !== undefined) {
                      updatedQuestionStatusResponse[questionIndex].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status = matchingThirdLevelAnswer.subanswer ? 'Answered' : 'Not Answered';
                    } else {
                      updatedQuestionStatusResponse[questionIndex].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status = 'Not Answered';
                    }
                  });
                }
              } else {
                updatedQuestionStatusResponse[questionIndex].subQuestions[secondLevelIndex].status = 'Not Answered';
              }
            });
          }
        }
      });
  
      // Log updated response for debugging
  
      // Set the updated question status response if necessary (e.g., using a state setter)
      // setQuestionStatusResponse(updatedQuestionStatusResponse);
    }
  }, [quizStatus, questionStatusResponse]);
  
  
  const handleNext = (index, action) => {
    setReview(false);
    //Level 1
    if (level === 1) {
      if (questionStatusResponse[count].status !== "Answer Later") {
        if (questionStatusResponse[count].answer !== "" && questionStatusResponse[count].answer.length !== 0|| questionStatusResponse[count]?.uploadedFiles?.length > 0) {
          questionStatusResponse[count].status = "Answered";
        } else {
          questionStatusResponse[count].status = "Not Answered";
        }
      }
      questionStatusResponse[count]?.uploadedFiles?.forEach((fileObject, index) => {
        let fileName = `upload${attemptidd}file${index + 1}`;
        if (!formData.has(fileName)) {
          formData.append(fileName, fileObject);
          setFormData(formData);
        }
      });
      // if (questionStatusResponse[count]?.answer.length !== 0) {
        dispatch(
          saveAnswer(
            attemptidd,
            question.slot,
            question.qtype,
            questionStatusResponse[count]?.answer,
            question.questionid,
            formData.size === 0 ? 0 : 1,
            formData
          )
        );
      // }
      // if (questionStatusResponse[count]?.uploadedFiles?.length > 0) {
      //   dispatch(
      //     saveAnswer(
      //       attemptidd,
      //       question.slot,
      //       question.qtype,
      //       // questionStatusResponse[count]?.answer,
      //       '',
      //       question.questionid,
      //       formData.size === 0 ? 0 : 1,
      //       formData
      //     )
      //   );
      // }
      if (action === "next") {
        setLevel(calculateQuestionLevels(questionStatusResponse, count + 1, 0, 0));
        setFormData(new FormData());
        setCount(count + 1);
      } else if (action === "previous") {
        setLevel(calculateQuestionLevels(questionStatusResponse, count - 1, 0, 0));
        setFormData(new FormData());
        setCount(count - 1);
      } else if (action === "submit") {
        if (questionStatusResponse[count].answer.length === 0) {
          questionStatusResponse[count].status = "Not Answered";
        }
        setIsSubmitted(true);
        setFormData(new FormData());
      }
      dispatch(fetchQuestionStatus(questionStatusResponse));
      setSelectedAnswer("");
      setsecondLevelIndex(0);
      setthirdLevelIndex(0);
    }
    // Level 2
    else if (level === 2) {
      if (questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.status !== "Answer Later") {
        if (
          questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.answer !== "" &&
          questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.answer.length !== 0 ||
          questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.uploadedFiles?.length > 0) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answered";
        } else {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Not Answered";
        }
      }
      for (let j = 0; j < questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles.length; j++) {
        let fileName = `upload${attemptidd}_${
          question?.mqsubquestions[secondLevelIndex]?.submqid || question?.clozeoptions[secondLevelIndex]?.submqid
        }file${j + 1}`;
        if (!formData.has(fileName)) {
          formData.append(fileName, questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles[j]);
          setFormData(formData);
        }
      }
      let allSubQuestionsAnswered = true; // Assume all sub-questions are answered
      for (let i = 0; i < questionStatusResponse[count].subQuestions.length; i++) {
    if (questionStatusResponse[count].subQuestions[i].status === "Answer Later" ||
      questionStatusResponse[count].subQuestions[i].status === "Not Answered" ||
      questionStatusResponse[count].subQuestions[i].status === "Not Visited"
    ) {
      allSubQuestionsAnswered = false; // If any sub-question is not answered, set the flag to false
      break;
    }
  } if (allSubQuestionsAnswered) {
    questionStatusResponse[count].status = "Answered";
  } else {
    questionStatusResponse[count].status = "Not Answered";
  }
      if (action === "next") {
        if (secondLevelIndex + 1 < questionStatusResponse[count].subQuestions.length) {
          setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex + 1, 0));
          setsecondLevelIndex(secondLevelIndex + 1);
        } else {
          dispatch(
            saveAnswerSubQuestions(
              attemptidd,
              question.slot,
              question.qtype,
              questionStatusResponse[count],
              question.questionid,
              formData.size === 0 ? 0 : 1,
              formData
              // isUpload ? selectedAnswer : null
            )
          );
          setFormData(new FormData());
          setLevel(calculateQuestionLevels(questionStatusResponse, count + 1, 0, 0));
          setsecondLevelIndex(0);
          setCount(count + 1);
        }
      } else if (action === "previous") {
        if (secondLevelIndex > 0) {
          setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex - 1, 0));
          setsecondLevelIndex(secondLevelIndex - 1);
        } else {
          dispatch(
            saveAnswerSubQuestions(
              attemptidd,
              question.slot,
              question.qtype,
              questionStatusResponse[count],
              question.questionid,
              formData.size === 0 ? 0 : 1,
              formData
            )
          );
          setLevel(calculateQuestionLevels(questionStatusResponse, count - 1, 0, 0));
          setsecondLevelIndex(0);
          setCount(count - 1);
          setFormData(new FormData());
        }
      } else if (action === "move") {
        dispatch(
          saveAnswerSubQuestions(
            attemptidd,
            question.slot,
            question.qtype,
            questionStatusResponse[count],
            question.questionid,
            formData.size === 0 ? 0 : 1,
            formData
          )
        );
        setthirdLevelIndex(0);
        setsecondLevelIndex(0);
        setFormData(new FormData());
      } else if (action === "submit") {
        setIsSubmitted(true);
        dispatch(
          saveAnswerSubQuestions(
            attemptidd,
            question.slot,
            question.qtype,
            questionStatusResponse[count],
            question.questionid,
            formData.size === 0 ? 0 : 1,
            formData
          )
        );
        setFormData(new FormData());
      }
      dispatch(fetchQuestionStatus(questionStatusResponse));
    }
    if (level === 3) {
      if (
        questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.status !==
        "Answer Later"
      ) {
        if (
          questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer !== "" &&
          questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer.length !== 0 ||
          questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.uploadedFiles?.length > 0
        ) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
            "Answered";
        } else {
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status =
            "Not Answered";
        }
      }
      for (
        let j = 0;
        j <
        questionStatusResponse[count].subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex].uploadedFiles
          .length;
        j++
      ) {
        let fileName = `upload${attemptidd}_${
          (question.mqsubquestions[secondLevelIndex] || question.clozeoptions[secondLevelIndex]).children[
            thirdLevelIndex
          ].submqid
        }file${j + 1}`;
        if (!formData.has(fileName)) {
          formData.append(
            fileName,
            questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles[j]
          );
          setFormData(formData);
        }
      }
      for (let i = 0; i < questionStatusResponse[count].subQuestions[secondLevelIndex]?.subQuestions?.length; i++) {
        if (questionStatusResponse[count].subQuestions[secondLevelIndex]?.subQuestions[i]?.uploadedFiles.length !== 0) {
        }

        if (questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[i].status === "Answer Later") {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answer Later";
          questionStatusResponse[count].status = "Answer Later";
          break;
        } else if (
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[i].status === "Not Answered" ||
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[i].status === "Not Visited"
        ) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Not Answered";
          questionStatusResponse[count].status = "Not Answered";
          break;
        } else if (questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[i].status === "Answered") {
          questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answered";
          questionStatusResponse[count].status = "Answered";
          continue;
        }
      }
      if (action === "next") {
        if (thirdLevelIndex + 1 < questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions.length) {
          setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex, thirdLevelIndex + 1));
          setthirdLevelIndex(thirdLevelIndex + 1);
        } else {
          if (secondLevelIndex + 1 < questionStatusResponse[count].subQuestions.length) {
            setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex + 1, 0));
            setsecondLevelIndex(secondLevelIndex + 1);
            setthirdLevelIndex(0);
          } else {
            dispatch(
              saveAnswerSubQuestions(
                attemptidd,
                question.slot,
                question.qtype,
                questionStatusResponse[count],
                question.questionid,
                formData.size === 0 ? 0 : 1,
                formData
              )
            );
            setFormData(new FormData());
            setLevel(calculateQuestionLevels(questionStatusResponse, count + 1, 0, 0));
            setCount(count + 1);
            setthirdLevelIndex(0);
            setsecondLevelIndex(0);
          }
        }
      } else if (action === "previous") {
        if (thirdLevelIndex > 0) {
          setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex, thirdLevelIndex - 1));
          setthirdLevelIndex(thirdLevelIndex - 1);
        } else {
          if (secondLevelIndex > 0) {
            setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex - 1, 0));
            setsecondLevelIndex(secondLevelIndex - 1);
            setthirdLevelIndex(0);
          } else {
            dispatch(
              saveAnswerSubQuestions(
                attemptidd,
                question.slot,
                question.qtype,
                questionStatusResponse[count],
                question.questionid,
                formData.size === 0 ? 0 : 1,
                formData
              )
            );
            setLevel(calculateQuestionLevels(questionStatusResponse, count - 1, 0, 0));
            setCount(count - 1);
            setFormData(new FormData());
            setthirdLevelIndex(0);
            setsecondLevelIndex(0);
          }
        }
      } else if (action === "move") {
        setFormData(new FormData());
        dispatch(
          saveAnswerSubQuestions(
            attemptidd,
            question.slot,
            question.qtype,
            questionStatusResponse[count],
            question.questionid,
            formData.size === 0 ? 0 : 1,
            formData
          )
        );
        setCount(0); // Reset to the first question
        setsecondLevelIndex(0); // Reset to the first sub-question of the first question
        setthirdLevelIndex(0); // Reset to the first sub-sub-question of the first question
      } else if (action === "submit") {
        setIsSubmitted(true);
        setFormData(new FormData());
        dispatch(
          saveAnswerSubQuestions(
            attemptidd,
            question.slot,
            question.qtype,
            questionStatusResponse[count],
            question.questionid,
            formData.size === 0 ? 0 : 1,
            formData
          )
        );
      }
      dispatch(fetchQuestionStatus(questionStatusResponse));
    }
  };
  const handleAlertModal = (action) => {
    if (action === "show") {
      setAlertModal(true);
      return false;
    } else if (action === "cancel") {
      setAlertModal(false);
    } else if (action === "exit") {
      setAlertModal(false);
      setOpenAlert(false);
      setSubmit("submit");
      // HANDLE_SUBMIT_BUTTON();
      // history.goBack();
    }
  };
  const [clearResponse, setClearResponse] = useState(false);
  return attemptidd && questionStatusResponse.length > 0 ? (
    <Grid>
      {question?.qtype === "multianswer" ? (
        <MultiAnswer
          attemptidd={attemptidd}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          count={count}
          secondLevelIndex={secondLevelIndex}
          setsecondLevelIndex={setsecondLevelIndex}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
          setLevel={setLevel}
          handleNext={handleNext}
          editorData={editorData}
          setEditorData={setEditorData}
          quiz={quiz}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question?.qtype === "multiquestion" ? (
        <MultiQuestion
          attemptidd={attemptidd}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          count={count}
          secondLevelIndex={secondLevelIndex}
          setsecondLevelIndex={setsecondLevelIndex}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
          setLevel={setLevel}
          handleNext={handleNext}
          editorData={editorData}
          setEditorData={setEditorData}
          quiz={quiz}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question?.qtype === "essay" ? (
        <EssayNew
          attemptidd={attemptidd}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          count={count}
          show={true}
          level={level}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          secondLevelIndex={secondLevelIndex}
          editorData={editorData}
          setEditorData={setEditorData}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      {question?.qtype === "shortanswer" ? (
        <ShortAnswerNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          count={count}
          show={true}
          level={level}
          quiz={quiz}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question?.qtype === "match" ? (
        <MatchNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          count={count}
          show={true}
          level={level}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}

      {question?.qtype === "truefalse" ? (
        <TrueFalseNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          show={true}
          count={count}
          level={level}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      {question?.qtype === "multichoice" ? (
        <MultiChoiceNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question}
          handleChange={handleChange}
          count={count}
          show={true}
          type="multichoice"
          level={level}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      <Grid container sx={{ marginTop: "3%", paddingBottom: "50px" }}>
        <Grid item xs={1.5} md={1.4} />
        <Grid item xs={10} md={4}>
          <Checkbox
            // defaultChecked={
            //   questionStatusResponse[count]?.status === "For Review" ? true : false
            // }
            checked={review}
            onChange={handleReview}
            sx={{
              color: "#634699",
              "&.Mui-checked": {
                color: "#634699",
              },
            }}
          />
          <label
            style={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "14px",
              lineHeight: "140%",
            }}
          >
            Answer Later
          </label>
        </Grid>
        <Grid item xs={1} md={0.2} />
        <Grid item xs={10} md={4}>
          <Button
            onClick={() => {
             setEditorData("");
              handleChange("", "");
              setClearResponse(true);
              setTimeout(() => setClearResponse(false), 0);
              localStorage.removeItem(`editorData_${question?.questionid || question?.submqid}`);
            }}
            sx={{
              background: "#FFFFFF",
              color: "#634699",
              borderRadius: "5px",
              textTransform: "none",
              border: "1px solid #634699",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#634699",
                boxShadow: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontStyle: "regular",
                fontSize: "14px",
                lineHeight: "140%",
              }}
            >
              Clear Response
            </Typography>
          </Button>
          <Button
            className={CLASSES.root}
            sx={{
              background: "#634699",
              color: "#FFFFFF",
              borderRadius: "5px",
              textTransform: "none",
              marginLeft: "10px",
              boxShadow: "none",
              "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
            }}
            disabled={count > 0 ? false : true}
            onClick={() => {
              handleNext("", "previous");
            }}
          >
            {" "}
            <ArrowLeftIcon sx={{ fontSize: 20 }} />
            <Typography
              sx={{
                fontWeight: 400,
                fontStyle: "regular",
                fontSize: "14px",
                lineHeight: "140%",
              }}
            >
              Prev
            </Typography>
          </Button>
          <Button
            onClick={() => {
              if (
                checkFinalQuestion(level, questionStatusResponse, secondLevelIndex, thirdLevelIndex, count) === "Next"
              ) {
                handleNext("", "next");
              } else {
                setSubmit("submit");
                handleNext("", "submit");
              }
            }}
            sx={{
              background: "#634699",
              color: "#FFFFFF",
              borderRadius: "5px",
              boxShadow: "none",
              "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
              textTransform: "none",
              marginLeft: "10px",
              textAlign: "center",
            }}
          >
            {" "}
            <Typography
              sx={{
                fontWeight: 400,
                fontStyle: "regular",
                fontSize: "14px",
                lineHeight: "140%",
              }}
            >
              {typeof questionStatusResponse[count]?.answer === "number" &&
              checkFinalQuestion(level, questionStatusResponse, secondLevelIndex, thirdLevelIndex, count) === "Next"
                ? "Save And Next"
                : checkFinalQuestion(level, questionStatusResponse, secondLevelIndex, thirdLevelIndex, count)}
            </Typography>
            <ArrowRightIcon sx={{ fontSize: 20 }} />
          </Button>
        </Grid>
        <QuestionSideBar
          quiz={quiz}
          setCount={setCount}
          handleNext={handleNext}
          count={count}
          open={open}
          setOpen={setOpen}
          submit={submit}
          setSubmit={setSubmit}
          setLevel={setLevel}
          setthirdLevelIndex={setthirdLevelIndex}
          setsecondLevelIndex={setsecondLevelIndex}
        />
      </Grid>
      <WarningAlert open={showAlertModal} handleAlert={handleAlertModal} />
      <Prompt
        when={openAlert}
        // message='Are you sure you want to leave this test?'
        message={() => handleAlertModal("show")}
      />
    </Grid>
  ) : (
    <Grid sx={{ position: "absolute", left: "45%", top: "50%" }}>
      <Lottie width={100} height={100} options={defaultOptions} />
    </Grid>
  );
};
export default QuestionScreenNew;
