/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/actions/MyTestPackageAction.js
 * This JS file is intended to provide the action methods with respect to enrolled test packages.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
  getEnrolledTests,
  getTestDetails,
  getCourseList,
  getQuizList,
  getQuizAttempts,
} from "../api/MyTestPackageApi";

export const fetchEnrolledTests = (userid) => async (dispatch) => {
  try {
    const getTests = await getEnrolledTests(userid);
    dispatch({ type: "GET_TESTS", payload: getTests });
  } catch (error) {
    return error;
  }
};

export const fetchTestDetails = (courseid,userid) => async (dispatch) => {
  try {
    if (courseid) {
      const getContents = await getTestDetails(courseid,userid);
      dispatch({ type: "GET_TEST_DETAILS", payload: getContents });
    } else {
      dispatch({ type: "RESET_TEST_DETAILS", payload: "" });
    }
  } catch (error) {
    return error;
  }
};

export const fetchCourseList = (courseid) => async (dispatch) => {
  try {
    if (courseid) {
      const getCourses = await getCourseList(courseid);
      dispatch({ type: "GET_COURSES", payload: getCourses });
    } else {
      dispatch({ type: "RESET_COURSES", payload: "" });
    }
  } catch (error) {
    return error;
  }
};

export const fetchQuizDetails = () => async (dispatch) => {
  try {
    const getQuizzes = await getQuizList();
    dispatch({ type: "GET_QUIZZES", payload: getQuizzes });
  } catch (error) {
    return error;
  }
};

export const fetchQuizAttempt = (userid,quizid) => async (dispatch) => {
  try {
    const getAttempts = await getQuizAttempts(userid,quizid);
    dispatch({ type: "GET_QUIZ_ATTEMPT_DETAIL", payload: getAttempts });
  } catch (error) {
    return error;
  }
};
