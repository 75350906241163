/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/actions/TestAttemptAction.js
 * This JS file is intended to provide the action methods with respect to TestAttempt
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
  startQuiz,
  questionList,
  saveChange,
  finishQuizAttempt,
  reviewQuizAttempt,
  getQuizStatus,
  saveChangeSubQuestions
} from "../api/TestAttemptApi";

export const fetchQuestionStatus = (questionStatus) => async (dispatch) => {
  try {
    dispatch({ type: "QUESTION_STATUS", payload: questionStatus });
  } catch (error) {
    return error;
  }
};
export const fetchTrueorfalseStatus = (tofStatus) => async (dispatch) => {
  try {
    dispatch({ type: "TRUEORFALSE_STATUS", payload: tofStatus });
  } catch (error) {
    return error;
  }
};
export const startQuizAttempt = (quizid) => async (dispatch) => {
  try {
    const sendStartRequest = await startQuiz(quizid);
    dispatch({ type: "START_ATTEMPT", payload: sendStartRequest });
  } catch (error) {
    return error;
  }
};

export const changeTestStatus = (status) => async (dispatch) => {
  try {
    dispatch({ type: "IS_TEST_STARTED", payload: status });
  } catch (error) {
    return error;
  }
};

export const fetchQuestionList = (quizid, attemptid) => async (dispatch) => {
  try {
    const list = await questionList(quizid, attemptid);
    dispatch({ type: "FETCH_QUESTION_LIST", payload: list });
  } catch (error) {
    return error;
  }
};

export const saveAnswer =
  (attemptid, slot, qtype, answer, questionid, isUpload, uploadedFiles) =>
    async (dispatch) => {
      try {
        const save = await saveChange(
          attemptid,
          slot,
          qtype,
          answer,
          questionid,
          isUpload,
          uploadedFiles
        );
        dispatch({ type: "SAVE_ANSWER", payload: save });
      } catch (error) {
        return error;
      }
    };

export const finishAttempt = (attemptid) => async (dispatch) => {
  try {
    const finish = await finishQuizAttempt(attemptid);
    dispatch({ type: "FINISH_ATTEMPT", payload: finish });
  } catch (error) {
    return error;
  }
};

export const reviewTestAttempt = (attemptid) => async (dispatch) => {
  try {
    const review = await reviewQuizAttempt(attemptid);
    dispatch({ type: "REVIEW_ATTEMPT", payload: review });
  } catch (error) {
    return error;
  }
};

export const setAnswersArray = (answers) => async (dispatch) => {
  try {
    dispatch({ type: "SET_ANSWERS", payload: answers });
  } catch (error) {
    return error;
  }
};

export const resetAnswers = () => async (dispatch) => {
  try {
    dispatch({ type: "RESET_ANSWERS", payload: [] });
  } catch (error) {
    return error;
  }
};
export const resetStartAttempt = () => async (dispatch) => {
  try {
    dispatch({ type: "RESET_START_ATTEMPT", payload: [] });
  } catch (error) {
    return error;
  }
};
export const resetGetQuizStatus = () => async (dispatch) => {
  try {
    dispatch({ type: "RESET_GET_QUIZ_STATUS", payload: [] });
  } catch (error) {
    return error;
  }
};

export const uploadedFiles = (list) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOADFILELIST", payload: list });
  } catch (error) {
    return error;
  }
};

export const fetchQuizStatus = (userid, quizid) => async (dispatch) => {
  try {
    const status = await getQuizStatus(userid, quizid);
    dispatch({ type: "GET_QUIZ_STATUS", payload: status });
  } catch (error) {
    return error;
  }
};
export const saveAnswerSubQuestions =
  (attemptid, slot, qtype, answer, questionid, isUpload, uploadedFiles) =>
    async (dispatch) => {
      try {
        const save = await saveChangeSubQuestions(
          attemptid,
          slot,
          qtype,
          answer,
          questionid,
          isUpload,
          uploadedFiles
        );
        dispatch({ type: "SAVE_ANSWER", payload: save });
      } catch (error) {
        return error;
      }
    };
