/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestPackageContent.js
 * This JS file is for showing the contents of each test packages.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  LinearProgress,
  Box,
  linearProgressClasses,
  Card,
  CardContent,
  IconButton,
  Collapse,
} from "@mui/material";
import { ExpandIcon, TestPackageListIcon } from "../../../../assets/icons";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  fetchTestDetails,
  fetchCourseList,
  fetchEnrolledTests,
} from "../../../../webservice/students/actions/MyTestPackageAction";
import COMPLETEDTESTS from "./CompletedTests";
import INPROGRESS from "./InProgress";
import PENDINGTESTS from "./PendingTests";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));

const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));

const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const USE_STYLES = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "3px",
    borderRadius: "5px",
    width: 26,
    height: 26,
  },
  gradeIcon: {
    "& .MuiSvgIcon-root": {
      fill: "#E2DDEC",
    },
  },
  linearProgress: {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EFF1F3",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#634699",
    },
  },
});

const TESTPACKAGECONTENT = () => {
  const params = useParams();
  const courseid = params.subject;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { contentList } = useSelector((state) => state.mytestpackagestate);
  const { courseList } = useSelector((state) => state.mytestpackagestate);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  // const { userid } = useSelector((state) => state.authState);
  const userid = JSON.parse(localStorage.getItem("userid"));
  useEffect(() => {
    dispatch(fetchTestDetails(courseid, userid));
    dispatch(fetchCourseList(courseid));
    if (userid) dispatch(fetchEnrolledTests(userid));
    return () => {
      dispatch(fetchTestDetails(""));
      dispatch(fetchCourseList(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = USE_STYLES();
  const [expanded, setExpanded] = React.useState(-1);
  const HANDLE_EXPAND_CLICK = (index, id) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
  };
  const getSearchResult = () => {
    let list = contentList.filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return val;
      }
    });
    if (list.length) return list;
    else return [{ modules: 0 }];
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "2rem",
                      lineHeight: "46px",
                      fontFamily:"Helvetica",
                      fontStyle:"normal"
                    }}
                  >
                    My Test Packages
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} sx={{marginTop:"-10px",marginBottom:"-10px"}}>
        <SEARCH>
          <SEARCH_ICON_WRAPPER>
            <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
          </SEARCH_ICON_WRAPPER>
          <STYLED_INPUT_BASE
            type="text"
            placeholder="Search Topics"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />{" "}
        </SEARCH>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sx={{marginBottom:"10px"}}>
            {courseList && courseList.courses.length
              ? courseList.courses.map((course) => (
                  <Grid container key={course.id}>
                    <Grid item xs={12} md={8}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontStyle: "regular",
                          color: "#634699",
                          fontSize: 20,
                        }}
                      >
                        {course.displayname}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} md={4} /> */}
                    <Grid item xs={12} md={4}>
                      {testList && testList.length
                        ? testList.map(
                            (test) =>
                              test.id === course.id && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  key={test.id}
                                >
                                  <Box width="100%" mr={1}>
                                    <LinearProgress
                                      className={classes.linearProgress}
                                      variant="determinate"
                                      value={test.progress}
                                    />
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`${Math.round(test.progress)}%`}
                                  </Typography>
                                </Box>
                              )
                          )
                        : null}
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
          <Grid item xs={12}>
            <Scrollbars style={{ height: 600 }}>
              {contentList && contentList.length
                ? getSearchResult().map(
                    (contentData, index) =>
                      contentData.section !== 0 &&
                      (contentData.modules &&
                      contentData.modules.length !== 0 ? (
                        <Grid container key={index}>
                          <Grid item xs={12}>
                            <Card
                              sx={{  marginTop: "10px",padding:"10px" }}
                            >
                              {/* <CardContent> */}
                                <Grid container>
                                  <Grid item xs={1}>
                                    <TestPackageListIcon
                                      className={classes.icon}
                                    />
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        fontStyle: "regular",
                                        lineHeight: "22px",
                                        color: "#634699",
                                      }}
                                    >
                                      {contentData.name}
                                      <br />
                                      <span
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          fontStyle: "regular",
                                          lineHeight: "22px",
                                          color: "gray",
                                        }}
                                      >
                                        {contentData.summary.replace(
                                          /<[^>]+>/g,
                                          ""
                                        )}
                                      </span>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <EXPAND_MORE
                                        expand={expanded === index}
                                        onClick={() =>
                                          HANDLE_EXPAND_CLICK(index)
                                        }
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                      >
                                        <ExpandIcon sx={{fontSize:16}} />
                                      </EXPAND_MORE>
                                    </Box>
                                  </Grid>
                                </Grid>
                              {/* </CardContent> */}
                            </Card>
                          </Grid>
                          <Grid item xs={12}>
                            {contentData.modules.length ? (
                              contentData.modules.map((module, moduleIndex) => (
                                <Collapse
                                  key={`collapse-${moduleIndex}`}
                                  in={expanded === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {module.modname === "quiz" && (
                                    <Card
                                      sx={{
                                        backgroundColor: module.locked ? "white" : "#E2DDEC",
                                        marginBottom: "10px",padding:"10px"
                                      }}
                                    >
                                      {/* <CardContent> */}
                                        {module &&
                                        module.attemptstatus === "finished" ? (
                                          <COMPLETEDTESTS module={module} />
                                        ) : module.attemptstatus ===
                                          "notstarted" ? (
                                          <PENDINGTESTS module={module} />
                                        ) : module.attemptstatus ===
                                          "inprogress" ? (
                                          <INPROGRESS module={module} />
                                        ) : null}
                                      {/* </CardContent> */}
                                    </Card>
                                  )}
                                </Collapse>
                              ))
                            ) : (
                              <Collapse
                                in={expanded === index}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Card
                                  sx={{
                                    backgroundColor: "#E2DDEC",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        fontStyle: "regular",
                                        lineHeight: "22px",
                                        color: "#634699",
                                        textAlign: "center",
                                      }}
                                    >
                                      No Tests Found
                                      <br />
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Collapse>
                            )}
                          </Grid>
                        </Grid>
                      ) : contentData.modules === 0 ? (
                        <Grid container key={index}>
                          <Grid item xs={12}>
                            <Card
                              sx={{ marginBottom: "15px", marginTop: "15px" }}
                            >
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12} textAlign="center">
                                    <Typography
                                      sx={{
                                        color: "#634699",
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "140%",
                                      }}
                                    >
                                      No Result Found..!
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      ) : null)
                  )
                : null}
            </Scrollbars>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TESTPACKAGECONTENT;
