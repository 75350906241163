import { Box, Button, Card, CardContent, Collapse, Divider, Grid, IconButton, List, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React, { Fragment } from "react";
import Scrollbars from "react-custom-scrollbars-2";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteIcon, Emptycart, ExpandIcon, TestPackageListIcon } from "../../../assets/icons";
import { useSelector, useDispatch } from "react-redux";
import { fetchCourseContents, removeFromCartAction } from "../../../webservice/students/actions/TestPackageListAction";

import { useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../../assets/images/collapse-testicon.png";
import Pic from "../../../assets/images/collapse-sessionicon.png";

const USE_STYLES = makeStyles({
  OrderConfirmationContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",marginTop: "5px",
  },
  carticon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "rgb(99 70 153 / 40%)",
    },
  },
  card: {
    margin: "10px",
    "& .MuiCardHeader-title": {
      color: "#634699",
      fontWeight: "bold",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  test: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px",
  },
  session: {
    backgroundImage: `url(${Pic})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px",
  },
  select: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:after": {
      borderColor: "transparent",
    },
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      fontSize: "2rem",
    },
    "& .MuiInputBase-formControl": {
      textAlign: "center",
      color: "#634699",
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px rgb(0 0 0 / 2%)",
      borderRadius: "5px",
    },
  },
});
const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CartDetails = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  const [expanded, setExpanded] = React.useState(-1);
  // const [price, setPrice] = useState(0);
  const [hover, sethover] = useState(false);
  const [showSyllabus, setShowSyllabus] = useState(false);
  const discount = 0;
  const cartItems = useSelector((state) => state.testPackageListReducer.cartItems);
  const { userid } = useSelector((state) => state.authState);
  const { coursecontents } = useSelector((state) => state.testPackageListReducer);
  // const cartItemsKey = `cartItems_${userid}`;
  // const cartItems = JSON.parse(localStorage.getItem(cartItemsKey)) || [];
  const dispatch = useDispatch();

  const handleExpandClick = (index, id) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
    dispatch(fetchCourseContents(id, userid));

  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCartAction(itemId));
  };
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  const totalCost = cartItems.reduce((acc, product) => {
    if (product.paypalenrollments && product.paypalenrollments.length > 0) {
      return acc + parseFloat(product.paypalenrollments[0].cost);
    }
    return acc;
  }, 0);
  let quizCount = 0;
  let assignCount = 0;
  const filteredContents = coursecontents?.filter((x) => x.section !== 0 && x.modules && x.modules.length !== 0);
  return (
    <div>
      <Scrollbars style={{ height: " 55vh " }}>
        <Box px={2}>
          <Grid item xs={12}>
            <Button
              sx={{
                color: "#634699",
                fontWeight: "bold",
                textTransform: "none",
              }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={11} md={11}>
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "30px",
                  lineHeight: "56px",
                }}
              >
                Cart Details
              </Typography>
            </Grid>
          </Grid>
          {cartItems.length === 0 ? (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Emptycart sx={{ fontSize: "14px", width: "10em", height: "10em" }} />
                <br />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    color: "#8365BB",
                    fontWeight: 700,
                    fontSize: "45.3167px",
                    lineHeight: "140%",
                  }}
                >
                  {" "}
                  Uh Oh !
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    color: "#98A0AB",
                    fontWeight: 400,
                    fontSize: "20.8432px",
                    lineHeight: "140%",
                  }}
                >
                  {" "}
                  Looks like your study cart is empty! <br />
                  Better start loading up on those test <br />
                  packages to ace your exams.
                </Typography>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Button
                  onClick={() => history.push("/test-packages")}
                  variant="contained"
                  sx={{
                    backgroundColor: "#634699",
                    textTransform: "none",
                    marginBottom: "20px",
                    "&:hover": { backgroundColor: "#634699" },
                  }}
                >
                  Go to Test Packages
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {/* <ul> */}
              <Grid container sx={{ marginBottom: "20px" }} spacing={2}>
                <Grid item xs={12} md={8}>
                  {cartItems.map((item, indexcart) => (
                    <Fragment key={item.id}>
                      <Grid item xs={12} key={item.id}>
                        <Card sx={{ marginBottom: "10px",padding:"12px" }}>
                          {/* <CardContent> */}
                            <Grid container>
                              <Grid item xs={2} md={1}>
                                <TestPackageListIcon className={CLASSES.icon} />
                              </Grid>

                              <Grid item xs={7} md={7}>
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "14.5px",
                                    lineHeight: "33.4px",
                                  }}
                                >
                                  {item.displayname}
                                  <span
                                    style={{
                                      color: "#718096",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      lineHeight: "16.8px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {item.customfields[0].name === "Grades" ? item.customfields[0].value : ""}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      color: "#C4C4C4",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "16.8px",
                                    }}
                                  >
                                    {item.summary.replace(/<[^>]+>/g, "")}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={3} md={1.5}>
                                {item?.paypalenrollments.map((pricedata, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      color: "#634699",
                                      fontWeight: 700,
                                      fontSize: "14.5px",
                                      lineHeight: "33.4px",
                                    }}
                                  >
                                    {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: pricedata?.currency,
                                      maximumSignificantDigits: 3,
                                    }).format(pricedata?.cost)}
                                  </Typography>
                                ))}
                              </Grid>

                              <Grid item xs={2} md={1.5}>
                                <IconButton onClick={() => handleRemoveItem(item.id)}>
                                  <DeleteIcon sx={{fontSize:20}}/>
                                </IconButton>
                              </Grid>
                              <Grid item xs={3} md={1}>
                                <EXPAND_MORE
                                  expand={expanded === indexcart}
                                  onClick={() => handleExpandClick(indexcart, item.id)}
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                >
                                  <ExpandIcon sx={{ fontSize: 19 }} />
                                </EXPAND_MORE>
                              </Grid>
                            </Grid>
                          {/* </CardContent> */}
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        {coursecontents && coursecontents.length
                          ? coursecontents.map((content, indexcontent) => {
                            if (content && content.modules && content.modules.length) {
                              let quizlist = content.modules.filter((e) => e.modname === "quiz");
                              if (quizlist.length) {
                                quizCount = quizCount + quizlist.length;
                              }
                              let assignlist = content.modules.filter((e) => e.modname === "assign");
                              if (assignlist.length) {
                                assignCount = assignCount + assignlist.length;
                              }
                            }
                            return coursecontents.length - 1 === indexcontent ? (
                              <Collapse in={expanded === indexcart} key={indexcart}>
                                <Card sx={{ marginBottom: "10px" }}>
                                  <CardContent>
                                    <Grid item xs={12}>
                                      <Grid container>
                                        <Grid item md={1}></Grid>
                                        <Grid item md={5} xs={12} className={CLASSES.session}>
                                          <span
                                            style={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "106.667px",
                                              lineHeight: "160px",
                                            }}
                                          >
                                            {filteredContents.length}
                                          </span>
                                          <Typography
                                            sx={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "12px",
                                              lineHeight: "150%",
                                            }}
                                          >
                                            Sessions
                                          </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12} className={CLASSES.test}>
                                          <span
                                            style={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "106.667px",
                                              lineHeight: "160px",
                                            }}
                                          >
                                            {quizCount}
                                          </span>
                                          <Typography
                                            sx={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "12px",
                                              lineHeight: "150%",
                                            }}
                                          >
                                            Tests
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Grid container>
                                        <Grid item xs={9.5} sx={{ marginTop: "22px" }}>
                                          {showSyllabus && (
                                            <div>
                                              {filteredContents.map((item) => (
                                                <Grid>
                                                  <List key={item.id}>
                                                    <Typography
                                                      sx={{
                                                        color: "#634699",
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "140%",
                                                        marginLeft: "50px",
                                                      }}
                                                    >
                                                      {item.name}
                                                    </Typography>
                                                  </List>
                                                </Grid>
                                              ))}
                                            </div>
                                          )}
                                        </Grid>
                                        <Grid item xs={2.5} sx={{ paddingTop: showSyllabus ? "120px" : "" }}>
                                          <Grid container direction="column">
                                            <Grid item>
                                              <Typography
                                                sx={{
                                                  color: "#634699",
                                                  fontWeight: 700,
                                                  fontSize: "1.25vw",
                                                  lineHeight: "150%",
                                                }}
                                              >
                                                {item.verificationstatus}
                                              </Typography>
                                            </Grid>

                                            {item.cancustomize === 1 && filteredContents.length !== 0 ? (
                                              <Grid item>
                                                <Button
                                                  component={Link}
                                                  to={`/test-packages/${item.id}`}
                                                  onMouseOver={() => sethover(true)}
                                                  onMouseOut={() => sethover(false)}
                                                  variant="outlined"
                                                  fullWidth
                                                  sx={{
                                                    marginTop: 1,
                                                    color: "#fff",
                                                    borderColor: "#634699",
                                                    textTransform: "none",
                                                    backgroundColor: "#634699",
                                                    "&:hover": {
                                                      backgroundColor: "#7C55C5",
                                                      borderColor: "#634699",
                                                    },
                                                  }}
                                                >
                                                  {hover ? "Customise" : "Customise"}
                                                </Button>
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            textTransform: "none",
                                            background: "rgba(224, 218, 235, 0.74)",
                                            color: "#634699",
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            padding: "8px",
                                            marginTop: "20px",
                                            borderRadius: "4px",
                                            "&:hover": { background: "rgba(224, 218, 235, 0.74)", boxShadow: "none" },
                                          }}
                                          fullWidth
                                          onClick={() => setShowSyllabus(!showSyllabus)}
                                        >
                                          {showSyllabus ? "Show Less" : "Show Test Syllabus"}
                                        </Button>
                                      </>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Collapse>
                            ) : null;
                          })
                          : null}
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>

                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Fragment>
                      <Grid item xs={12}>
                        <Card>
                          {cartItems.map((item) => (
                            <Grid container>
                              <Grid item xs={6} p={1} sx={{paddingTop:"40px"}}>
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    // lineHeight: "33.6px",
                                  }}
                                >
                                  {/* Total prize */}
                                  {item.displayname}
                                  <span style={{ color: "#634699" }}></span>
                                </Typography>
                              </Grid>
                              <Grid item container xs={6} display="flex" justifyContent="flex-end" p={3}>
                                {item?.paypalenrollments.map((pricedata, index) => (
                                  <Typography
                                    sx={{
                                      color: "#634699",
                                      fontWeight: 700,
                                      fontSize: "17px",
                                      lineHeight: "56px",
                                    }}
                                  >
                                    {pricedata?.cost}${/* {totalCost}$ */}
                                    {/* {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: pricedata?.currency,
                                      maximumSignificantDigits: 3,
                                    }).format(pricedata?.cost)} */}
                                    {/* {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(price)} */}
                                  </Typography>
                                ))}
                              </Grid>
                            </Grid>
                          ))}
                          <Grid container>
                            <Grid item xs={6} p={3}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontWeight: 400,
                                  fontSize: "17px",
                                  lineHeight: "33.6px",
                                }}
                              >
                                Care
                                <br />
                                <span
                                  style={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "17px",
                                    lineHeight: "33.6px",
                                  }}
                                >
                                  Discount
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item container xs={6} display="flex" justifyContent="flex-end" p={3}>
                              <Typography
                                sx={{
                                  color: "#634699",
                                  fontWeight: 700,
                                  fontSize: "17px",
                                  lineHeight: "56px",
                                }}
                              >
                                {discount}$
                                {/* {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(discount)} */}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider variant="middle" sx={{ bgcolor: "#634699" }} />
                          <Grid container>
                            <Grid item xs={6} p={3}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontWeight: 400,
                                  fontSize: "17px",
                                  lineHeight: "33.6px",
                                }}
                              >
                                Total
                              </Typography>
                            </Grid>
                            <Grid item container xs={6} display="flex" justifyContent="flex-end" p={3}>
                              <Typography
                                sx={{
                                  color: "#634699",
                                  fontWeight: 700,
                                  fontSize: "17px",
                                  lineHeight: "56px",
                                }}
                              >
                                {/* 20 $ */}
                                {/* {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(price + discount)} */}
                                {totalCost + discount}$
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                        <Button
                          // onClick={() =>
                          //   history.push(
                          //     `/test-packages/checkout/${courseid}/${
                          //       price + discount
                          //     }`
                          //   )
                          // }
                          variant="contained"
                          fullWidth
                          sx={{
                            marginTop: 1,
                            backgroundColor: "#634699",
                            textTransform: "none",
                            "&:hover": { backgroundColor: "#7C55C5" },

                            borderRadius: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "16px",
                              lineHeight: "140%",

                              color: "#F1F1F1",
                            }}
                          >
                            Checkout
                          </Typography>
                        </Button>
                        <Button
                          // onClick={customizeHandler}
                          variant="outlined"
                          fullWidth
                          sx={{
                            marginTop: 1,
                            borderColor: "#634699",
                            color: "#634699",
                            textTransform: "none",
                            border: "1px solid #634699",
                            borderRadius: "3px",
                            "&:hover": { borderColor: "#7C55C5" },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "16px",
                              lineHeight: "140%",
                              color: "#634699",
                            }}
                          >
                            Customize
                          </Typography>
                        </Button>
                      </Grid>
                    </Fragment>
                    {/* // )
            );
          })
        : null} */}
                  </Grid>
                </Grid>
              </Grid>

              {/* </ul> */}
            </div>
          )}
        </Box>
      </Scrollbars>
    </div>
  );
};

export default CartDetails;
