import { Box, CardMedia, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import parse from "html-react-parser";
import MultiChoiceNew from "./multiChoiceNew";
import TrueFalseNew from "./TrueFalseNew";
import MatchNew from "./MatchNew";
import ShortAnswerNew from "./ShortAnswerNew";
import EssayNew from "./essayNew";
import SubQuestionLevelcounts from "./SubQuestionLevelcounts";
import Text from "./text";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import { useEffect } from "react";
import { calculateQuestionLevels } from "../../../../utils/AppConstants";
import { alphabets } from "../../../../utils/AppConstants";
import { colorPicker } from "../../../../utils/AppConstants";
const MultiQuestion = ({
  question,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  secondLevelIndex,
  setsecondLevelIndex,
  attemptidd,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,
  setLevel,
  handleNext,
  editorData,
  setEditorData,quizStatus,quiz, clearResponse,
  setClearResponse
}) => {
  const dispatch = useDispatch();

  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialog(true);
    setImageSrc(src);
  };

  return (
  
    <div>
      <Grid container sx={{ marginTop: "2%" }}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.5} md={7.5}>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            {" "}
            {question.slot ? question.slot : ""}
          </Typography>{" "}
          {question?.clozemainquestiontext !== "&nbsp;" ? (
            <Typography
              sx={{
                fontWeight: 400,
                fontStyle: "regular",
                color: "#000000",
                fontSize: "16px",
                lineHeight: "150%",
                paddingBottom: "30px",
              }}
            >
              
              {parse(question?.clozemainquestiontext, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                  src={domNode.attribs.src}
                  style={{
                    maxWidth: '65%', // Ensures the image fits within the parent container
                    maxHeight: '65%', // Ensures the image fits within the parent container
                    objectFit: 'contain', // Maintains the aspect ratio of the image
                    cursor: 'pointer'
                  }}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
            </Typography>
          ) : (
            ""
          )}
          {/* <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "5px",
            }}
          >
            Read the above passage and answer the following questions
          </Typography> */}
          <br />
          <Grid item xs={1.5} md={1.5} />
          <Grid item xs={5.2} md={7.3}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {(question?.mqsubquestions).map((i, index) => {
                return (
                  <Box
                    key={index}
                    onClick={() => {
                      handleNext(index);
                      setLevel(calculateQuestionLevels(questionStatusResponse, count, index, 0));
                      setsecondLevelIndex(index);
                      setthirdLevelIndex(0);
                    }}
                    sx={{
                      background: colorPicker(
                        questionStatusResponse[count]?.subQuestions[index]?.status,
                        secondLevelIndex,
                        index
                      )[0],
                      color: colorPicker(
                        questionStatusResponse[count]?.subQuestions[index]?.status,
                        secondLevelIndex,
                        index
                      )[2],
                      borderRadius: "3px ",
                      textTransform: "none",
                      padding: "3% 2% 2% 3%",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.03)",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontStyle: "regular",
                        fontSize: "12px",
                        lineHeight: "140%",
                      }}
                    >
                      {question.slot}({alphabets[index]})
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Grid> <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "multichoice" ? (
        <MultiChoiceNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.mqsubquestions[secondLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          handleChange={handleChange}
          count={count}
          type="multiquestion"
          level={level}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "truefalse" ? (
        <TrueFalseNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          show={true}
          question={question?.mqsubquestions[secondLevelIndex]}
        />
      ) : (
        ""
      )}
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "match" ? (
        <MatchNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.mqsubquestions[secondLevelIndex]}
          secondLevelIndex={secondLevelIndex}
          count={count}
          show={true}
          questionStatusResponse={questionStatusResponse}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "shortanswer" ? (
        <ShortAnswerNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.mqsubquestions[secondLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          count={count}
          level={level}
          handleChange={handleChange}
          quiz={quiz}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "essay" ? (
        <EssayNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.mqsubquestions[secondLevelIndex]}
          show={true}
          count={count}
          secondLevelIndex={secondLevelIndex}
          attemptidd={attemptidd}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
          handleChange={handleChange}
          editorData={editorData}
          setEditorData={setEditorData}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "text" ? (
        <Text
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.mqsubquestions[secondLevelIndex]}
          show={true}
          count={count}
          secondLevelIndex={secondLevelIndex}
          attemptidd={attemptidd}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          handleChange={handleChange}
          level={level}
          setLevel={setLevel}
          editorData={editorData}
          setEditorData={setEditorData}
          quiz={quiz}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "text" ? (
        <Grid item xs={5.5} md={9}>
          <SubQuestionLevelcounts
            question={question?.mqsubquestions[secondLevelIndex]}
            setsecondLevelIndex={setsecondLevelIndex}
            secondLevelIndex={secondLevelIndex}
            count={count}
            thirdLevelIndex={thirdLevelIndex}
            setthirdLevelIndex={setthirdLevelIndex}
            level={level}
            setLevel={setLevel}
            handleNext={handleNext}
            questionslot={question.slot}
            quiz={quiz}
            quizStatus={quizStatus}
          />
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};

export default MultiQuestion;
