import { Box, Grid } from '@mui/material';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import PROGRESS from '../../../views/students/dashboard/progress';
import SCHEDULE from '../../../views/students/dashboard/schedule';
import Needhelpsection from './Needhelpsection';

const HelpIndex = () => {
    return (
        <div>
            <Scrollbars style={{ height: 450 }}>
        <Box px={2}>
          <Grid
            container
            spacing={2}
            columnSpacing={10}
            // className={CLASSES.testPackageContainer}
          >
            <Grid item xs={12} md={8}>
              <Grid container direction="column">
                <Grid item xs={12} md={8} sx={{paddingBottom:"100px"}}>
                  {/* <TestPackageList /> */}
                  {/* <Results/> */}
                  <Needhelpsection/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12} md={4}>
                  <PROGRESS />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SCHEDULE />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
        </div>
    );
}

export default HelpIndex;
