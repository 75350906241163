/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/reducers/TestAttemptReducer.js
 * This JS file is intended to provide the reducer for Test Attempt.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

const initialState = {
  startattempt: null,
  questionList: null,
  answerSaved: null,
  finishAttempt: null,
  reviewAttempt: null,
  answerList: [],
  uploadfileList: [],
  quizStatus: null,
  notStarted: true,
  questionStatusResponse: [],
  truefalseStatusResponse:[]
};
const TestAttemptReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_ATTEMPT":
      return {
        ...state,
        startattempt: action.payload.data,
      };
      case "RESET_START_ATTEMPT":
      return {
        ...state,
        startattempt: action.payload,
      };
    case "FETCH_QUESTION_LIST":
      return {
        ...state,
        questionList: action.payload.data,
      };
    case "SAVE_ANSWER":
      return {
        ...state,
        answerSaved: action.payload.data,
      };
    case "FINISH_ATTEMPT":
      return {
        ...state,
        finishAttempt: action.payload.data,
      };
    case "REVIEW_ATTEMPT":
      return {
        ...state,
        reviewAttempt: action.payload.data,
      };
    case "SET_ANSWERS":
      return {
        ...state,
        answerList: action.payload,
      };
    case "RESET_ANSWERS":
      return {
        ...state,
        answerList: action.payload,
      };
    case "UPLOADFILELIST":
      return {
        ...state,
        uploadfileList: action.payload,
      };
    case "GET_QUIZ_STATUS":
      return {
        ...state,
        quizStatus: action.payload.data,
      };
      case "RESET_GET_QUIZ_STATUS":
      return {
        ...state,
        quizStatus: action.payload,
      };
    case "IS_TEST_STARTED":
      return {
        ...state,
        notStarted: action.payload,
      };
    case "QUESTION_STATUS":
      return {
        ...state,
        questionStatusResponse: action.payload,
      };
      case "TRUEORFALSE_STATUS":
        return {
          ...state,
          truefalseStatusResponse: action.payload,
        };
    default:
      return state;
  }
};


export default TestAttemptReducer;
