/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class ProfileScreen.js
 * This JS file is intended to give a editable profile form.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 17 Aug 2022
 */
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Card,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import * as React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import editicon from "../../../assets/images/editicon.png";
import cut from "../../../assets/images/canceledit.png";

import changeicon from "../../../assets/images/changeicon.png";
import { styled } from "@mui/styles";
import { useState, useEffect } from "react";
import {
  fetchUserField,
  updateUserFields,
  checkExistingEmail,
  checkExistingUsername,
  updatePassword,
} from "../../../webservice/students/actions/AuthAction";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import { Cancelediticon } from "../../../assets/icons";
import changepassword from "../../../assets/images/ForgotPasswordSelection.svg";
import changepasswordbg from "../../../assets/images/passwordchangebg.svg";
import editicons from "../../../assets/images/ForgotPassEnterdetails.svg";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CryptoJS from "crypto-js";
import LoadingSpinner from "../../../views/main/login/LoadingSpinner";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#634699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#634699",
    },
    "&:hover fieldset": {
      borderColor: "#634699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#634699",
    },
  },
});

const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#634699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#634699",
    },
    "&:hover fieldset": {
      borderColor: "#634699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#634699",
    },
  },
});
const useStyles = makeStyles({
  dialogContainer: {
    "& .MuiDialog-container": {
      backgroundColor: "white !important"
    }
  },
});
const USE_STYLES = makeStyles({
  snackbar: {
    bottom: "104px",
  },
  tabTwo: {
    "& .Mui-selected": {
      color: "#634699",
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "140%",
      textTransform: "none",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#634699",
      textTransform: "none",
    },
    "& .MuiTabs-flexContainer": {
      flexWrap: "wrap",
    },
  },
  btn: {
    color: "white",
    backgroundColor: "#634699",
    "&:hover": {
      backgroundColor: "#634699",
    },
  },
  card: {
    width: "100%",
    padding: "30px",
    margin: "10px",
    borderRadius: "5px",
    transition: "0.3s",
  },
  ProfileScreenContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
});

const ProfileScreen = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const { userid } = useSelector((state) => state.authState);
  const { userfield } = useSelector((state) => state.authState);
  const { existingUsername } = useSelector((state) => state.authState);
  const { existingEmail } = useSelector((state) => state.authState);
  const { newPasswordReducer } = useSelector((state) => state.authState);
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const COUNTRYOBJ = countries.getNames("en", { select: "official" });
  const [open, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    country: "",
    username: "",
    facebook: "",
    phone2: "",
    linkedin: "",
    instagram: "",
    addressl2: "",
    pincode: "",
    parentemail:"",
    parentphone:"",
  });

  React.useEffect(() => {
    dispatch(fetchUserField(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  const {
    firstname,
    lastname,
    email,
    city,
    country,
    username,
    address,
    phone2,
    facebook,
    linkedin,
    instagram,
    addressl2,
    pincode,
    parentemail,
    parentphone,
  } = state;
  React.useEffect(() => {
    if (userfield) {
      setState({
        firstname: userfield[0]?.fullname.split(" ").slice(0, -1).join(" "),
        lastname: userfield[0]?.fullname.split(" ").slice(-1).join(" "),
        email: userfield[0]?.email,
        username: userfield[0]?.username,
        city: userfield[0]?.city,
        country: userfield[0]?.country,
        address: userfield[0]?.address,
        phone2: userfield[0]?.phone2,
        facebook:
          userfield[0]?.customfields?.filter((a) => a.name === "Facebook")[0]
            ?.value || "",
        linkedin:
          userfield[0]?.customfields?.filter((b) => b.name === "LinkedIn")[0]
            ?.value || "",
        instagram:
          userfield[0]?.customfields?.filter((c) => c.name === "Instagram")[0]
            ?.value || "",
        addressl2:
          userfield[0]?.customfields?.filter(
            (d) => d.name === "Address Line2"
          )[0]?.value || "",
        pincode:
          userfield[0]?.customfields?.filter((e) => e.name === "Pincode")[0]
            ?.value || "",
        parentemail:
          userfield[0]?.customfields?.filter((e) => e.name === "Parents Email")[0]
            ?.value || "",
        parentphone:
          userfield[0]?.customfields?.filter((e) => e.name === "Parent's Contact number")[0]
            ?.value || "",
      });
    }
  }, [userfield]);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      dispatch(checkExistingEmail(e.target.name, e.target.value));
    } else if (e.target.name === "username") {
      dispatch(
        checkExistingUsername(e.target.name, e.target.value.toLowerCase())
      );
    }
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
    setFormErrors(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(state));
    setIsSubmitted(true);
    setOpen(true);
  };
  useEffect(() => {
    if (isSubmitted && Object.keys(formErrors).length === 0) {
      dispatch(updateUserFields(state, userid));
      setEdit(!isEdit);
    }
  }, [isSubmitted, formErrors]);

  const validate = (values) => {
    const errors = {};
    var pincodexp = /^\d{6}$/;
    var url =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const fname = /^[a-zA-Z]+$/;
    const city = /^[A-Za-z\s]+$/;
    const address = /^[a-zA-z0-9/\\''().&@*$,-\s]+$/;
    const uname = /^[a-z0-9]+$/;
    var phone = new RegExp(/^[0-9\b\s+()-]+$/);


    if (!phone.test(values.phone2)) {
      errors.phone2 = "Please provide valid phone number";
    } else if (values.phone2.length < 10 || values.phone2.length > 16) {
      errors.phone2 = "Please enter valid mobile number";
    }
    if (!phone.test(values.parentphone)) {
      errors.parentphone = "Please provide valid phone number";
  } else if (values.parentphone.length < 10 || values.parentphone.length > 16) {
      errors.parentphone = "Please enter valid mobile number";
  }

  // Validating parent's email
  if (!emailRegex.test(values.parentemail)) {
      errors.parentemail = "Enter a valid email format for parent's email";
  }

    if (!pincodexp.test(values.pincode)) {
      errors.pincode = " Pin code should be 6 digits";
    }

    if (values.facebook && !url.test(values.facebook)) {
      errors.facebook = "This is not a valid URL!";
    }

    if (values.instagram && !url.test(values.instagram)) {
      errors.instagram = "This is not a valid URL!";
    }

    if (values.linkedin && !url.test(values.linkedin)) {
      errors.linkedin = "This is not a valid URL!";
    }
    if (!address.test(values.address)) {
      errors.address =
        "Address Line 1 must only contain alphanumeric characters with a few special characters.";
    }
    if (!address.test(values.addressl2)) {
      errors.addressl2 =
        "Address Line 2 must only contain alphanumeric characters with a few special characters.";
    }
    if (!city.test(values.city)) {
      errors.city = " City should be only alphabetical characters";
    }

    if (!emailRegex.test(values.email)) {
      errors.email = "Enter a valid email format";
    }

    if (!fname.test(values.firstname)) {
      errors.firstname = "First name should be only alphabetical characters";
    }

    if (!fname.test(values.lastname)) {
      errors.lastname = "Last name should be only alphabetical characters";
    }

    if (!uname.test(values.username)) {
      errors.username =
        "Username must have only lowercase alphanumeric characters";
    }
    if (existingUsername && existingUsername[0]?.id) {
      errors.existuser = "Username Already Exists";
    }
    if (existingEmail && existingEmail[0]?.id) {
      errors.existmail = "Email Already Exists";
    }

    return errors;
  };

  const COUNTRYARR = Object.entries(COUNTRYOBJ).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleEdit = (i) => {
    setEdit(!isEdit);
  };

  const handleCancelEdit = () => {
   
    setIsDialogOpen(false);
   
    setEdit(!isEdit);
    setCancelEdit(!cancelEdit);
    if (!cancelEdit) {
      dispatch(fetchUserField(userid));
    }
  };
  const handleCancel = () => {
    setIsDialogOpen(true);
  };
  // const handleCancel = () => {
  //   setEdit(!isEdit);
  //   setIsDialogOpen(false);
  //   // add your cancel logic here
  // };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/;
    return passwordRegex.test(password);
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
  
    // Update state with the new password value
    setNewPassword(newPasswordValue);
  
    // Validate the new password and update error message
    const isValid = validatePassword(newPasswordValue);
    setPasswordErrors((prevErrors) => ({
      ...prevErrors,
      newPassword: isValid
        ? null
        : "Password must contain an uppercase, lowercase, numbers, special characters (@, !, *, $, ^,) and be between 8 and 20 characters in length",
    }));
  };
  
  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
  
    // Update state with the new confirm password value
    setConfirmPassword(confirmPasswordValue);
  
    // Validate the confirm password and update error message
    const isValid = newPassword === confirmPasswordValue;
    setPasswordErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword: isValid ? null : "Passwords do not match",
    }));
  };
  
  
  // Password validation logic
  const [encryptedText, setEncryptedText] = useState('');
  const key = '1234567891011121'; // 128-bit key
  const iv = '1234567891011121'; // 16-byte IV
 
 
  var Sha256 = CryptoJS.SHA256;
var Hex = CryptoJS.enc.Hex;
var Utf8 = CryptoJS.enc.Utf8;
var Base64 = CryptoJS.enc.Base64;
var AES = CryptoJS.AES;
useEffect(() => {
  // Check if newPasswordReducer.updated is true and open the success dialog
  if (newPasswordReducer.updated) {
    setOpenSuccessDialog(true);
  }
}, [newPasswordReducer.updated]); // Run this effect whenever newPasswordReducer.updated changes

const handleSaveClick = async () => {
  try {
  

      if (!validatePassword(newPassword)) {
    setPasswordErrors((prevErrors) => ({
      ...prevErrors,
      newPassword:
        "Password must contain an uppercase, lowercase, numbers, special characters (@, !, *, $, ^,) and be between 8 and 15 characters in length",
    }));
  }

  if (newPassword !== confirmPassword) {
    setPasswordErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword: "Passwords do not match",
    }));
    } else {
      setLoading(true);
      // Continue with save logic
      const encrypted = CryptoJS.AES.encrypt(newPassword, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        keySize: 128 / 8,
      });

      const ciphertext = encrypted.toString();
      setEncryptedText(ciphertext);
      await dispatch(updatePassword(userid, ciphertext));
      setLoading(false);
    }
  } catch (error) {
    console.error('Error:', error.message);
    // setLoading(false);
  }
};

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };
  const handleConfirmOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleResetOpen = () => {
    setOpenResetDialog(true);
    // You can perform any additional actions here when the reset dialog opens.
  };

  const handleResetClose = () => {
    setOpenResetDialog(false);
  };

  const handlePasswordChange = () => {
    setOpenConfirmDialog(false);
    handleResetOpen();
  };
  return (
    <div>
      <Scrollbars style={{ height: "80vh" }}>
        <Box px={2}>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <Button
                sx={{
                  color: "#634699",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid
              container
              spacing={3}
              className={CLASSES.ProfileScreenContainer}
            >
              <Grid item xs={12} md={10}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} md={2}>
                    <Box sx={{ typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{}}>
                          <TabList
                            onChange={handleTabs}
                            aria-label="lab API tabs example"
                            className={CLASSES.tabTwo}
                          >
                            <Tab
                              label="Personal Details "
                              value="1"
                              sx={{
                                color: "#634699",
                                fontWeight: 400,
                                fontSize: "24px",
                                lineHeight: "140%",
                                textTransform: "none",
                              }}
                            />
                          </TabList>
                        </Box>
                        <Card className={CLASSES.card}>
                          <TabPanel value="1">
                            <div>
                              <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={handleCloses}
                                className={CLASSES.snackbar}
                              >
                                <Alert
                                  onClose={handleCloses}
                                  severity={
                                    Object.keys(formErrors).length === 0 &&
                                    isSubmitted
                                      ? "success"
                                      : "warning"
                                  }
                                >
                                  {Object.keys(formErrors).length === 0 &&
                                  isSubmitted ? (
                                    <div>
                                      Thank you for filling out your
                                      information!
                                    </div>
                                  ) : (
                                    <p>You must make some modifications</p>
                                  )}
                                </Alert>
                              </Snackbar>

                              <Box margin={1}>
                                <div>
                                  {isEdit ? (
                                    <div>
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              First Name*
                                            </Typography>

                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="firstname"
                                              id="firstname"
                                              size="small"
                                              onChange={handleChange}
                                              value={firstname || ""}
                                              required
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.firstname}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Last Name*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="lastname"
                                              id="lastname"
                                              size="small"
                                              onChange={handleChange}
                                              value={lastname || ""}
                                              required
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.lastname}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Username*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="username"
                                              id="username"
                                              size="small"
                                              onChange={handleChange}
                                              value={username || ""}
                                              required
                                              error={
                                                existingUsername &&
                                                existingUsername[0]?.id
                                                  ? true
                                                  : false
                                              }
                                              helperText={
                                                existingUsername[0]?.id
                                                  ? "Username Already Exists"
                                                  : null
                                              }
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.username}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Mobile No*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              maxRows={10}
                                              type="text"
                                              name="phone2"
                                              id="phone2"
                                              size="small"
                                              onChange={handleChange}
                                              value={phone2 || ""}
                                              required
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.phone2}
                                            </p>
                                          </Grid>

                                          {/* parents mobile number */}
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                             Parent's mobile No*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              maxRows={10}
                                              type="text"
                                              name="parentphone"
                                              id="parentphone"
                                              size="small"
                                              onChange={handleChange}
                                              value={parentphone}
                                              required
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.parentphone}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              E-mail ID*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="email"
                                              name="email"
                                              id="email"
                                              size="small"
                                              onChange={handleChange}
                                              value={email || ""}
                                              error={
                                                existingEmail?.length !== 0
                                                  ? true
                                                  : false
                                              }
                                              helperText={
                                                existingEmail?.length !== 0
                                                  ? "Email Already Exists"
                                                  : null
                                              }
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.email}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          
                                          {/* parents emailid */}
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Parent's E-mail ID*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="email"
                                              name="parentemail"
                                              id="parentemail"
                                              size="small"
                                              onChange={handleChange}
                                              value={parentemail || ""}
                                              error={
                                                existingEmail?.length !== 0
                                                  ? true
                                                  : false
                                              }
                                              helperText={
                                                existingEmail?.length !== 0
                                                  ? "Email Already Exists"
                                                  : null
                                              }
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.email}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Country*
                                            </Typography>
                                            <CssFormControl
                                              fullWidth
                                              required
                                              size="small"
                                            >
                                              <Select
                                                variant="outlined"
                                                required
                                                name="country"
                                                id="country"
                                                onChange={handleChange}
                                                value={country || "AU"}
                                              >
                                                {!!COUNTRYARR?.length &&
                                                  COUNTRYARR.map(
                                                    ({ label, value }) => (
                                                      <MenuItem
                                                        key={value}
                                                        value={value || ""}
                                                      >
                                                        {label}
                                                      </MenuItem>
                                                    )
                                                  )}
                                              </Select>
                                            </CssFormControl>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              City*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="city"
                                              id="city"
                                              size="small"
                                              onChange={handleChange}
                                              value={city || ""}
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.city}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                         

                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Pin Code*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="pincode"
                                              id="pincode"
                                              size="small"
                                              onChange={handleChange}
                                              value={pincode || ""}
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.pincode}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Address Line 1*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="address"
                                              id="address"
                                              onChange={handleChange}
                                              value={address || ""}
                                              size="small"
                                              required
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.address}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Address Line 2*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="addressl2"
                                              id="addressl2"
                                              size="small"
                                              onChange={handleChange}
                                              value={addressl2 || ""}
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.addressl2}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Facebook link
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="facebook"
                                              id="facebook"
                                              value={facebook.replace(
                                                /<[^>]+>/g,
                                                "" || ""
                                              )}
                                              onChange={handleChange}
                                              size="small"
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.facebook}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Instagram link
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="instagram"
                                              id="instagram"
                                              size="small"
                                              value={instagram.replace(
                                                /<[^>]+>/g,
                                                "" || ""
                                              )}
                                              onChange={handleChange}
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.instagram}
                                            </p>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Linkedin link
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="linkedin"
                                              id="linkedin"
                                              size="small"
                                              value={linkedin.replace(
                                                /<[^>]+>/g,
                                                "" || ""
                                              )}
                                              onChange={handleChange}
                                            />
                                            <p style={{ color: "red" }}>
                                              {formErrors.linkedin}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ) : null}
                                </div>

                                <div>
                                  {!isEdit ? (
                                    <div>
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              First Name*
                                            </Typography>

                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="firstname"
                                              id="firstname"
                                              size="small"
                                              value={firstname || ""}
                                              disabled
                                              required
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Last Name*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="lastname"
                                              id="lastname"
                                              size="small"
                                              disabled
                                              value={lastname || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Username*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="username"
                                              id="username"
                                              size="small"
                                              disabled
                                              value={username || ""}
                                              required
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Mobile No*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="phone2"
                                              id="phone2"
                                              size="small"
                                              value={phone2 || ""}
                                              disabled
                                              required
                                            />
                                          </Grid>
                                          {/* parents mobile number */}
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Parent's mobile No*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="parentphone"
                                              id="parentphone"
                                              size="small"
                                              value={parentphone || ""}
                                              disabled
                                              required
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              E-mail ID*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="email"
                                              name="emailid"
                                              id="emailid"
                                              size="small"
                                              disabled
                                              value={email || ""}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />

                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>                                        
                                            {/* parents emailid */}
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Parent's E-mail ID*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="email"
                                              name="parentemail"
                                              id="parentemail"
                                              size="small"
                                              disabled
                                              value={parentemail || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Country*
                                            </Typography>
                                            <CssFormControl
                                              fullWidth
                                              required
                                              size="small"
                                              disabled
                                            >
                                              <Select
                                                variant="outlined"
                                                required
                                                name="country"
                                                id="country"
                                                onChange={handleChange}
                                                value={country || ""}
                                              >
                                                {!!COUNTRYARR?.length &&
                                                  COUNTRYARR.map(
                                                    ({ label, value }) => (
                                                      <MenuItem
                                                        key={value}
                                                        value={value || ""}
                                                      >
                                                        {label}
                                                      </MenuItem>
                                                    )
                                                  )}
                                              </Select>
                                            </CssFormControl>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              City*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="city"
                                              id="city"
                                              size="small"
                                              disabled
                                              value={city || ""}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>        
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Pin Code*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="pincode"
                                              id="pincode"
                                              size="small"
                                              disabled
                                              value={pincode || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Address Line 1*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="address"
                                              id="address"
                                              size="small"
                                              disabled
                                              value={address || ""}
                                              required
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Address Line 2*
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="addressl2"
                                              id="addressl2"
                                              size="small"
                                              disabled
                                              value={addressl2 || ""}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <br />
                                      <br />
                                      <Grid item xs={12}>
                                        <Grid container spacing={7}>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Facebook link
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              name="facebook"
                                              id="facebook"
                                              size="small"
                                              disabled
                                              value={facebook.replace(
                                                /<[^>]+>/g,
                                                "" || ""
                                              )}
                                              required
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Instagram link
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="instagram"
                                              id="instagram"
                                              size="small"
                                              disabled
                                              value={instagram.replace(
                                                /<[^>]+>/g,
                                                "" || ""
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                              }}
                                            >
                                              {" "}
                                              Linkedin link
                                            </Typography>
                                            <CssTextField
                                              variant="outlined"
                                              fullWidth
                                              required
                                              type="text"
                                              name="linkedin"
                                              id="linkedin"
                                              size="small"
                                              disabled
                                              value={linkedin.replace(
                                                /<[^>]+>/g,
                                                "" || ""
                                              )}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ) : null}
                                </div>
                              </Box>
                            </div>
                          </TabPanel>
                        </Card>
                      </TabContext>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <div
                  // style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button  
                  onClick={handleConfirmOpen} 
                  sx={{
                              background: "#FFFFFF",
                              color: "#634699",
                              borderRadius: "5px",
                              textTransform: "none",
                              border: "1px solid #634699",
                              boxShadow: "none",
                              marginBottom:"10px",
                              "&:hover": {
                                backgroundColor: "#FFFFFF",
                                color: "#634699",
                                boxShadow: "none",
                              },
                            }}>
                              Change Password
                   </Button><br/>

      <Dialog open={openConfirmDialog} PaperProps={{
            style: {
              background: "#FFFFFF",
              borderRadius: "5px",
              padding: "3%",
              maxWidth: "1000px",

            },
          }}  sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
        <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>
              <Grid container>
                <Grid item xs={6} >
                  <img src={changepassword} alt="edit" width="300px" height="300px" />
                </Grid>
                <Grid item xs={6} >
                  <Grid
                    item
                    sx={{ paddingTop: "100px", paddingBottom: "20px", paddingLeft: "50px" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "30px",
                        lineHeight: "79.3px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        color: "#634699",
                      }}
                    >
                      Cenable Password?
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "140%",
                        // display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                       Are you sure you want to change your password?
                    </Typography>
                    <br/>
                    <Button
                      onClick={handleConfirmClose}
                      sx={{
                        background: "#FFFFFF",
                        color: "#634699",
                        borderRadius: "5px",
                        marginRight: "20px",
                        textTransform: "none",
                        border: "1px solid #634699",
                        fontWeight: 700,
                        fontSize: "14px",
                        boxShadow: "none",
                        padding: "3px 45px 3px 45px",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#634699",
                          boxShadow: "none",
                        },
                      }}
                    >
                      No
                    </Button>
                    <Button
                      onClick={handlePasswordChange}
                      sx={{
                        background: "#634699",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                        textTransform: "none",
                        boxShadow: "none",
                        padding: "3px 45px 3px 45px",
                        fontWeight: 700,
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#634699",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
      </Dialog>

     <Dialog open={openResetDialog}   PaperProps={{
                style: {
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  padding: "3%",
                  maxWidth: "1000px",
                },
              }}sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
     
          <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>
                <Grid container>
                  <Grid item xs={6} >
                    <img src={editicons} alt="edit" width="300px" height="313px" />
                  </Grid>
                  <Grid item xs={6} >
                    <Grid
                      item
                      sx={{ padding: "10px 40px 75px 0px" }}
                    >
                     <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "30px",
                          lineHeight: "59.3px",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#634699",
                        }}
                      >
                        Enter Details
                      </Typography>
                      
      <Typography
        sx={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        New Password
      </Typography>
      <CssTextField
        variant="outlined"
        fullWidth
        required
        sx={{marginBottom:"10px"}}
        type={showPassword ? 'text' : 'password'}
        name="newPassword"
        id="newPassword"
        size="small"
        value={newPassword}
        onChange={handleNewPasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibility}
                edge="end"
              >
                {showPassword ?   <Visibility />:<VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />{passwordErrors.newPassword && (
        <Typography color="error" sx={{ fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",marginBottom:"5px"}}>{passwordErrors.newPassword}</Typography>
      )}

      <Typography
        sx={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        Confirm New Password
      </Typography>
      <CssTextField
        variant="outlined"
        fullWidth
        required
        type={showCPassword ? 'text' : 'password'}
        name="confirmPassword"
        id="confirmPassword"
        sx={{marginBottom:"10px"}}
        size="small"
        value={confirmPassword}
  onChange={handleConfirmPasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggleConfirmPasswordVisibility}
                edge="end"
              >
                {showCPassword ?  <Visibility />:<VisibilityOff /> }
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    {passwordErrors.confirmPassword && (
  <Typography color="error"  sx={{ fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",marginBottom:"5px"}}>{passwordErrors.confirmPassword}</Typography>
)}
                     <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                          {loading && <LoadingSpinner />}
                        </div>
                        {!loading && (<>
                        
                        <Button
                     onClick={handleResetClose}
                      sx={{
                        background: "#FFFFFF",
                        color: "#634699",
                        borderRadius: "5px",
                        marginRight: "20px",
                        textTransform: "none",
                        border: "1px solid #634699",
                        fontWeight: 700,
                        fontSize: "11.6px",
                        boxShadow: "none",
                        padding: "5px 25px 5px 25px",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#634699",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                          <Button
                          onClick={handleSaveClick}
                          disabled={
                            !newPassword ||
                            !validatePassword(newPassword) ||
                            newPassword !== confirmPassword
                          }
                            sx={{
                              background: !newPassword || !validatePassword(newPassword) || newPassword !== confirmPassword
                              ? "rgba(0, 0, 0, 0.12)"
                              : "#634699",
                              color: "#FFFFFF",
                              borderRadius: "3px",
                              textTransform: "none",
                              boxShadow: "none",
                              padding: "5px 25px 5px 25px",
                              fontWeight: 700,
                              fontSize: "11.6px",
                              "&:hover": {
                                backgroundColor: "#634699",
                                boxShadow: "none",
                              },
                            }}
                          >
                            Save
                          </Button>
                         </>)} 
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
      </Dialog>
      <Dialog open={openSuccessDialog} PaperProps={{
                style: {
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  padding: "3%",
                  maxWidth: "1000px",
                },
              }}sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
  <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>
    
  <Card sx={{backgroundColor: "#FFFFFF",
  width: "800px",
  height: "330px",
  backgroundImage: `url(${changepasswordbg})`,
  backgroundPosition: "center",
  backgroundSize: "90% 90%",
  backgroundRepeat: "no-repeat",
  position: "relative",}}>
  <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" }}>
    <Button
      onClick={() => { 
        setOpenSuccessDialog(false);
        handleResetClose(); 
        window.location.reload();
      }}
      sx={{
        position: "absolute",
        bottom: "70px",
        background: "#634699",
        color: "#FFFFFF",
        borderRadius: "3px",
        textTransform: "none",
        boxShadow: "none",
        padding: "5px 25px 5px 25px",
        fontWeight: 700,
        fontSize: "11.6px",
        "&:hover": {
          backgroundColor: "#634699",
          boxShadow: "none",
        },
      }}
    >
      Woohooo!
    </Button>
  </div>
</Card>
  </Card>
</Dialog>
                  <div>
                    {isEdit ? (
                      <div>
                        {disable ? (<div>
                          <Button
                            align="right"
                            onClick={handleCancel}
                            sx={{
                              background: "#FFFFFF",
                              color: "#634699",
                              borderRadius: "5px",
                              textTransform: "none",
                              border: "1px solid #634699",
                              boxShadow: "none",
                              "&:hover": {
                                backgroundColor: "#FFFFFF",
                                color: "#634699",
                                boxShadow: "none",
                              },
                            }}
                          >
                            <Box mr={3}>Cancel Edit</Box>
                            <Box>
                              <Cancelediticon sx={{fontSize:10}}/>
                              {/* <img src={cut} alt="edit" /> */}
                            </Box>
                          </Button>
                           <Dialog open={isDialogOpen}  PaperProps={{
          style: {
            background: "#FFFFFF",
            boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
            borderRadius: "10.5656px",
            padding:"5%"
          },
        }}
       >
                           
                           <DialogContent>
                           <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "70.875px",
                                                lineHeight: "83px",
                                                textAlign:"center",
                                                marginBottom:"15px"
                                              }}
                                            >
                                              Hey! 
                                            </Typography>
                           <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 400,
                                                fontSize: "18px",
                                                lineHeight: "33px",
                                                textAlign:"center",
                                                marginBottom:"15px"
                                              }}
                                            >
                                              Are you sure you don’t want to change any details ?
                                            </Typography>
                             
                           </DialogContent>
                           
                           <div style={{ display: "flex", justifyContent: "center" }}>
                             <Button onClick={() => setIsDialogOpen(false)} sx={{
                              background: "#FFFFFF",
                              color: "#634699",
                              borderRadius: "5px",
                              textTransform: "none",
                              border: "1px solid #634699",fontWeight:700, fontSize:"14px",
                              boxShadow: "none",padding:"5px 25px 5px 25px",
                              "&:hover": {
                                backgroundColor: "#FFFFFF",
                                color: "#634699",
                                boxShadow: "none",
                              },
                            }}>Cancel</Button>
                             <Button 
                            onClick={handleCancelEdit}
                            //  onClick={handleCancel}  
                             sx={{
              background: "#634699",
              color: "#FFFFFF",
              borderRadius: "5px",
              textTransform: "none",
              marginLeft: "10px",
              boxShadow: "none",padding:"5px 25px 5px 25px",fontWeight:700, fontSize:"14px",
              "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
            }}>Confirm</Button></div>
                           
                         </Dialog></div>
                        ) : (
                          <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={4} md={9.5} />
                            <Grid item xs={12} md={2.5}>
                          <Button
                            disabled
                            align="right"
                            variant="contained"
                            sx={{
                              backgroundColor: "#634699",

                              textTransform: "none",
                              "&:hover": { backgroundColor: "#634699" },
                            }}
                          >
                            <Box mr={2}>Update Changes</Box>
                            <Box>
                              <img src={changeicon} alt="edit" />
                            </Box>
                          </Button></Grid></Grid></Grid>
                        )}
                      </div>
                    ) : (
                      <div>
                        {value === "1" ? (
                          <Button
                            align="right"
                            onClick={handleEdit}
                            variant="contained"
                            sx={{
                              backgroundColor: "#634699",
                              textTransform: "none",
                              "&:hover": { backgroundColor: "#634699" },
                            }}
                          >
                            <Box mr={3}>Edit Profile</Box>
                            <Box>
                              <img src={editicon} alt="edit" />
                            </Box>
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={4} md={9.5} />
                  <Grid item xs={12} md={2.5}>
                    {value === "1" ? (
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={!isEdit}
                        sx={{
                          backgroundColor: "#634699",
                          textTransform: "none",
                          "&:hover": { backgroundColor: "#634699" },
                        }}
                      >
                        <Box mr={1}>Update Changes</Box>
                        <Box>
                          <img
                            src={changeicon}
                            alt="edit"
                            style={{
                              verticalAlign: "middle",
                            }}
                          />
                        </Box>
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Scrollbars>
    </div>
  );
};

export default ProfileScreen;
