/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class LoginError.js
 * This JS file is intended to provide the layout of the error page.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoginPurpleBg from "../../../assets/images/login-purple-bg.png";
import LoginWhiteBg from "../../../assets/images/login-white-bg.png";
import error from "../../../assets/images/error-emoji.svg";
import ERRORINNERLEFT from "./ErrorInnerLeft";

const USE_STYLES = makeStyles({
  leftContainer: {
    backgroundColor: "#634699",
    height: "100vh",
    borderRadius: "15px",
    // backgroundImage: `url(${LoginPurpleBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10% 50%",
    backgroundSize: "90% 100%",
    margin: "0px",
    paddingLeft: "0px",

    "@media (max-width:900px)": {
      height: "60vh",
    },
  },
  rightContainer: {
    height: "100vh",
    weight: "100vh",
    // backgroundImage: `url(${LoginWhiteBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "99% 88%",
    backgroundSize: "25% 25%",

    "@media (max-width:900px)": {
      height: "40vh",
    },
  },
  innerLeftcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "30%",
    fontFamily: "Helvetica",
    "@media (max-width:900px)": {
      height: "50vh",
      paddingLeft: "20%",
    },
  },
  innerRightcontainer: {
    height: "100vh",
    paddingLeft: "12%",
    alignItems: "center",
    fontFamily: "Helvetica",

    "@media (max-width:900px)": {
      height: "50vh",
      paddingLeft: "0%",
    },
  },
});

const LOGINERROR = () => {
  const classes = USE_STYLES();
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <div className={classes.leftContainer}>
          <Grid
            container
            className={classes.innerLeftcontainer}
            spacing={0}
            alignItems="center"
          >
            <ERRORINNERLEFT />
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={classes.rightContainer}>
          <Grid
            container
            className={classes.innerRightcontainer}
            spacing={0}
            alignItems="center"
          >
            <img src={error} alt="pic" width="350px" height="380px" />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default LOGINERROR;
