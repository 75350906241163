import { useEffect } from 'react';

const useBeforeUnload = ({ when, message, action = () => {} }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    const handleUnload = (event) => {
      action();
      
      const delay = (ms) => {
        var start = +new Date;
        while ((+new Date - start) < ms);
      }
      
      delay(1500);
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('unload', handleUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [when, message, action]);
};

export default useBeforeUnload;
