/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestDetailCard.js
 * This JS file is intended to create a card component that showing the details of a particular test package.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { Fragment, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link , useHistory} from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseById,
  fetchCoursePrice,
  fetchCourseList
} from "../../../../webservice/students/actions/TestPackageListAction";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";

const USE_STYLES = makeStyles({
  cardAction: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    background: "#634699",
    textTransform: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    "&:hover": { backgroundColor: "#634699" },
  },
});

const TEST_DETAIL_CARD = () => {
  const CLASSES = USE_STYLES();
  const [openDialog, setOpenDialog] = useState(false);
  const params = useParams();
  const history = useHistory();
  const courseid = params.id;
  const userid = localStorage.getItem("userid");
  const dispatch = useDispatch();
  const { CourseById } = useSelector((state) => state.testPackageListReducer);
  const { courseprice } = useSelector((state) => state.testPackageListReducer);
const { coursecontents } = useSelector(
  (state) => state.testPackageListReducer
);
useEffect(() => {
  dispatch(fetchUserId());
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const Checkalreadyenroll = coursecontents && coursecontents.length ? coursecontents
  .filter(topic => topic.section !== 0 && topic.modules && topic.modules.length !== 0)
  .map(topic => {
    return topic.secvisible;
  }) : [];



const isButtonDisabled = Checkalreadyenroll.includes(true);
  useEffect(() => {
    dispatch(fetchCourseById(courseid));
    return () => {
      dispatch(fetchCourseById(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(fetchCoursePrice(courseid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
 
  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );
  const { courseList } = useSelector((state) => state.testPackageListReducer);

 const { customizedList } = useSelector(
    (state) => state.testPackageListReducer
  );
  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  return (
    <Grid container>
      <Grid item xs={12}>
        {courseprice && courseprice.length
          ? courseprice.map((prices, index) => (
              <Fragment key={index}>
                {CourseById && CourseById.courses.length
                  ? CourseById.courses.map((datas, index) => (
                      <Fragment key={index}>
                        <Card sx={{padding:"0px 10px"}}>
                          
                            <Grid container>
                              <Grid item xs={6} p={1}>
                                <Typography
                                variant="h2"
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "18px",
                                    lineHeight: "56px",
                                  }}
                                >
                                  {datas.displayname}
                                  {/* <MoreVertIcon
                                    onClick={() => setOpenDialog(true)}
                                    style={{ cursor: "pointer" }}
                                  /> */}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={6}
                                display="flex"
                                justifyContent="flex-end"
                                p={1}
                              >
                                <Typography
                                variant="h2"
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "30px",
                                    lineHeight: "56px",
                                  }}
                                >
                                  <Fragment>
                                  {new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: prices?.currency,
                                    maximumSignificantDigits: 3,
                                  }).format(prices?.cost)} &nbsp;
                                    <span style={{
                                      textDecoration: "line-through",
                                      color: "#000",
                                      fontWeight: 700,
                                      fontSize: "20px",
                                      lineHeight: "56px",
                                    }}>
                                      {new Intl.NumberFormat("en-IN", {
                                        style: "currency",
                                        currency: prices?.currency,
                                        maximumSignificantDigits: 3,
                                      }).format(prices?.actualcost)}
                                    </span>
                                 
                                </Fragment>
                                    {/* <Fragment>
                                        {new Intl.NumberFormat("en-IN", {
                                          style: "currency",
                                          currency: prices?.currency,
                                          maximumSignificantDigits: 3,
                                        }).format(prices?.cost - 100)} <span style={{ textDecoration: "line-through", color: "#000",
                                        fontWeight: 700,
                                        fontSize: "20px",
                                        lineHeight: "56px", }}>
                                              {new Intl.NumberFormat("en-IN", {
                                                style: "currency",
                                                currency: prices?.currency,
                                                maximumSignificantDigits: 3,
                                              }).format(prices?.cost)}
                                            </span>{" "}  <span style={{ color: "#000", fontWeight: 700, fontSize: "20px", lineHeight: "56px" }}>
                                   
                                  </span>
                                     
                                    </Fragment> */}
                                  
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={7} p={1}>
                              <Typography
                              variant="h2"
                                  sx={{
                                    color: "#00000",
                                    fontWeight: "300",
                                    fontStyle: "normal",
                                    fontSize: "14.37px",
                                    lineHeight: "27.11px",
                                  }}
                                ><ul style={{marginTop:"-25px"}}>
                                {coursecontents && coursecontents.length ? coursecontents
                                  ?.filter(
                                    (content) =>
                                      content.visible === 1 &&
                                      content.name !== 'General'&&
                                      content.section !== 0 && content.modules && content.modules.length !== 0  // Exclude 'General' from the list
                                  )
                                  .map((content) => (
                                    <li key={content.id}>{content.name}</li>
                                  )):""}
                              </ul></Typography>
                              {datas.summary && datas.summary !== "" && (
                                <Fragment>
                                <Typography
                                variant="h2"
                                  sx={{
                                    color: "#b5b4b5",
                                    fontWeight: "300",
                                    fontStyle: "normal",
                                    fontSize: "19.37px",
                                    lineHeight: "27.11px",
                                  }}
                                >
                                 
                              
                                {datas.summary.replace(/<[^>]+>/g, "")}
                             
                                </Typography> </Fragment>
                            )}
                                <Typography
                                variant="h2"
                                  sx={{
                                    color: "#634699",
                                    fontWeight: "300",
                                    fontStyle: "normal",
                                    fontSize: "15px",
                                    lineHeight: "27.11px",
                                  }}
                                >
                                  {/* Enroll all topics for exclusive discounts of A$100! */}
                                   Click to select individual topics for a personalized learning experience!
                                  {/* Unlock full topics, enjoy exclusive discounts!
                                  <br/> Click to select Topic! */}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={5}
                                display="flex"
                                justifyContent="flex-end"
                              ><Typography
                                variant="h2"
                              sx={{
                                color: "#000",
                                fontWeight: "600",
                                fontStyle: "normal",
                                fontSize: "15px",
                                // lineHeight: "27.11px",
                              }}
                            >
                              {/* Enroll all topics for exclusive discounts of A$100! */}
                              Enroll in all topics to enjoy an exclusive discount!
                              {/* Unlock full topics, enjoy exclusive discounts!
                              <br/> Click to select Topic! */}
                            </Typography>
                                <CardActions
                                  disableSpacing
                                  className={CLASSES.cardAction}
                                >
                                  <Button
                                    component={Link}
                                    to={`/test-packages/orderconfirmation/${datas.id}`}
                                    variant="contained"
                                    className={CLASSES.button}
                                    disabled={isButtonDisabled}
                                    onClick={() => {
                                    //  localStorage.setItem("customizedList", customizedList);
                                     localStorage.setItem("customizeList", JSON.stringify(filteredContents));
                                      localStorage.setItem("userid", userid);
                                      localStorage.setItem("courseid", courseid);
                                      localStorage.setItem("courseList", JSON.stringify(courseList));  
                                      history.push(`/test-packages/orderconfirmation/${datas.id}`);
                                      localStorage.setItem("filteredContents", JSON.stringify(filteredContents));
                                    
                                    }}
                                  >
                                    Enroll all
                                  </Button>
                                </CardActions>
                              </Grid>
                            </Grid>
                          
                        </Card>
                        <Dialog
                          open={openDialog}
                          onClose={() => setOpenDialog(false)}
                        >
                          Unlock full topics, enjoy exclusive discounts!
                                  <br/> Click to select Topic!
                        </Dialog>
                      </Fragment>
                    ))
                  : null}
              </Fragment>
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default TEST_DETAIL_CARD;
