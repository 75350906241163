/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class my-test-packages/index.js
 * This JS file is the main entry point to the my_test_packages folder.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import Progress from "../../dashboard/progress";
import Schedule from "../../dashboard/schedule";
import MyTestPackagesList from "./MyTestPackagesList";
import TestExplore from "./TestExplore";
import { Route, Switch } from "react-router-dom";
import TestPackageContent from "./TestPackageContent";
// import Feedback from "./feedback";
// import QuestionFeedback from "./QuestionFeedback";
import Test from "./test";
import { Scrollbars } from "react-custom-scrollbars-2";
import FeedbackNew from "./feedbackNew";
import QuestionFeedbackNew from "./QuestionFeedbackNew";
import { useLocation } from "react-router-dom";


const USE_STYLES = makeStyles({
  myTestPackageContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      fontSize: "2rem",
    },
    "& .MuiInputBase-formControl": {
      padding: "8px",
      textAlign: "center",
      color: "#634699",
      backgroundColor: "#fff",
    },
  },
});

const MYTESTPACKAGES = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  const scrollbarsRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(0);
    }
  }, [location.pathname]); 

  return (
    <>
      <Scrollbars  ref={scrollbarsRef}
      style={{ height: "90vh" }}
     >
        <Box px={2}>
          <Grid item xs={12}>
            <Button
              sx={{
                color: "#634699",
                fontWeight: "bold",
                textTransform: "none",
                margin:"0px -15px",
              }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
          <Grid
            container
            // spacing={1}
            columnSpacing={1}
            className={CLASSES.myTestPackageContainer}
          >
            <Grid item xs={12} md={8}>
              <Grid container spacing={1} direction="column">
                <Switch>
                  <Route path="/my-test-packages" exact>
                    <Grid item xs={12} md={8}>
                      <MyTestPackagesList />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TestExplore />
                    </Grid>
                  </Route>

                  <Route path="/my-test-packages/:id/test/:quizid">
                    <Grid item xs={12} md={8}>
                      <Test />
                    </Grid>
                  </Route>
                  <Route path="/my-test-packages/:id/feedback/:quizid" exact>
                    <Grid item xs={12} md={8}>
                      <FeedbackNew />
                    </Grid>
                  </Route>
                  <Route
                    path="/my-test-packages/:subject/feedback/:quizid/:questionname"
                    exact
                  >
                    <Grid item xs={12} md={8}>
                      <QuestionFeedbackNew />
                    </Grid>
                  </Route>
                  <Route path="/my-test-packages/:subject">
                    <Grid item xs={12} md={8}>
                      <TestPackageContent />
                    </Grid>
                  </Route>
                </Switch>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} direction="column">
                <Grid item xs={12} md={4}>
                  <Progress />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Schedule />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
    </>
  );
};

export default MYTESTPACKAGES;
