import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchQuizAttempt } from "../../../../webservice/students/actions/MyTestPackageAction";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Feedbackiconbtn,
  McqIcon,
  Scheduleiconbtn,
} from "../../../../assets/icons";
import { useState } from "react";
import OfflineHeader from "../../../../layouts/main/header/OfflineHeader";
import LOGO from "../../../main/ui_component/logo";
const section = ["All questions", "Section 1", "Section 2"];
const USE_STYLES = makeStyles(() => ({
  icon: {
    height: "10px",
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      fontSize: "2rem",
    },
    "& .MuiInputBase-formControl": {
      textAlign: "center",
      color: "#634699",
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px rgb(0 0 0 / 2%)",
      borderRadius: "2px",
      border: "1px solid",
      padding:"5px 2px 5px 2px"
    },
  },
}));
const OfflineFeedbacknew = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  const params = useParams();
  const quizid = params.quizid;
  const courseid = params.id;
  const { quizAttemptDetail } = useSelector(
    (state) => state.mytestpackagestate
  );
  const [color, setColor] = useState(false);
  const [searchTerm, setSearchTerm] = useState(section[0]);
  const handleColor = () => {
    setColor(true);
  };
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  useEffect(() => {
    if (userid) dispatch(fetchQuizAttempt(userid, quizid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid, quizid]);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return quizAttemptDetail ? (
    <div>
      <div style={{ marginTop: "20px", marginLeft: "30px" }}>
        <LOGO width="12%" />
      </div>

      <Grid container sx={{ padding: "5% 2% 5% 19%" }}>
        <div
          style={{
            // background: "#FFFFFF",
            // boxShadow: "2px 4px 18px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            marginRight: "187.5px"
          }}
        >
          {/* <div style={{padding:"10px 200px 0px 20px"}}> */}
            <OfflineHeader />
            {/* </div> */}
            
            <Fragment>
              <div style={{padding:"10px 10px 10px 0px",margin:"20px"}}>
                <Grid item container xs={12}>
                <Grid item xs={12}>
            <Button
              sx={{
                color: "#634699",
                fontWeight: "400",
                textTransform: "none",
                fontSize:"14px",
                lineHeight:"140%",
                textAlign:"left",
                padding:"0px",
                minWidth:0

              }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Typography
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "40px",
                        lineHeight: "56px",
                      }}
                    >
                      Feedback
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <Typography
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "40px",
                        lineHeight: "56px",
                      }}
                    >
                      {quizAttemptDetail[0].quizmark === "Not graded"
                        ? "Not Graded"
                        : formatter.format(quizAttemptDetail[0].quizmark) +
                          "/" +
                          formatter.format(quizAttemptDetail[0].quizmaxmark)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ paddingBottom: "25px", borderRadius: "10px" }}>
                    <CardContent sx={{ paddingLeft: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          fontStyle: "regular",
                          lineHeight: "22px",
                          color: "#634699",
                        }}
                      >
                        {parse(quizAttemptDetail[0].overallfeedback)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item container xs={12} sx={{marginTop:"10px", marginBottom:"10px"}}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontStyle: "regular",
                        color: "#634699",
                        fontSize: 24,
                      }}
                    >
                      <McqIcon
                        sx={{
                          stroke: "#634699",
                          fill: "#634699",
                          margin: "3px",
                        }}
                        className={CLASSES.icon}
                      />
                      {quizAttemptDetail[0].sectionname}
                      <span
                        style={{
                          margin: 5,
                          color: "gray",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                      >
                        Test {quizAttemptDetail[0].attemptno}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5} display="flex" >
           
            </Grid>
                  <Grid item xs={2.5} display="flex" justifyContent="flex-end">
                  <FormControl
                    fullWidth
                    className={CLASSES.formcontrol}
                    variant="outlined"
                  >
                    <Select
                      id="grouped-select"
                      label="Grouping"
                      displayEmpty
                      sx={{p: "3.5px 21px", '& .MuiOutlinedInput-input': {p: "3.5px 21px"}}}
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                      className={CLASSES.select}
                      defaultValue={section[0]}
                    >
                      {section.map((sections, index) => (
                        <MenuItem
                          key={index}
                          value={sections}
                          sx={{
                            color: "#634699",
                            backgroundColor: "#C4C4C4",
                          }}
                        >
                          {sections}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                </Grid>
                {quizAttemptDetail[0].questionlist.map((question, index) =>
            (searchTerm === "Section 1" && question.tag === "parta") ||
            (searchTerm === "Section 2" && question.tag === "partb") ||
            searchTerm === "All questions" ? (
                  <Grid item xs={12} md={12} sm={12} key={index}>
                    <Card sx={{marginBottom:"20px",padding:"15px"}}>
                      {/* <CardContent 
                      sx={{ p:0, '&:last-child': { pb: "-1px" }}}
                      > */}
                        <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={2}
                            sm={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontStyle: "regular",
                          color: "#634699",
                          fontSize: 26,
                        }}
                      >
                        {question.mark > "0"
                        ? `+${formatter.format(question.mark)}`
                        : formatter.format(question.mark)}
                      </Typography>
                    </Grid>
                      <Grid item xs={12} md={9} sm={12}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: "#000000",
                            fontSize: 16,
                          }}
                        >
                          Question {question.questionorder}
                          {/* <IconButton
                            onClick={handleColor}
                            sx={{
                              color: "#634699",
                              textTransform: "none",
                              boxShadow: "none",
                              "&:hover": {
                                backgroundColor: "#FFFFFF",
                                boxShadow: "none",
                              },
                            }}
                          >
                            {!color ? <StarBorderIcon /> : <StarIcon />}
                          </IconButton> */}
                          <Button
                            component={Link}
                            to={`/login/${courseid}/feedback/${quizAttemptDetail[0].quizid}/${question.questionorder}`}
                            variant="contained"
                            sx={{
                              marginLeft:"20px",
                              textTransform: "none",
                              color: "#634699",
                              backgroundColor: "#E2DDEC",
                              "&:hover": {
                                backgroundColor: "#E2DDEC",
                              },
                            }}
                          >
                            <Feedbackiconbtn sx={{ fontSize: "95%" }} />
                            &nbsp;<span style={{ fontSize: "80%" }}>Check Feedback</span>
                          </Button>
                          <br /><br></br>
                          <span
                            style={{
                              fontWeight: 400,
                              fontStyle: "regular",
                              color: "#634699",
                              fontSize: 16,
                              display: "block",
                              width: "350px",
                              wordWrap: "break-word",
                              justifyContent: "center",
                            }}
                          >
                            {question.qtype === "multiquestion" ?  parse(question.clozemainquestiontext.split("\n")[0]):parse(question.question.split("\n")[0])}
                          </span>
                        </Typography>
                      </Grid>
                  </Grid>
                      {/* </CardContent> */}
                    </Card>
                  </Grid>
                ) : (
                  ""
                )
              )}
              </div>
            </Fragment>
          
        </div>
      </Grid>
    </div>
  ) : (
    ""
  );
};

export default OfflineFeedbacknew;
