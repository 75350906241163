/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class MyTestPackagesList.js
 * This JS file is for showing the list of the packages that enrolled.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  LinearProgress,
  linearProgressClasses,
  Box,
} from "@mui/material";
import React from "react";
import { TestPackageListIcon } from "../../../../assets/icons";
import { makeStyles } from "@mui/styles";
import { Link, useHistory } from "react-router-dom";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchEnrolledTests } from "../../../../webservice/students/actions/MyTestPackageAction";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));
const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));

const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const USE_STYLES = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
  },
  linearProgress: {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EFF1F3",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#634699",
    },
  },
});

const MYTESTPACKAGESLIST = () => {
  const HISTORY = useHistory();
  const classes = USE_STYLES();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { userid } = useSelector((state) => state.authState);
  localStorage.setItem("userid", userid);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  const getSearchResult = () => {
    let list = testList.filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (
        val.fullname.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      }
    });
    if (list.length) return list;
    else return [{ format: 0 }];
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={8} md={8}>
                <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: 700,
                    fontSize: "2rem",
                    lineHeight: "46px",
                  }}
                >
                  My Test Packages
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <SEARCH>
          <SEARCH_ICON_WRAPPER>
            <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
          </SEARCH_ICON_WRAPPER>
          <STYLED_INPUT_BASE
            type="text"
            placeholder="Search My Test Packages"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />{" "}
        </SEARCH>
      </Grid>
      <Grid item xs={12}>
        {testList && testList.length
          ? getSearchResult().map((test, index) =>
              test.format !== 0 ? (
                <Card sx={{ marginBottom: "10px",padding:"10px" }} key={index}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/my-test-packages/${test.id}`}
                  >
                    {/* <CardContent> */}
                      <Grid container>
                        <Grid item xs={1}>
                          <TestPackageListIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#634699",
                              textAlign: "left",
                            }}
                          >
                            {test.fullname}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                              <LinearProgress
                                className={classes.linearProgress}
                                variant="determinate"
                                value={test.progress}
                              />
                            </Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                            >{`${Math.round(test.progress)}%`}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    {/* </CardContent> */}
                  </Link>
                </Card>
              ) : test.format === 0 ? (
                <Card sx={{ marginBottom: "15px" }} key={index}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} key={test.format} textAlign="center">
                        <Typography
                          sx={{
                            color: "#634699",
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "140%",
                          }}
                        >
                          No Result Found..!
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : null
            )
          : null}
           <Typography
                          sx={{
                            color: "#634699",
                            fontWeight: 700,
                            fontSize: "32px",
                            lineHeight: "140%",
                          }}
                        >
                          Explore Other Courses 
                        </Typography>
        {/* <Button
          variant="contained"
          onClick={() => HISTORY.push("/test-packages")}
          sx={{
            textTransform: "none",
            backgroundColor: "#E2DDEC",
            color: "#634699",
            fontWeight: "bold",
            padding: "15px",
            "&:hover": { backgroundColor: "#E2DDEC" },
          }}
          fullWidth
        >
          Explore
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default MYTESTPACKAGESLIST;
