/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class views/dashboard/default/index.js
 * This JS file is the main entry point to the dashboard folder.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import React from "react";
import CenableStudents from "./CenableStudents";
import Feedback from "./FeedbackNotes";
// import Progress from "./progress";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UpcomingTasks from "./UpcomingTasks";
// import Schedule from "./schedule";
import Conversations from "./conversations";
import { Scrollbars } from "react-custom-scrollbars-2";
import SCHEDULE from "./DashboardSchedule";
import PROGRESS from "./DashboradProgress";

const USE_STYLES = makeStyles({
  dashboardContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "134px",
    marginTop: "5px",
  },
});

const DASHBOARD = () => {
  const classes = USE_STYLES();
  return (
    <Scrollbars style={{ height: "90vh" }}>
      <Box px={2}>
        <Grid container spacing={1} className={classes.dashboardContainer}>
          <Grid item xs={12} md={4}>
            <CenableStudents />
          </Grid>
          <Grid item xs={12} md={4}>
            <Feedback />
          </Grid>
          <Grid item xs={12} md={4}>
            <PROGRESS />
          </Grid>
          <Grid item xs={12} md={4}>
            <UpcomingTasks />
          </Grid>
          <Grid item xs={12} md={4}>
            <SCHEDULE />
          </Grid>
          <Grid item xs={12} md={4}>
            <Conversations />
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
};
export default DASHBOARD;
