/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice\students\actions\TestPackageListAction.js
 * This JS file is intended to provide the action methods with respect to testpackages
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 07 Feb 2022
 */

 import {
  getCourseList,
  getCourseContents,
  getTestList,
  getCourseId,
  getCoursePrice,
  sendNotification,
  getGradeSubjectList,
} from "../api/TestPackageListApi";

export const fetchCourseList = (userid) => async (dispatch) => {
  try {
    const getCourse = await getCourseList(userid);
    dispatch({ type: "GET_COURSE", payload: getCourse });
  } catch (error) {
    return error;
  }
};
export const fetchCourseContents = (courseid, userid) => async (dispatch) => {
  try {
    const getContents = await getCourseContents(courseid, userid);
    dispatch({ type: "GET_CONTENTS", payload: getContents });
  } catch (error) {
    return error;
  }
};
export const fetchTestList = (courseid) => async (dispatch) => {
  try {
    if (courseid) {
      const getTest = await getTestList(courseid);
      dispatch({ type: "GET_TESTLIST", payload: getTest });
    } else {
      dispatch({ type: "RESET_TESTLIST", payload: "" });
    }
  } catch (error) {
    return error;
  }
};
export const fetchCourseById = (courseid) => async (dispatch) => {
  try {
    if (courseid) {
      const getCourseById = await getCourseId(courseid);

      dispatch({ type: "GET_COURSEBYID", payload: getCourseById });
    } else {
      dispatch({ type: "RESET_COURSEBYID", payload: "" });
    }
  } catch (error) {
    return error;
  }
};
export const fetchCoursePrice = (courseid) => async (dispatch) => {
  try {
    const getPrice = await getCoursePrice(courseid);
    dispatch({ type: "GET_COURSEPRICE", payload: getPrice });
  } catch (error) {
    return error;
  }
};

export const customizeList = (list) => async (dispatch) => {
  dispatch({ type: "GET_CUSTOMIZEDLIST", payload: list });
};
export const carteditemsaction = (list) => async (dispatch) => {
   // Save cart items to local storage
   localStorage.setItem('cartItems', JSON.stringify(list));
  dispatch({ type: "ADD_TO_CART", payload: list });
};
export const removeFromCartAction = (list) => async (dispatch) => {
  // Save cart items to local storage
  // localStorage.setItem('cartItems', JSON.stringify(list));
 dispatch({ type: "REMOVE_FROM_CART", payload: list });
};

export const resetCustomizedList = () => async (dispatch) => {
  dispatch({ type: "RESET_CUSTOMIZEDLIST", payload: null });
};

export const submitNotification =
  (
    userid,
    courseid,
    cost,
    firstname,
    lastname,
    address,
    method,
    country,
    email,
    sectionid,
    refcode
  ) =>
  async (dispatch) => {
    try {
      const notification = await sendNotification(
        userid,
        courseid,
        cost,
        firstname,
        lastname,
        address,
        method,
        country,
        email,
        sectionid,
        refcode
      );
      dispatch({ type: "POST_NOTIFICATION", payload: notification });
    } catch (error) {
      return error;
    }
  };

export const fetchGradeSubject = () => async (dispatch) => {
  try {
    const getList = await getGradeSubjectList();
    dispatch({ type: "GET_GRADE_SUBJECT", payload: getList });
  } catch (error) {
    return error;
  }
};
