import CryptoJS from "crypto-js";
window.Buffer = window.Buffer || require("buffer").Buffer;
var Sha256 = CryptoJS.SHA256;
var Hex = CryptoJS.enc.Hex;
var Utf8 = CryptoJS.enc.Utf8;
var Base64 = CryptoJS.enc.Base64;
var AES = CryptoJS.AES;

var secret_key = "cenablecare@2022k3y";
var secret_iv = "cenablecare@20221v";

var key = Sha256(secret_key).toString(Hex).substr(0, 32);
var iv = Sha256(secret_iv).toString(Hex).substr(0, 16);

export const encrypt = (data) => {
  try {
    let text;
    if (typeof data === "object") {
      text = JSON.stringify(data);
    } else {
      text = data;
    }

    var output = AES.encrypt(text, Utf8.parse(key), {
      iv: Utf8.parse(iv),
    }).toString();

    var output2ndB64 = Utf8.parse(output).toString(Base64);
    return output2ndB64;
  } catch (error) {
    return error;
  }
};

export const decrypt = (data) => {
  try {
    if (data !== null) {
      let buff = Buffer.from(data, "base64");
      let base64ToStringNew = buff.toString("ascii");
      var decrypted = AES.decrypt(base64ToStringNew, Utf8.parse(key), {
        iv: Utf8.parse(iv),
      }).toString(Utf8);
      return decrypted;
    }
  } catch (error) {
    return "";
  }
};
