/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/ApiMethods.js
 * This JS file is intended to provide common methods for get and post.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

import axios from "axios";
import { baseURL } from "../config";

export const GET_METHOD = async (url = "") => {
  const configuration = {
    headers: {
      "Content-Type": "application/json;",
    },
  };
  const result = await axios.get(baseURL + url, configuration);
  return result;
};
export const POST_METHOD = async (url = "", inputParams) => {
  const configuration = {
    headers: {
      "Content-Type": "application/json;",
    },
  };
  const result = await axios.post(baseURL + url, inputParams);
  return result;
};
