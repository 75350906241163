/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice\students\api\TestPackageListApi.js
 * This JS file is intended to provide the api calls related to enrolled test packages.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 07 Feb 2022
 */

 import { MoodleURL } from "../config";
 import { GET_METHOD } from "./ApiMethods";
 import { decrypt } from "../../../encrypt";
 
 export const getCourseList = async (userid) => {
   try {
     let adminToken = await GET_METHOD(
       `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
     );
     let token = adminToken.data.token;
     let response = await GET_METHOD(
       `${MoodleURL(
         token
       )}&wsfunction=local_centapi_getallcoursecost&moodlewsrestformat=json&userid=${userid}`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 
 export const getCourseContents = async (courseid, userid) => {
   try {
     let adminToken = await GET_METHOD(
       `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
     );
     let token = adminToken.data.token;
     let response = await GET_METHOD(
       `${MoodleURL(
         token
       )}&wsfunction=local_centapi_coursecontents&moodlewsrestformat=json&courseid=${courseid}&userid=${userid}`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 export const getTestList = async (courseid) => {
   try {
     let user = window.localStorage.getItem("userToken");
     let d = decrypt(user);
     let response = await GET_METHOD(
       `${MoodleURL(
         d
       )}&wsfunction=mod_quiz_get_quizzes_by_courses&moodlewsrestformat=json&courseids[0]=${courseid}`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 export const getCourseId = async (courseid) => {
   try {
     let user = window.localStorage.getItem("userToken");
     let d = decrypt(user);
     let response = await GET_METHOD(
       `${MoodleURL(
         d
       )}&wsfunction=core_course_get_courses_by_field&moodlewsrestformat=json&field=id&value=${courseid}`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 export const getCoursePrice = async (courseid) => {
   try {
     let user = window.localStorage.getItem("userToken");
     let d = decrypt(user);
     let response = await GET_METHOD(
       `${MoodleURL(
         d
       )}&wsfunction=local_centapi_get_coursecostlatest&moodlewsrestformat=json&courseid=${courseid}`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 
 export const sendNotification = async (
   userid,
   courseid,
   cost,
   firstname,
   lastname,
   address,
   method,
   country,
   email,
   sectionid,
   refcode
 ) => {
   try {
     let adminToken = await GET_METHOD(
       `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
     );
     let token = adminToken.data.token;
     let response = await GET_METHOD(
       `${MoodleURL(
         token
       )}&wsfunction=local_centapi_checkoutemail&moodlewsrestformat=json&courseid=${courseid}&userid=${userid}&totalamount=${cost}&firstname=${firstname}&lastname=${lastname}&address=${address}&method=${method}&country=${country}&email=${email}&section=${sectionid}&refcode=${refcode}`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 
 export const getGradeSubjectList = async () => {
   try {
     let user = window.localStorage.getItem("userToken");
     let d = decrypt(user);
     let response = await GET_METHOD(
       `${MoodleURL(
         d
       )}&wsfunction=local_centapi_coursefilters&moodlewsrestformat=json`
     );
 
     return response;
   } catch (error) {
     return error;
   }
 };
 