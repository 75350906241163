/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class App.js
 * This JS file is the main entry point to the webapp.
 *
 * @author manu.nair@centelon.com
 * @since 17 Feb 2022
 *
 * @modifiedBy tincy.thomas@centelon.com
 * @modifiedDate 18 Feb 2022
 */

import React from "react";
import { ThemeProvider } from "@mui/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import Routes from "./routes/students";
import themes from "./themes";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./errorBoundary";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes()}>
        <CssBaseline />
        <BrowserRouter>
        <ErrorBoundary>
          <Routes />
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
