/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/api/MyTestPackageApi.js
 * This JS file is intended to provide the api calls related to enrolled test packages.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */
import { MoodleURL } from "../config";
import { GET_METHOD } from "./ApiMethods";
import { decrypt } from "../../../encrypt";

export const getEnrolledTests = async (userid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=core_enrol_get_users_courses&userid=${userid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getTestDetails = async (courseid,userid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_centapi_coursecontents&courseid=${courseid}&userid=${userid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getCourseList = async (courseid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=core_course_get_courses_by_field&field=id&value=${courseid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getQuizList = async () => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(d)}&wsfunction=mod_quiz_get_quizzes_by_courses`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getQuizAttempts = async (userid,quizid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=local_centapi_getallquizattemptdetails&userid=${userid}&quizid=${quizid}`
    );

    return response;
  } catch (error) {
    return error;
  }
};
