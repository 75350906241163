/* eslint-disable no-new-object */
import LOGO from "../../../main/ui_component/logo";
import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, linearProgressClasses, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuizStatus,
  fetchQuestionList,
  fetchQuestionStatus,
  fetchTrueorfalseStatus,
} from "../../../../webservice/students/actions/TestAttemptAction";
import { makeStyles } from "@mui/styles";

import { changeTestStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import { fetchQuizDetails } from "../../../../webservice/students/actions/MyTestPackageAction";
import QuestionScreenNew from "./QuestionScreenNew";
import Timer from "./timer";
import FinalReview from "./FinalReview";
import SuccessAttemptNew from "./SuccessAttemptNew";
import animationData from "../../../../assets/images/loader.json";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import "./MathType.css";
import TimerExpiredNew from "./TimerExpiredNew";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const USE_STYLES = makeStyles({
  header: {
    backgroundColor: "#634699",
    borderRadius: "3px",
    color: "#ffffff",
    fontSize: "21px",
    height: "80px",
    "@media (max-width:900px)": {
      height: "85px",
    },
    paddingTop: "5px",
  },
  logoContainer: {
    marginLeft: "72px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  linearProgress: {
    marginTop: "12px",
    height: 10,
    width: "500px",
    // width: "40%",
    "@media (max-width:900px)": {
      width: "240px",
    },
    borderRadius: 5,
    border: "1px solid #EFF1F3",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#634699",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#EFF1F3",
      borderRadius: 6,
    },
  },
});
const TestAttemptScreenNew = (props) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [submit, setSubmit] = React.useState("In progress");
  const quiz = props.location.state;
  const [count, setCount] = useState(0);
  const { questionList } = useSelector((state) => state.testAttemptState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const { validateTestCode } = useSelector((state) => state.guestAccessState);
  const [attemptidd, setAttemptId] = useState(0);
  const [secondLevelIndex, setsecondLevelIndex] = React.useState(0);
  const [thirdLevelIndex, setthirdLevelIndex] = React.useState(0);
  const [multipleChoiceStatus, setmultipleChoiceStatus] = useState([]);

  const CLASSES = USE_STYLES();
  const { userid } = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (submit === "In progress") {
      setsecondLevelIndex(0);
      setthirdLevelIndex(0);
    }
  }, [submit]);
  // useEffect(() => {
  //   // Request full screen mode
  //   const element = document.documentElement;
  //   const requestFullScreen =
  //     element.requestFullscreen ||
  //     element.webkitRequestFullscreen ||
  //     element.mozRequestFullScreen ||
  //     element.msRequestFullscreen;
  //   requestFullScreen.call(element);

  //   // Disable all tabs
  //   window.onblur = () => {
  //     window.focus();
  //   };

  //   // Prevent exiting full screen mode
  //   document.addEventListener("fullscreenchange", () => {
  //     if (!document.fullscreenElement) {
  //       requestFullScreen.call(element);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   history.block((location, action) => {
  //     if (action === "POP") {
  //       return false;
  //     }
  //     return true;
  //   });
  // }, [history]);
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  useEffect(() => {
    dispatch(changeTestStatus(false));
    if (attemptidd && attemptidd !== 0) {
      dispatch(fetchQuestionList(quiz.id || validateTestCode.quizid, attemptidd));
    } else {
      setAttemptId(quizStatus?.attemptid ? quizStatus?.attemptid : startattempt?.attempt?.id);
    }
  }, [attemptidd]);

  useEffect(() => {
    let list = questionList
      ? Array(questionList?.length)
          .fill()
          .map(
            (_, i) =>
              new Object({
                questionid: questionList[i].questionid, // Add the questionid property
                answer: questionList[i].qtype === "multichoice" ? [] : "",
                status: "Not Visited",
                uploadedFiles:[],
                subQuestions: Array(questionList[i]?.mqsubquestions?.length || questionList[i]?.clozeoptions?.length)
                  .fill()
                  .map(
                    (_, j) =>
                      new Object({
                        questionid: (questionList[i]?.mqsubquestions || questionList[i]?.clozeoptions)[j]?.submqid, // Add the questionid property
                        answer: "",
                        status: "Not Visited",
                        uploadedFiles: [],
                        level: 2,
                        type: (questionList[i]?.mqsubquestions || questionList[i]?.clozeoptions)[j]?.submqqtype,
                        subQuestions:
                          (questionList[i]?.mqsubquestions || questionList[i]?.clozeoptions)[j]?.submqqtype === "text"
                            ? Array(
                                (questionList[i]?.mqsubquestions || questionList[i]?.clozeoptions)[j]?.children?.length
                              )
                                .fill()
                                .map(
                                  (_, k) => {
                                    const question = questionList[i];
                                    const subQuestions = question?.mqsubquestions || question?.clozeoptions;
                                    const subQuestion = subQuestions?.[j]?.children?.[k];
                                    return {
                                      questionid: subQuestion?.submqid, // Add the questionid property
                                      answer: "",
                                      status: "Not Visited",
                                      uploadedFiles: [],
                                      level: 3,
                                      type: subQuestion?.submqqtype,
                                      children: [] // Add children property for level 3
                                    };
                                  }
                                )
                            : "",
                      })
                  ),
                type: questionList[i]?.qtype,
                level: 1,
              })
          )
      : "";
    setmultipleChoiceStatus(list);
    dispatch(fetchQuestionStatus(list));
  }, [questionList]);
  return (
    <React.Fragment>
      {/* <Box className={CLASSES.logoContainer}>
        <LOGO width="12%" height="12%" />
      </Box> */}

      <Grid container className={CLASSES.header} item xs={12} md={12} sm={12}>
        <Grid item xs={0} md={1.5} sm={1.5} />

        <Grid item xs={4} md={2} sm={2} sx={{ paddingTop: "6px" }}>
          {" "}
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "10.5px",
              lineHeight: "140%",
              color: "#EFF1F3",
            }}
          >
            {quiz.name || quiz.quizname}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "140%",
              color: "#EFF1F3",
            }}
          >
            Time Left &nbsp; <Timer quiz={quiz} type="header" />
          </Typography>
        </Grid>
        <Grid item xs={0} md={0.3} sm={0} />

        <Grid item xs={7} md={5} sm={7}>
          <Grid container item xs={6} md={12}>
            <Grid item xs={11.5} md={10.5}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "140%",
                  paddingTop: "10px",
                  color: "#EFF1F3",
                }}
              >
                Progress
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "140%",

                  paddingTop: "10px",
                  color: "#EFF1F3",
                  textAlign: "center",
                }}
              >
                {count + 1}/{questionList?.length}
              </Typography>
            </Grid>
          </Grid>
          <LinearProgress
            className={CLASSES.linearProgress}
            variant="determinate"
            value={((count + 1) / questionList?.length) * 100}
          />
        </Grid>

        <Grid item xs={1} />
      </Grid>
      {submit === "In progress" ? (
        questionList?.length !== 0 ? (
          // <TimerExpiredNew/>
          <QuestionScreenNew
            question={questionList?.[count]}
            count={count}
            setCount={setCount}
            quiz={quiz}
            open={open}
            setOpen={setOpen}
            submit={submit}
            setSubmit={setSubmit}
            attemptidd={attemptidd}
            thirdLevelIndex={thirdLevelIndex}
            secondLevelIndex={secondLevelIndex}
            setsecondLevelIndex={setsecondLevelIndex}
            setthirdLevelIndex={setthirdLevelIndex}
          />
        ) : (
          <Grid sx={{ position: "absolute", left: "45%", top: "50%" }}>
            <Lottie width={100} height={100} options={defaultOptions} />
          </Grid>
        )
      ) : (
        <FinalReview
          question={questionList[count]}
          count={count}
          open={open}
          setOpen={setOpen}
          quiz={quiz}
          submit={submit}
          setSubmit={setSubmit}
          setCount={setCount}
        />
      )}
    </React.Fragment>
  );
};

export default TestAttemptScreenNew;
