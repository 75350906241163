/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/store/rootReducer.js
 * This JS file is intended to combine all available reducers
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { combineReducers } from "redux";
import DashboardReducer from "../reducers";
import AuthReducer from "../reducers/AuthReducer";
import MyTestPackageReducer from "../reducers/MyTestPackageReducer";
import ScheduleReducer from "../reducers/ScheduleReducer";
import FeedbackReducer from "../reducers/FeedbackReducer";
import TestPackageListReducer from "../reducers/TestPackageListReducer";
import TestAttemptReducer from "../reducers/TestAttemptReducer";
import GuestAccessReducer from "../reducers/GuestAccessReducer";

const rootReducer = combineReducers({
  dashboardState: DashboardReducer,
  authState: AuthReducer,
  mytestpackagestate: MyTestPackageReducer,
  scheduleState: ScheduleReducer,
  feedbackState: FeedbackReducer,
  testPackageListReducer: TestPackageListReducer,
  testAttemptState: TestAttemptReducer,
  guestAccessState:GuestAccessReducer,
});

export default rootReducer;
