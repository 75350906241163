import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardContent, Collapse, Dialog, DialogContent, Grid, IconButton, Typography, styled } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import Scrollbars from "react-custom-scrollbars-2";
import {
  AddImageIcon,
  ESSAYtestscreen,
  ExpandIcon,
  HelpMailIcon,
  MATCHtestscreen,
  MCQtestscreen,
  PngImageIcon,
  SAtestscreen,
  SUBQUESTIONStestscreen,
  TOFtestscreen,
} from "../../../assets/icons";
import { allinone, questionFormats, video } from "../../../utils/AppConstants";
import ReactPlayer from "react-player";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const EXPAND_MORES = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Needhelpsection = () => {
  let history = useHistory();
  // const [expanded, setExpanded] = useState(-1);
  const [expand, setExpand] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const handleThumbnailClick = (url) => {
    setVideoUrl(url);
    setOpenVideoDialog(true);
  };
  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  const handleExpandClicks = (index, id) => {
    setExpand((prevExpand) => (prevExpand === index ? -1 : index));
  };
  const [selectedImage, setSelectedImage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  
  const handleVideoCloseDialog = () => {
    setOpenVideoDialog(false);
  };
  
  const handleVideoOpenDialog = () => {
    setOpenVideoDialog(true);
  };

const handleCloseDialog = () => {
  setOpenDialog(false);
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};


  const handleImageClick = (imageName) => {
    setSelectedImage(imageName);
    handleOpenDialog();
  };

 
  const FilterImage = (type) => {
    if (type.image === "MCQ") {
      return <MCQtestscreen sx={{ fontSize: 245, color: "transparent", cursor: "pointer" }} onClick={() => handleImageClick(type.image)}/>;
    } else if (type.image === "Short Answer Questions") {
      return <SAtestscreen sx={{ fontSize: 245, color: "transparent", cursor: "pointer" }} onClick={() => handleImageClick(type.image)}/>;
    } else if (type.image === "True Or False") {
      return <TOFtestscreen sx={{ fontSize: 245, color: "transparent", cursor: "pointer" }} onClick={() => handleImageClick(type.image)}/>;
    } else if (type.image === "Arrange Elements") {
      return <MATCHtestscreen sx={{ fontSize: 245, color: "transparent", cursor: "pointer" }} onClick={() => handleImageClick(type.image)}/>;
    } else if (type.image === "Essay Questions") {
      return <ESSAYtestscreen sx={{ fontSize: 245, color: "transparent", cursor: "pointer" }} onClick={() => handleImageClick(type.image)}/>;
    } else if (type.image === "Subquestions") {
      return <SUBQUESTIONStestscreen sx={{ fontSize: 245, color: "transparent", cursor: "pointer" }} onClick={() => handleImageClick(type.image)}/>;
    }
    return "";
  };


  return (
    <div>
      <Grid item xs={12}>
        <Button
          sx={{
            color: "#634699",
            fontWeight: "bold",
            textTransform: "none",
          }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={8} md={8}>
          <Typography
           variant="h2"
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "2rem",
              lineHeight: "56px",
            }}
          >
            Need Help ?
          </Typography>
        </Grid>{" "}
      </Grid>
      

     
      {allinone.map((section, index) => (
        <div key={index}>
          <Typography
           variant="h2"
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "140%",
            }}
          >
            {section.section}
          </Typography>
          {section.questions.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Card sx={{ margin: "10px", padding: "5px" }}>
                <Grid container item xs={12} key={item.id} sx={{ padding: "8px" }}>
                  <Grid item xs={10.5} md={10.5}>
                    <Typography
                     variant="h2"
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                      }}
                    >
                      {item.question}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5} md={1.5}>
                    <ExpandIcon
                      sx={{ fontSize: 18, transform: expanded === item.id ? "rotate(90deg)" : "rotate(0deg)" }}
                      onClick={() => handleExpandClick(item.id)}
                      aria-expanded={expanded === item.id}
                      aria-label="show more"
                    />
                  </Grid>
                </Grid>
              </Card>
              <Collapse in={expanded === item.id} timeout="auto" unmountOnExit>
                <Card
                  sx={{
                    backgroundColor: "transparent",
                    marginBottom: "5px",
                    marginTop: "5px",
                    paddingLeft:"8px"
                  }}
                >
                  <CardContent>
                    <Typography
                     variant="h2"
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        fontStyle: "regular",
                        lineHeight: "150%",
                        color: "#634699",
                      }}
                    >
                      {item.answer}
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </Collapse>
            </Grid>
          ))}
        </div>
      ))}
      <Typography
       variant="h2"
        sx={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "140%",
        }}
      >
        Contact Support
      </Typography>{" "}
      <br />
      <Typography
       variant="h2"
        sx={{
          fontWeight: 400,
          fontSize: "16px",
          fontStyle: "regular",
          lineHeight: "140%",
          color: "#634699",
        }}
      >
        If you have a question that isn't answered here, please contact our support team.
        <br />
        <HelpMailIcon sx={{ paddingTop: "12px" }} /> &nbsp;&nbsp;
        <span
          style={{
            position: "relative",
            fontWeight: 700,

            zIndex: 1,
            textDecoration: "underline",
            textDecorationStyle: "dotted",
            textDecorationColor: "#634699",
          }}
        >
          contact@cenablecare.com
        </span>
      </Typography>
      <br />
      <Typography
       variant="h2"
        sx={{
          color: "#634699",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "140%",
        }}
      >
        Test Formats
      </Typography>{" "}
      <br />
      
      {questionFormats.map((section, sectionIndex) => (
        <Grid item xs={12} key={sectionIndex}>
          <Card sx={{ margin: "10px", padding: "5px" }}>
            <Grid container item xs={12} key={sectionIndex} sx={{ padding: "8px" }}>
              <Grid item xs={10.5} md={10.5}>
                <Typography
                 variant="h2"
                  sx={{
                    color: "#634699",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "140%",
                  }}
                >
                  {section.section}
                </Typography>
              </Grid>
              {/* <Grid item xs={1.5} md={1.5}>
                <ExpandIcon
                  sx={{
                    fontSize: 18,
                    transform: !expand ? "rotate(90deg)" : "rotate(120deg)",marginTop:"3px"
                  }}
                  expand={expand === sectionIndex ? "true" : "false"}
                  onClick={() => handleExpandClicks(sectionIndex)}
                  aria-expanded={expand === sectionIndex}
                  aria-label="show more"
                />
              </Grid> */}
            </Grid>
          </Card>
          {/* <Collapse in={expand === sectionIndex} timeout="auto" unmountOnExit> */}
            {section.questions.map((question, questionIndex) => (
              <Card
                key={questionIndex}
                sx={{
                  backgroundColor: "#FFFFFF",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            {question.name}
                          </Typography>{" "}
                          <br />
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            {question.description}
                          </Typography><br/>
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            Example
                          </Typography><br/>
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                           Please refer the image on the right .
                          </Typography><br/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Grid container justifyContent="center" alignItems="center">
                            <FilterImage image={question.name} />
                          </Grid>

                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            Image {sectionIndex + 1}.{questionIndex + 1} {question.name}
                          </Typography>
                        </Grid>
                        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            background: "#FFFFFF",
            boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            width: "700px",
            maxWidth: "700px",
            height:"500px"
          },
        }}
      >
        <div style={{padding:"18px 0px 0px 0px"}}>
          <Grid container item display="flex" alignItems="center" justifyContent="center">
            
              {selectedImage === "MCQ" && <MCQtestscreen sx={{ fontSize: 600, color: "transparent" ,height: "0.7em"}}  onClick={handleCloseDialog}/>}
              {selectedImage === "Short Answer Questions" && <SAtestscreen sx={{ fontSize: 600, color: "transparent",height: "0.7em" }}  onClick={handleCloseDialog}/>}
              {selectedImage === "True Or False" && <TOFtestscreen sx={{ fontSize: 600, color: "transparent",height: "0.7em" }}  onClick={handleCloseDialog}/>}
              {selectedImage === "Arrange Elements" && <MATCHtestscreen sx={{ fontSize: 600, color: "transparent",height: "0.7em" }}  onClick={handleCloseDialog}/>}
              {selectedImage === "Essay Questions" && <ESSAYtestscreen sx={{ fontSize: 600, color: "transparent",height: "0.7em" }}  onClick={handleCloseDialog}/>}
              {selectedImage === "Subquestions" && <SUBQUESTIONStestscreen sx={{ fontSize: 600, color: "transparent",height: "0.7em" }}  onClick={handleCloseDialog}/>}
              
              
          </Grid>
          <Typography
           variant="h2"
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "60px",
              textAlign: "center",
            }}
          >
            Image {sectionIndex + 1}.{questionIndex + 1} {selectedImage}
          </Typography>
          </div>
      </Dialog>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          {/* </Collapse> */}
        </Grid>
      ))} <br /><Typography
       variant="h2"
      sx={{
        color: "#634699",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "140%",
      }}
    >
      Video
    </Typography>{" "}
    <br />
      {video.map((section, sectionIndex) => (
        <Grid item xs={12} key={sectionIndex}>
          <Card sx={{ margin: "10px", padding: "5px" }}>
            <Grid container item xs={12} key={sectionIndex} sx={{ padding: "8px" }}>
              <Grid item xs={10.5} md={10.5}>
                <Typography
                 variant="h2"
                  sx={{
                    color: "#634699",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "140%",
                  }}
                >
                  {section.section}
                </Typography>
              </Grid>
              {/* <Grid item xs={1.5} md={1.5}>
                <ExpandIcon
                  sx={{
                    fontSize: 18,
                    transform: !expand ? "rotate(90deg)" : "rotate(120deg)",marginTop:"3px"
                  }}
                  expand={expand === sectionIndex ? "true" : "false"}
                  onClick={() => handleExpandClicks(sectionIndex)}
                  aria-expanded={expand === sectionIndex}
                  aria-label="show more"
                />
              </Grid> */}
            </Grid>
          </Card>
          {/* <Collapse in={expand === sectionIndex} timeout="auto" unmountOnExit> */}
            {section.questions.map((question, questionIndex) => (
              <Card
                key={questionIndex}
                sx={{
                  backgroundColor: "#FFFFFF",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            {question.name}
                          </Typography>{" "}
                          <br />
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            {question.description}
                          </Typography><br/>
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                            Example
                          </Typography><br/>
                          <Typography
                           variant="h2"
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontStyle: "regular",
                              lineHeight: "22px",
                              color: "#634699",
                            }}
                          >
                           Please refer the video on the right .
                          </Typography><br/>
                        </Grid>

                        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                          <Grid container justifyContent="center" alignItems="center">
                            {/* <FilterImage image={question.name} /> */}
                            {/* <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        fontStyle: "regular",
                        lineHeight: "150%",
                        color: "#634699",
                        textDecoration: "underline",
          cursor: "pointer",
                      }}
                      onClick={() => handleThumbnailClick(question.answer)}
                    >
                      Click here to watch the video
                       <br /> */}
                      {/* <img
                        src={videoplay}
                        width="200vw"
                        height="200vh"
                        alt="Thumbnail"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleThumbnailClick(question.answer)}
                      />  */}
                       
      <PlayCircleOutlineOutlinedIcon
        sx={{ color: "#634699", fontSize: 42, marginTop: "2px", cursor: "pointer" }}
        onClick={() => handleThumbnailClick(question.answer)}
      />
 
                    {/* </Typography> */}
                    <Dialog open={openVideoDialog} PaperProps={{
          style: {
            maxHeight: "none",overflowY:"hidden"
          },
        }}
        
        sx={{
          backgroundColor: "rgba(99, 70, 153,  60%)",
        }} >
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <HighlightOffSharpIcon
                      sx={{ color: "#634699", fontSize: 32, marginTop: "2px", cursor: "pointer" }}
                      onClick={handleVideoCloseDialog}
                    /></div>
        <ReactPlayer
    url={videoUrl}
    width="560px"
    height="315px"
    controls={true}
    playing={isPlaying}
  />
      </Dialog>
                          </Grid>

                          
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          {/* </Collapse> */}
        </Grid>
      ))}
    </div>
  );
};

export default Needhelpsection;
