/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class EnterTestCode.js
 * This JS file is intended to create a component for giving the body for take test page.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 25 Feb 2022
 */
import React from "react";
import { Grid, Typography, TextField, Paper } from "@mui/material";

const ENTERTESTCODE = () => {
  return (
    <Grid container direction="column">
      <Paper elevation={0}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: "700",
                  fontStyle: "normal",
                  fontSize: "20px",
                  lineHeight: "45.5px",
                }}
              >
                Enter Test Code
              </Typography>
            </Grid>

            <Grid item xs={6} md={6}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={3}>
                      <TextField
                        minRows={1}
                        style={{ width: 55 }}
                        color="secondary"
                        focused
                      />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <TextField
                        minRows={1}
                        style={{ width: 55 }}
                        color="secondary"
                        focused
                      />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <TextField
                        minRows={2}
                        style={{ width: 55 }}
                        color="secondary"
                        focused
                      />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <TextField
                        minRows={2}
                        style={{ width: 55 }}
                        color="secondary"
                        focused
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ENTERTESTCODE;
