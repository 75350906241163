/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class CenableStudents.js
 * This JS file is intended to display the details of Courses Available along with their basic information
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy manu.nair@centelon.com
 * @modifiedDate 17 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 * @modifiedDate 16 Aug 2024
 */
import {
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseList,
} from "../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import { useEffect } from "react";
import { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const USE_STYLES = makeStyles({
  card: {
    marginBottom: "20px",
    width: "100%",
    margin: "auto",
    height: "377.5px",
    borderRadius: "5px",
    transition: "0.3s",
    // "&:hover": {
    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    // },
  },
});

const CENABLE_STUDENTS = () => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.testPackageListReducer);
  const { userid } = useSelector((state) => state.authState);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  const getSearchResult = () => {
    let enrolledCourses = [];
    let nonEnrolledCourses = [];
  
    let list = courseList?.filter((val) => {
      if (searchTerm === "" && selectedGrade === "" && selectedSubject === "") {
        return val;
      } else if (
        val.displayname.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedGrade
          ? val.customfields[0].name === "Grades" &&
            val.customfields[0].valueraw === selectedGrade.toString()
          : true) &&
        (selectedSubject
          ? val.customfields[1].name === "Subjects" &&
            val.customfields[1].valueraw === selectedSubject.toString()
          : true)
      ) {
        return val;
      }
    });
  
    list?.forEach(course => {
      if (course.enroluistat) {
        enrolledCourses.push(course);
      } else {
        nonEnrolledCourses.push(course);
      }
    });
  
    if (enrolledCourses.length || nonEnrolledCourses.length) {
      return { enrolledCourses, nonEnrolledCourses };
    } else {
      return { enrolledCourses: [{ format: "no-topics" }], nonEnrolledCourses: [{ format: "no-topics" }] };
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container>
          <Card className={CLASSES.card}>
            <CardContent>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#634699",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    paddingLeft: "5px",
                    paddingTop: "12px",
                  }}
                >
                  Enrolled Courses
                </Typography>
              </Grid>
              <Scrollbars style={{ height: 330 }}>
                <Grid item xs={12}>
                  <List>
                  {courseList && courseList.length
    ? getSearchResult().enrolledCourses.length > 0
      ? getSearchResult()
          .enrolledCourses.sort(
            (a, b) => a.categorysortorder - b.categorysortorder
          )
          .map((data, index) => {
                let quizCount = 0;
                let assignCount = 0;
                return data.format === "topics" &&
                  data.categoryid === 2 &&
                  data.visible === 1 ? (
                        <ListItem key={data.id}>    
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  color: "#828282",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  lineHeight: "19.6px",
                                  px:"5px"
                                }}
                              >
                                {data.displayname}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : data.format === "no-topics" ? (
                     <Grid item xs={12} key={data.format} textAlign="center">
                    <Typography
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                      }}
                    >
                      No Result Found..!
                    </Typography>
                  </Grid>
                ) : null;
              })
              : // Message when there are no enrolled courses
              <Grid item xs={12}  textAlign="center">

              <Typography
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "140%",
              }}
            >No non-enrolled courses found!</Typography></Grid>
          : null}
                  </List>
                </Grid>
              </Scrollbars>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default CENABLE_STUDENTS;
